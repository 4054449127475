import { useDeleteWorkflowForm } from 'api/useForms'
import axios from 'axios'
import { COLORS } from 'constants/colors'
import React from 'react'
import { Button, Heading, Paragraph } from 'ui'

const DeleteFormModal = ({
  workflow,
  isVisible,
  closeModal,
  addToast
}) => {
  const deleteWorkflowForm = useDeleteWorkflowForm()

  const handleDelete = async () => {
    try {
      const deleteForm = await deleteWorkflowForm.mutateAsync(workflow?.id);
      if (deleteForm?.status === 'success') {
        addToast({
          variant: "success",
          message: "Workflow Form Deleted successfully",
        });
        closeModal()
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }
  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Work Flow
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {workflow?.title} form? This means
                that this form will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={deleteWorkflowForm?.isLoading}
                loading={deleteWorkflowForm?.isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={closeModal}
                disabled={deleteWorkflowForm?.isLoading}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default DeleteFormModal