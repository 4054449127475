import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import { ERRORS } from "constants/errors";
import jwt_decode from "jwt-decode";
import { JsonDecodeProps } from "modules/auth";
import { useManageAuth } from "modules/auth/hooks/useManageAuth";
import { routes } from "pages/pageRoutes";
import { routes as authRoutes } from "modules/auth/routes";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  // credentials: "include",
});


const errorLink = onError(({ graphQLErrors }) => {
  if (Array.isArray(graphQLErrors)) {
    const { logout } = useManageAuth();
    const notAuthorizedError = graphQLErrors.find(
      ({ message }) => message === ERRORS.NOT_AUTHORIZED
    );

    if (notAuthorizedError) {
      logout();
    }
  }
});

const authLink = setContext((_, { headers: originalHeaders }) => {
  const { getAppAuthToken, getCustomerAuthToken, logout, logoutCustomer } = useManageAuth();
  const appToken = getAppAuthToken();
  const customerToken = getCustomerAuthToken();
  const token = appToken || customerToken;

  let headers = originalHeaders || {};

  // Check if the current operation is for a route requiring authentication
  const requiresAuth = (url: string) => {
    const allRoutes = [...routes, ...authRoutes]?.filter((route) => route?.path !== '/');
    const route = allRoutes.find(route => url.includes(route.path));
    return route?.meta?.requiresAuth || false;
  };

  // Add `Authorization` header if the token is valid and the route requires auth
  if (token && (requiresAuth(window.location.pathname) || window.location.pathname === "/customer-dashboard" || window.location.pathname === "/login" || window.location.pathname === "/forgot-password-otp" || window.location.pathname === '/generate-password' || window.location.pathname === '/form/:id')) {
    const tokenDecoded: JsonDecodeProps = jwt_decode(token as string);
    const expirationTime = tokenDecoded.exp * 1000 - 60000;

    // Logout if the token has expired
    if (Date.now() >= expirationTime) {
      appToken && logout();
      customerToken && logoutCustomer();
    } else {
      headers = {
        ...headers,
        authorization: `Bearer ${token}`,
      };
    }
  }

  return { headers };
});

const cache = new InMemoryCache();

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

// Combine Links
const link = from([
  authLink,
  errorLink,
  httpLink, // Fallback to GraphQL for all other requests
]);

export const client = new ApolloClient({
  link,
  cache,
});

export default client;