import { useTriggerWorkflowForm, useWorkflowForms } from 'api/useForms'
import axios from 'axios'
import { COLORS } from 'constants/colors'
import PreviewOrCompleteFormScreen from 'modules/settings/components/PreviewOrCompleteFormScreen'
import { CustomSectionsInput, WorkFlowForm } from 'modules/settings/types'
import React, { useEffect, useState } from 'react'
import { Button, Heading, Modal, Paragraph, Pill, SvgArrowBack, SvgForms, SvgNote } from 'ui'

const AddClientForm = ({ isVisible, closeModal, client, addToast }) => {
  const [selectedForm, setSelectedForm] = useState<WorkFlowForm | null>(null)
  const triggerWorkflowForm = useTriggerWorkflowForm()
  const {
    data: formsData,
    refetch: refetchWorkFormFlow
  } = useWorkflowForms();
  const workFlowForms = formsData?.data || [];

  const getPreviewDetails = () => {
    if (!selectedForm) return null;

    const sections: CustomSectionsInput[] = Array?.isArray(selectedForm?.sections) && selectedForm?.sections?.length ? selectedForm?.sections?.map((section, index) => {
      return {
        name: section?.title,
        description: section?.description,
        index,
        type: 'custom', // or 'client' depending on your logic
        questions: Array?.isArray(section?.inputs) && section?.inputs?.length ? section?.inputs?.map((question) => {
          return {
            key: question?.key,
            isRequired: question?.is_required,
            description: question?.description,
            type: question?.type?.toLowerCase(),
            label: question?.label,
            options: question?.options
          }
        }) : []
      }
    }) : [];

    return sections;
  }

  const sendFormToClient = async () => {
    if (!selectedForm) return;
    try {
      const formId = selectedForm?.id;
      const clientId = client?.id;
      const payload = {
        formId,
        clientId
      }
      const triggerForm = await triggerWorkflowForm.mutateAsync({
        ...payload
      })

      if (triggerForm?.status === 'success') {
        addToast({
          variant: "success",
          message: triggerForm?.message,
        });
        closeModal();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }
  return (
    <Modal show={isVisible} closeModal={closeModal} variant='right'>
      <div className='w-full relative my-[80px]'>
        <div className='fixed top-0 bg-white w-full max-w-[850px] flex justify-between items-start border-b border-grey-100 px-6 py-5 z-10'>
          <div className='flex space-x-2 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' />
            </Button>

            <div className='flex flex-col space-y-2'>
              <Paragraph size="b4" color={COLORS.GREY[900]} weight='bold'>Add Form</Paragraph>
              <Paragraph size="b5" color={COLORS.GREY[300]}>Choose and complete preferred form</Paragraph>
            </div>
          </div>
          {selectedForm ?
            <div className='flex space-x-2 items-start'>
              <Button
                variant='primary'
                size='sm'
                type='button'
                rounded='md'
                fontSize='b5'
                onClick={sendFormToClient}
                disabled={triggerWorkflowForm?.isLoading}
                loading={triggerWorkflowForm?.isLoading}
              >
                Send
              </Button>
            </div> : null}
        </div>
        <div className="w-full min-h-[400px] h-full flex flex-col xl:flex-row items-start">
          <div className='w-full max-w-[300px] h-full border-r border-grey-100/60'>
            <div className='w-full flex flex-col justify-center min-h-[83px] border-b border-grey-100 p-6'>
              <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold' className='flex items-center space-x-2'>
                <SvgNote /> <span>Form</span>
              </Paragraph>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
                Select preferred form
              </Paragraph>
            </div>
            <div className='w-full flex flex-col space-y-3 p-4'>
              {Array?.isArray(workFlowForms) && workFlowForms?.length ? workFlowForms?.map((workform, index) => {
                const isActive = workform?.id === selectedForm?.id;
                return (
                  <div className={`w-full border ${isActive ? 'border-grey-900' : 'border-grey-100'} flex min-h-[66px] items-center justify-between shadow-md rounded-md px-2 xl:px-4 cursor-pointer`} key={index} onClick={() => setSelectedForm(workform)}>
                    <div className='w-full flex justify-between items-center space-x-3'>
                      <Paragraph size='b3' weight='bold' className=''>
                        {workform?.title}
                      </Paragraph>
                      <Pill variant='success'>Active</Pill>
                    </div>
                  </div>
                )
              }) : null}
            </div>
          </div>

          <div className="w-full h-full">
            {selectedForm ?
              <PreviewOrCompleteFormScreen customQuestions={getPreviewDetails()} /> :
              <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
                <SvgForms width="100%" height="100%" />
                <Heading variant='h1' size='h10' weight='bold' color={COLORS.GREY[900]}>
                  No form selected yet
                </Heading>
                <Paragraph size='b4' color={COLORS.GREY[400]}>
                  Select a form to get started
                </Paragraph>
              </div>}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddClientForm