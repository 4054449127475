import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEmailOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.75 4.667V4.25a.417.417 0 0 0-.417.417h.417Zm15 0h.417a.416.416 0 0 0-.417-.417v.417Zm-15 .416h15V4.25h-15v.833Zm14.583-.416v10h.834v-10h-.834Zm-1.25 11.25H4.417v.833h11.666v-.833Zm-12.916-1.25v-10h-.834v10h.834Zm1.25 1.25a1.25 1.25 0 0 1-1.25-1.25h-.834a2.083 2.083 0 0 0 2.084 2.083v-.833Zm12.916-1.25a1.25 1.25 0 0 1-1.25 1.25v.833a2.083 2.083 0 0 0 2.084-2.083h-.834Z"
      fill="#000"
    />
    <path
      d="m2.75 4.668 7.5 7.5 7.5-7.5"
      stroke="#000"
      strokeWidth={0.833}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgEmailOutline;
