import * as React from "react";
import { ModalHeaderProps } from './types'
import { SvgArrowBack, SvgClose } from "ui";
import { Button, Heading, Paragraph } from "../..";
import { COLORS } from "constants/colors";

export const ModalHeader = ({ className, title, subTitle, position, onClose, showCloseButton }: ModalHeaderProps) => {
  return (
    <div className={`w-full flex flex-row justify-between border-b border-grey-100 bg-white p-4 ${!showCloseButton ? 'xl:px-6 xl:border-none' : 'xl:px-6 xl:pb-4'} ${position === 'center' ? 'items-center' : 'items-start'} ${className || ""}`}>
      <div className="flex space-x-3">
        {!showCloseButton ? <Button
          variant='icon'
          size='square'
          rounded='md'
          type='button'
          className='flex xl:hidden'
          onClick={onClose}
        >
          <SvgArrowBack width="24px" height="24px" />
        </Button> : null}

        <div className="flex flex-col space-y-1">
          <Heading
            variant='h1'
            size='h9'
            weight='bold'
            color={COLORS.BLACK}
            className={`flex-1 ${!showCloseButton ? 'text-center' : ''}`}
          >{title}</Heading>
          {subTitle && (
            <Paragraph size="b5" weight='normal' className={`hidden xl:block flex-1 ${!showCloseButton ? 'text-center' : ''}`} color={COLORS.GREY[400]}>
              {subTitle}
            </Paragraph>
          )}
        </div>
      </div>
      {showCloseButton && (
        <div className='w-fit flex justify-between items-start text-grey-300 rounded-full p-1.5 bg-[#16796F0D]' role='button' onClick={onClose}>
          <SvgClose width="24px" height="24px" />
        </div>
      )}
    </div>
  );
};