import { useQuery, useMutation, gql } from "@apollo/client";
import { LoyaltyOverviewData, LoyaltyProgram, LoyaltySalesReport, Milestone, Promo, Service } from "core/generated";
import { ClientMembershipProps, MembershipProps } from "./types/general";

const LOYALTY_PROGRAM = gql`
  query LoyaltyProgram {
    loyaltyProgram {
      business {
        name
      }
      createdAt
      id
      ruleValue
      rewardPoint
      ruleRequirement
      updatedAt
      validityMonths
      status
      includeSales
      milestones {
        createdAt
        customerPointsAttained
        customerReceives
        customerReceivesType
        milestoneProducts {
          createdAt
          id
          milestoneId
          productId
          updatedAt
        }
        milestoneServices {
          createdAt
          id
          milestoneId
          serviceId
          updatedAt
        }
        products {
          id
          name
        }
        services {
          id
          name
        }
        id
        loyaltyProgramId
        name
        updatedAt
      }
    }
  }

`;

export const useLoyaltyProgram = () => {
  return useQuery<{
    loyaltyProgram: LoyaltyProgram;
  }>(LOYALTY_PROGRAM);
};

const LOYALTY_OVERVIEW = gql`
  query LoyaltyOverviewData(
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    loyaltyOverviewData(startDate: $startDate, endDate: $endDate) {
      topServicePurchased
      redemptionRate {
        totalIssued
        totalRedeemed
      }
      topMembers {
        discountValue
        name
        pointsEarned
        spendValue
        spendType
        date
        discountType
      }
      visitSummaryData {
        averageVisitFrequency
        visitSummaryGraphData {
          numberOfClients
          numberOfVisits
        }
      }
    }
  }
`

export const useLoyaltyOverview = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
  return useQuery<{
    loyaltyOverviewData: LoyaltyOverviewData
  }>(LOYALTY_OVERVIEW, {
    variables: {
      startDate,
      endDate,
    },
  });
}

const ACTIVATE_LOYALTY = gql`
  mutation ActivateLoyaltyProgram($input: ActivateLoyaltyProgramInput!) {
    activateLoyaltyProgram(input: $input) {
        clientMutationId
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useActivateLoyalty = () => {
  const [activateLoyalty, options] = useMutation<{
    activateLoyaltyProgram: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(ACTIVATE_LOYALTY);

  return {
    activateLoyalty,
    ...options,
  };
};

const CREATE_LOYALTY = gql`
  mutation CreateLoyaltyProgram($input: CreateLoyaltyProgramInput!) {
    createLoyaltyProgram(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      loyaltyProgram {
        business {
          name
        }
        createdAt
        id
        ruleValue
        rewardPoint
        ruleRequirement
        updatedAt
        validityMonths
        status
        milestones {
          createdAt
          customerPointsAttained
          customerReceives
          customerReceivesType
          milestoneProducts {
            createdAt
            id
            milestoneId
            productId
            updatedAt
          }
          milestoneServices {
            createdAt
            id
            milestoneId
            serviceId
            updatedAt
          }
          products {
            id
            name
          }
          services {
            id
            name
          }
          id
          loyaltyProgramId
          name
          updatedAt
        }
      } 
    }
  }
`;

export const useCreateLoyalty = () => {
  const [createLoyalty, options] = useMutation<{
    createLoyaltyProgram: {
      clientMutationId: string;
      status: number;
      loyaltyProgram: LoyaltyProgram
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_LOYALTY);

  return {
    createLoyalty,
    ...options,
  };
};

const DELETE_LOYALTY_PROGRAM = gql`
  mutation DeleteLoyaltyProgram($input: DeleteLoyaltyProgramInput!) {
    deleteLoyaltyProgram(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteLoyaltyProgram = () => {
  const [deleteLoyaltyProgram, options] = useMutation<{
    deleteLoyaltyProgram: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_LOYALTY_PROGRAM);

  return {
    deleteLoyaltyProgram,
    ...options,
  };
};

const GET_PROMOS = gql`
  query Promos($q: String) {
    promos(q: $q) {
      topPerformingPromo {
        id
        title
      }
      promos {
        id
        description
        title
        isActive
        createdAt
        isServiceApplicable
        isProductApplicable
        isPackageApplicable
        requiredExpenditure
        startAt
        redemptionLimit
        promoType
        promoValue
        endAt
        totalClients
        totalSales
        promotionsApplied
        topPerformingPromo
        salons {
          id
          branchName
        }
        products {
          id
          name
        }
        services {
          id
          name
        }
        packages {
          id
          name
          isDiscountApplicable
        }
      }
    }
  }
`;

export const useGetPromos = (q?: string) => {
  return useQuery<{
    promos: {
      topPerformingPromo: {
        id: string,
        title: string
      };
      promos: Promo[];
    };
  }>(GET_PROMOS, {
    variables: {
      q,
    },
  });
};

const CREATE_PROMO = gql`
  mutation CreatePromo($input: CreatePromoInput!) {
    createPromo(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreatePromo = () => {
  const [createPromo, options] = useMutation<{
    createPromo: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_PROMO);

  return {
    createPromo,
    ...options,
  };
};

const DELETE_PROMO = gql`
  mutation DeletePromo($input: DeletePromoInput!) {
    deletePromo(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeletePromo = () => {
  const [deletePromo, options] = useMutation<{
    deletePromo: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_PROMO);

  return {
    deletePromo,
    ...options,
  };
};

const BUSINESS_SERVICES = gql`
  query BusinessServices($businessId: ID!) {
    businessServices(businessId: $businessId) {
      name
      id
      price
      duration
    }
  }
`;

export const useBusinessServices = ({
  businessId,
}: {
  businessId: string;
  }) => {
  return useQuery<{
    businessServices: Service[];
  }>(BUSINESS_SERVICES, {
    variables: {
      businessId,
    },
    skip: !businessId,
  });
}

const LOYALTY_SALES = gql`
  query LoyaltySalesReport(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    loyaltySalesReport(startDate: $startDate, endDate: $endDate) {
      client
      dateReached
      dateRedeemed
      location
      milestoneId
      milestoneName
      promoType
      promoValue
      services
      staff
    }
  }
`;

export const useLoyaltySales = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    loyaltySalesReport: LoyaltySalesReport[];
  }>(LOYALTY_SALES, {
    variables: {
      startDate,
      endDate,
    },
  });
};

const MILESTONE = gql`
  query Milestone($id: ID!) {
    milestone(id: $id) {
      customerPointsAttained
      customerReceives
      customerReceivesType
      loyaltyProgramId
      name
    }
  }
`;

export const useMilestone = ({
  id,
}: {
  id: string;
}) => {
  return useQuery<{
    milestone: Milestone;
  }>(MILESTONE, {
    variables: {
      id,
    },
    skip: !id,
  });
}


const CREATE_MEMBERSHIP = gql`
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
        clientMutationId
        message
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useCreateMembership = () => {
  const [createMembership, options] = useMutation<{
    createMembership: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_MEMBERSHIP);

  return {
    createMembership,
    ...options,
  };
};

const ASSIGN_MEMBERSHIP = gql`
  mutation AssignMembership($input: AssignMembershipInput!) {
    assignMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
        clientMembership {
          createdAt
          id
          membershipExpirationDate
          membershipStatus
          purchaseDate
          updatedAt
        }
    }
  }
`;

export const useAssignMembership = () => {
  const [assignMembership, options] = useMutation<{
    assignMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      clientMembership: {
        createdAt: string;
        id: string;
        membershipExpirationDate: string;
        membershipStatus: string;
        purchaseDate: string;
        updatedAt: string;
      };
    };
  }>(ASSIGN_MEMBERSHIP);

  return {
    assignMembership,
    ...options,
  };
};

export const GET_MEMBERSHIPS = gql`
  query Business($bookingUrlIdentifier: String!) {
    business(bookingUrlIdentifier: $bookingUrlIdentifier) {
        memberships {
            createdAt
            fee
            id
            name
            rewardPercentage
            updatedAt
            validityPeriod
            services {
              id
              name
            }
            clients {
              firstName
              lastName
              id
            }
        }
    }
  }
`;

export const useGetMemberships = (bookingUrlIdentifier: string) => {
  return useQuery<{
    business: {
      memberships: MembershipProps[];
    };
  }>(GET_MEMBERSHIPS, {
    variables: { bookingUrlIdentifier },
    skip: !bookingUrlIdentifier,
  });
};

const UPGRADE_MEMBERSHIP = gql`
  mutation UpdateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useUpgradeMembership = () => {
  const [upgradeMembership, options] = useMutation<{
    updateMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPGRADE_MEMBERSHIP);

  return {
    upgradeMembership,
    ...options,
  };
};

const ARCHIVE_MEMBERSHIP = gql`
  mutation ArchiveMembership($input: ArchiveMembershipInput!) {
    archiveMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useArchiveMembership = () => {
  const [archiveMembership, options] = useMutation<{
    archiveMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(ARCHIVE_MEMBERSHIP);

  return {
    archiveMembership,
    ...options,
  };
};

const DELETE_MEMBERSHIP = gql`
  mutation DeleteMembership {
    deleteMembership(input: null) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useDeleteMembership = () => {
  const [deleteMembership, options] = useMutation<{
    deleteMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_MEMBERSHIP);

  return {
    deleteMembership,
    ...options,
  };
};

const ARCHIVED_MEMBERSHIPS = gql`
  query ArchivedMemberships(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    archivedMemberships(startDate: $startDate, endDate: $endDate) {
        createdAt
        fee
        id
        name
        rewardPercentage
        updatedAt
        validityPeriod
        services {
          id
          name
        }
        clients {
          id
          lastName
          firstName
        }
    }
  }
`;

export const useArchiveMemberships = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    archivedMemberships: MembershipProps[];
  }>(ARCHIVED_MEMBERSHIPS, {
    variables: {
      startDate,
      endDate,
    },
  })
}

const RESTORE_MEMBERSHIP = gql`
  mutation RestoreMembership($input: RestoreMembershipInput!) {
    restoreMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useRestoreMembership = () => {
  const [restoreMembership, options] = useMutation<{
    restoreMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(RESTORE_MEMBERSHIP);

  return {
    restoreMembership,
    ...options,
  };
};

const ACTIVE_MEMBERSHIPS = gql`
  query ActiveMemberships(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    activeMemberships(startDate: $startDate, endDate: $endDate) {
      createdAt
      fee
      id
      name
      rewardPercentage
      updatedAt
      validityPeriod
      services {
        id
        name
      }
      clients {
        id
        firstName
        clientMembership {
          purchaseDate
          salon {
            branchName
          }
        }
      }
    }
  }
`;

export const useActiveMemberships = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    activeMemberships: MembershipProps[];
  }>(ACTIVE_MEMBERSHIPS, {
    variables: {
      startDate,
      endDate,
    },
  });
};

const MEMBERSHIP_PURCHASE = gql`
  query MembershipPurchases(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    membershipPurchases(startDate: $startDate, endDate: $endDate) {
        purchaseCount
        salesValue
        membership {
          name
        }
    }
  }
`;

export const useMembershipPurchase = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    membershipPurchases: {
      purchaseCount: number;
      salesValue: number;
      membership: {
        name: string;
      };
    }[];
  }>(MEMBERSHIP_PURCHASE, {
    variables: {
      startDate,
      endDate,
    },
  });
}

const ACTIVE_CLIENT_MEMBERSHIPS = gql`
  query ActiveClientMemberships(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    activeClientMemberships(startDate: $startDate, endDate: $endDate) {
      createdAt
      id
      membershipExpirationDate
      membershipStatus
      purchaseAmount
      purchaseDate
      updatedAt
      membership {
        fee
        id
        name
        rewardPercentage
        services {
          id
          name
        }
        clients {
          firstName
          id
          clientMembership {
            purchaseAmount
            purchaseDate
            salon {
              branchName
            }
          }
        }
        validityPeriod
        updatedAt
      }
    }
  }
`;

export const useActiveClientMemberships = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    activeClientMemberships: ClientMembershipProps[];
  }>(ACTIVE_CLIENT_MEMBERSHIPS, {
    variables: {
      startDate,
      endDate,
    },
  });
}

const MERGED_CLIENT_MEMBERSHIPS = gql`
  query MergedClientMemberships(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    activeClientMemberships(startDate: $startDate, endDate: $endDate) {
      createdAt
      id
      membershipExpirationDate
      membershipStatus
      purchaseAmount
      client {
        firstName
        id
        membershipWalletBalance
        clientMembership {
          purchaseAmount
          purchaseDate
          salon {
            branchName
          }
        }
      }
      purchaseDate
      updatedAt
      membership {
        fee
        id
        name
        rewardPercentage
        services {
          id
          name
        }
        clients {
          firstName
          id
          membershipWalletBalance
          clientMembership {
            purchaseAmount
            purchaseDate
            salon {
              branchName
            }
          }
        }
        validityPeriod
        updatedAt
      }
    }
    allClientMemberships(startDate: $startDate, endDate: $endDate) {
      createdAt
      id
      membershipExpirationDate
      membershipStatus
      purchaseAmount
      purchaseDate
      updatedAt
      membership {
        fee
        id
        name
        rewardPercentage
        services {
          id
          name
        }
        clients {
          firstName
          id
          clientMembership {
            purchaseAmount
            purchaseDate
            salon {
              branchName
            }
          }
        }
        validityPeriod
        updatedAt
      }
    }
    expiredClientMemberships(startDate: $startDate, endDate: $endDate) {
      id
      membershipExpirationDate
      membershipStatus
      purchaseAmount
      purchaseDate
    }
    membershipPurchases(startDate: $startDate, endDate: $endDate) {
      purchaseCount
      salesValue
      membership {
        name
      }
    }
    activeMemberships(startDate: $startDate, endDate: $endDate) {
      clients {
        firstName
        id
        clientMembership {
          purchaseAmount
          purchaseDate
          salon {
            branchName
          }
        }
      }
      name
      rewardPercentage
      services {
        id
        name
      }
      fee
      validityPeriod
    }
  }
`;

export const useMergedClientMemberships = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    activeClientMemberships: ClientMembershipProps[];
    allClientMemberships: ClientMembershipProps[];
    expiredClientMemberships: ClientMembershipProps[];
    activeMemberships: MembershipProps[];
    membershipPurchases: {
      purchaseCount: number;
      salesValue: number;
      membership: {
        name: string;
      };
    }[];
  }>(MERGED_CLIENT_MEMBERSHIPS, {
    variables: {
      startDate,
      endDate,
    },
  });
};

const WALLET_BALANCES = gql`
  query TotalClientWalletBalance {
    totalClientWalletBalance
    totalMembershipWalletBalance
  }
`;

export const useWalletBalances = () => {
  return useQuery<{
    totalClientWalletBalance: number;
    totalMembershipWalletBalance: number;
  }>(WALLET_BALANCES);
};