import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGenderOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.125 3h-3.75a.625.625 0 1 0 0 1.25h2.242L12.33 7.537a6.25 6.25 0 1 0 .883.883L16.5 5.134v2.241a.625.625 0 1 0 1.25 0v-3.75A.625.625 0 0 0 17.125 3ZM11.91 15.913a5 5 0 1 1 0-7.072 5.008 5.008 0 0 1 0 7.072Z"
      fill="#000"
    />
  </svg>
);
export default SvgGenderOutline;
