import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUserOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 1.543a3.958 3.958 0 1 0 0 7.917 3.958 3.958 0 0 0 0-7.917ZM7.542 5.501a2.708 2.708 0 1 1 5.416 0 2.708 2.708 0 0 1-5.416 0Zm2.708 5.209c-1.928 0-3.704.438-5.02 1.178-1.297.73-2.272 1.835-2.272 3.197v.085c0 .968-.001 2.183 1.064 3.051.525.427 1.259.731 2.25.931.994.202 2.29.308 3.978.308 1.688 0 2.983-.106 3.978-.308.992-.2 1.725-.504 2.25-.93 1.066-.869 1.064-2.084 1.064-3.052v-.085c0-1.362-.975-2.467-2.271-3.197-1.317-.74-3.093-1.178-5.021-1.178Zm-6.042 4.375c0-.71.519-1.48 1.634-2.107 1.097-.617 2.654-1.018 4.409-1.018 1.753 0 3.31.401 4.407 1.018 1.116.627 1.634 1.398 1.634 2.107 0 1.09-.034 1.703-.604 2.166-.308.252-.825.498-1.708.676-.88.178-2.085.283-3.73.283s-2.85-.105-3.73-.283c-.883-.178-1.4-.424-1.708-.675-.57-.464-.604-1.077-.604-2.167Z"
      fill="#000"
    />
  </svg>
);
export default SvgUserOutline;
