import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalendarOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.375 3v1.875M15.125 3v1.875M3.25 16.125V6.75a1.875 1.875 0 0 1 1.875-1.875h11.25A1.875 1.875 0 0 1 18.25 6.75v9.375m-15 0A1.875 1.875 0 0 0 5.125 18h11.25a1.875 1.875 0 0 0 1.875-1.875m-15 0v-6.25A1.875 1.875 0 0 1 5.125 8h11.25a1.875 1.875 0 0 1 1.875 1.875v6.25"
      stroke="#000"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCalendarOutline;
