import { COLORS } from 'constants/colors'
import React from 'react'
import { Paragraph } from 'ui'
import { PreviewFormQuestion } from '../types'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'

const PreviewOrCompleteFormScreen = ({ customQuestions }: PreviewFormQuestion) => {
  const convertOptionsToMultiSelect = (options: string[]) => {
    return options?.map((option) => {
      return {
        label: option,
        value: option
      }
    })
  }
  return (
    <div className='w-full flex flex-col p-6 space-y-6 bg-[#F3F6F659]'>
      {/* client details form */}
      {Array?.isArray(customQuestions) && customQuestions?.length ? customQuestions?.map((customSection, index) => {
        return (
          <div className='w-full bg-white border border-[#E9E9E9BF] flex flex-col space-y-3 rounded-md' key={index}>
            <div className='w-full flex py-3 px-6 items-center bg-[#E2E8E933] justify-between'>
              <div className='w-[70%] flex flex-col'>
                <Paragraph size='b4' weight='bold' className=''>
                  {customSection?.name}
                </Paragraph>
                <Paragraph size='b5' weight='bold' color={COLORS.GREY[300]} className=''>
                  {customSection?.description}
                </Paragraph>
              </div>

              <div className='py-2 px-5 bg-[#E2E8E9] rounded-full'>
                <Paragraph size='b5' weight='bold' color={COLORS.GREY[900]} className=''>
                  Step {index + 1}
                </Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col gap-y-8 p-6'>
              {Array?.isArray(customSection?.questions) && customSection?.questions?.length ? customSection?.questions?.map((question, questionIndex) => {
                return (
                  <div className='w-full flex flex-col space-y-2' key={index}>
                    <div className='w-full flex justify-between items-center'>
                      <Paragraph size='b5' weight='bold' className=''>
                        {question?.label} {question?.isRequired ? '*' : null}
                      </Paragraph>
                    </div>
                    {question?.type === 'text' || question?.type === 'textarea' ?
                      <div className='w-full flex flex-col space-y-2 py-2 px-4 rounded-md border border-[#E9E9E9BF]'>
                        <Paragraph size='b4' weight='bold' color={COLORS.GREY[400]} className=''>
                          Enter {question?.label}
                        </Paragraph>
                      </div> : null}
                    {question?.type === 'radio' ?
                      <div className='w-full flex flex-col space-y-1 py-2'>
                        {Array?.isArray(question?.options) && question?.options?.length ? question?.options?.map((option, optionIndex) => {
                          return (
                            <div className='w-full flex items-center space-x-3' key={optionIndex}>
                              <span className='w-[16px] h-[16px] border rounded-full'></span>
                              <Paragraph size='b5' weight='bold' color={COLORS.GREY[900]} className=''>
                                {option}
                              </Paragraph>
                            </div>
                          )
                        }) : null}
                      </div> : null}
                    {question?.type === 'checkbox' ?
                      <div className='w-full flex flex-col space-y-1 py-2'>
                        {Array?.isArray(question?.options) && question?.options?.length ? question?.options?.map((option, optionIndex) => {
                          return (
                            <div className='w-full flex items-center space-x-3' key={optionIndex}>
                              <span className='w-[16px] h-[16px] border'></span>
                              <Paragraph size='b5' weight='bold' color={COLORS.GREY[900]} className=''>
                                {option}
                              </Paragraph>
                            </div>
                          )
                        }) : null}
                      </div> : null}
                    {question?.type === 'select' ?
                      <SingleSelect
                        selected={[]}
                        options={convertOptionsToMultiSelect(question?.options)}
                        placeholder={question?.label}
                        setSelected={null}
                      /> : null}
                  </div>
                )
              }) : null}
            </div>
          </div>
        )
      }) : null}
    </div>
  )
}

export default PreviewOrCompleteFormScreen