import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLongAnswer = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 15.625A.625.625 0 0 1 3.125 15h8.75a.624.624 0 1 1 0 1.25h-8.75a.625.625 0 0 1-.625-.625Zm0-3.75a.625.625 0 0 1 .625-.625h13.75a.624.624 0 1 1 0 1.25H3.125a.625.625 0 0 1-.625-.625Zm0-3.75a.625.625 0 0 1 .625-.625h13.75a.625.625 0 1 1 0 1.25H3.125a.625.625 0 0 1-.625-.625Zm5-3.75a.625.625 0 0 1 .625-.625h8.75a.625.625 0 1 1 0 1.25h-8.75a.625.625 0 0 1-.625-.625Z"
      fill="#000"
    />
  </svg>
);
export default SvgLongAnswer;
