import React from 'react'

const FormProgressBar = ({
  step, steps
}: {
  step: number,
  steps: number
}) => {
  const stepsArray = Array.from({ length: steps - 1 }, (_, i) => i + 1);
  return (
    <div className='w-full max-w-[450px] flex justify-center'>
      {stepsArray.map((_, index) => {
        const isActive = step >= index + 1
        return (
          <div key={index} className='w-fit flex items-center'>
            <div className={`w-[36px] h-[36px] flex justify-center items-center rounded-full border border-green-300 ${isActive ? 'bg-green-300' : 'bg-white'}`}>
              <span className={`${!isActive ? 'text-green-300' : 'text-white'} text-b5`}>{index + 1}</span>
            </div>
            <div className={`min-w-[50px] xl:min-w-[100px] border border-green-300`}></div>
          </div>
        )
      })}
      <div className='w-fit flex items-center'>
        <div className={`w-[36px] h-[36px] flex justify-center items-center rounded-full border border-green-300 ${step >= steps ? 'bg-green-300' : 'bg-white'}`}>
        <span className={`${step >= steps ? 'text-white' : 'text-green-300'} text-b5`}>{steps}</span>
        </div>
      </div>
    </div>
  )
}

export default FormProgressBar