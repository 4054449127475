/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import BookContext from './context';
import { BookingPersonalInformationFormInput, BookingService, CheckoutFormDataInput } from './types';
import { convertServicesToAppointmentStaffService, convertToPreviewPurchaseSalonPackageInput, convertToPurchaseSalonPackageInput, convertVoucherServicesToAppointmentStaffService, hasHomeAsOnlyLocation, isPackagesFieldNotEmpty } from './utils';
import { APPOINTMENT_PAYMENT_METHOD, APPOINTMENT_PAYMENT_METHODS, APPOINTMENT_PAYMENT_METHODS_OFFLINE, INITIAL_STEP, MEMBERSHIP_PAYMENT_METHOD, MYSELF_AND_SOMEONE_ELSE_BOOKING_INPUT, PAYMENT_TYPE_NAME_AND_VALUE, PHONE_FORM_STEP, PURCHASE_SUCCESSFUL_STEP, TIME_AND_DATE } from './constants';
import { useModal } from 'hooks/useModal';
import { API_ERRORS, ERRORS } from 'constants/errors';
import { formatInToPrice, getHoursAndMinutesString, getNumberMonthYearAndTimeFromDate, limitString, sanitizePhoneNumber } from '../../utils/misc';
import BookingBreadcrumb from './BookingBreadcrumb';
import BookingDetailsCard from './BookingDetailsCard';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import { FormLabel } from 'ui/atoms/formLabel';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import FormPhone from 'ui/molecules/input/FormPhone';
import { REGEX_PATTERNS } from 'constants/pattern';
import Input from 'ui/molecules/input/Input';
import Button from 'ui/atoms/button/Button';
import { FormatNumber, FormHelperText, Modal, RadioButton, SvgClose, SvgGreyCheckbox, SvgMemberlove, SvgPaymentMethodWallet, SvgPlus } from 'ui';
import { getHelpTextForCharacterLeft, getHelperTextForReactHookFormErrors } from '../../utils/form';
import FormTextarea from 'ui/molecules/input/FormTextarea';
import Pill from 'ui/atoms/pill/Pill';
import PaymentModal from '../../modals/PaymentModal';
import { Client, ClientSearchDocument, CreateAppointmentDocument, CreateVoucherDocument, PaystackPaymentAuthorization, VoucherPreviewDocument } from 'core/generated';
import BookingSteps from './BookingSteps';
import { logger } from 'core/logger';
import { SvgArrowLeft, SvgArrowRight, SvgCalendar, SvgCartBag, SvgTrash, SvgUser, SvgWhiteDiamond } from 'ui';
import { Heading } from '../../../ui';
import { useCreateVoucher, usePreviewVoucherPrices, useSearchClient } from 'api/usePublic';
import { useAddAppointment } from 'api/useAppointments';
import DOMPurify from 'dompurify';
import SelectedStaffView from './components/SelectedStaff';
import SelectStaff from './modals/SelectStaff';
import SelectedCheckinTime from './components/SelectedCheckinTime';
import SelectedServicesView from './components/SelectedServicesView';
import { useWorkflowBeforeBooking } from 'api/useForms';
import BookingSiteWorkFlow from './components/BookingSiteWorkFlow';

const CheckoutUserDetails = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { addToast, handleNextStep, setSelectedServices, selectedServices, voucherServices, selectedSalon, selectedPackages, setServiceClient, serviceClient, voucherToBeRedeemed, setVoucherPurchasedData, priceSummary, appointmentDateTime, setBookedAppointment, promoCodeApplication, setPackagesPriceSummary, setPromoCodeApplication, setAppointmentPaymentType, bookedAppointment, rewards, selectedReward, setSelectedReward, setRewardSelected, services, businessInfo, deleteService, isMobile, existingAppointment, selectedStaff, setSelectedStaff, setRewards } = useContext(BookContext);
  const [client, setClient] = useState<Client | null>(null);
  const [paymentAuthorizationUrl, setPaymentAuthorizationUrl] = useState<PaystackPaymentAuthorization | null>(null);
  const [showDepositView, setShowDepositView] = useState(false)
  const [showConfirmOrderScreen, setShowConfirmOrderScreen] = useState(false);
  const country = businessInfo?.country;
  const [voucherInputData, setVoucherInputData] = useState<any>(null)
  const [notifyClientToReviewDetails, setNotifyClientToReviewDetails] = useState(false)
  const [oldDetails, setOldDetails] = useState<BookingPersonalInformationFormInput | null>(null)
  const [rewardShowAll, setRewardShowAll] = useState(false)
  const {
    isVisible: isWorkFlowFormModalVisible,
    closeModal: closeWorkFlowFormModal,
    openModal: openWorkFlowFormModal
  } = useModal()
  const {
    data: workflowFormData,
  } = useWorkflowBeforeBooking({
    id: serviceClient ? businessInfo?.id : null
  })

  const workFlowForm = useMemo(() => {
    if (workflowFormData?.data) {
      openWorkFlowFormModal()
      return workflowFormData?.data
    }
    return null
  }, [workflowFormData])
  const {
    loading: createVoucherIsLoading,
    createVoucher
  } = useCreateVoucher()

  const {
    loading: createAppointmentIsLoading,
    createAppointment
  } = useAddAppointment()
  const {
    data: checkPhoneNumberData,
    refetch: searchClient,
  } = useSearchClient({
    salonId: selectedSalon?.id,
  })
  const {
    loading: previewPackagesPriceIsLoading,
    previewVoucherPrices
  } = usePreviewVoucherPrices()
  const {
    control: serviceClientControl,
    handleSubmit: serviceClientHandleSubmit,
    watch: serviceClientWatch,
    register: serviceClientRegister,
    setValue: serviceClientSetValue,
    formState: { errors: serviceErrors },
  } = useForm<BookingPersonalInformationFormInput>({
    defaultValues: {
      paymentMethod: country?.payoutsSupported === true && country?.collectionsSupported === true ? APPOINTMENT_PAYMENT_METHOD.card : APPOINTMENT_PAYMENT_METHOD.unpaid,
      isHomeService: hasHomeAsOnlyLocation(selectedSalon?.locationType),
      address: serviceClient?.address,
      phone: serviceClient?.phone,
      countryCode: serviceClient?.countryCode || selectedSalon?.countryCode,
      callingCode: serviceClient?.callingCode || selectedSalon?.callingCode,
    }
  });
  const { register, handleSubmit, control, setValue, watch } = useForm<CheckoutFormDataInput>({});

  const {
    isVisible: isNotifyClientModal,
    closeModal: closeNotifyClientModal,
    openModal: openNotifyClientModal
  } = useModal()
  const {
    isVisible: isSelectStaffModalVisible,
    openModal: openSelectStaffPage,
    closeModal: closeSelectStaffPage,
  } = useModal();
  const {
    isVisible: isTermAndConditionsModalVisible,
    closeModal: closeTermAndConditionsModal,
    openModal: openTermAndConditions
  } = useModal()

  const {
    isVisible: paymentModalIsVisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();

  let PAYMENT_METHODS = country?.payoutsSupported === true && country?.collectionsSupported === true ? APPOINTMENT_PAYMENT_METHODS : APPOINTMENT_PAYMENT_METHODS_OFFLINE;

  const processAppointmentPayment = (paymentType: string) => {
    setAppointmentPaymentType(paymentType)
    submitAppointment(paymentType);
  }

  const onSubmitDetails = async (input: CheckoutFormDataInput) => {
    const formIsValid = isPackagesFieldNotEmpty(input);
    if (!formIsValid) {
      addToast({
        variant: "error",
        message: "All fields are required",
      });
      return;
    }

    const inputData = convertToPurchaseSalonPackageInput(input, selectedPackages);
    const _voucherInputData = {
      recipients: inputData,
      ...(promoCodeApplication && promoCodeApplication?.isValid && { promoCode: promoCodeApplication?.promoCode }),
    }
    setVoucherInputData(_voucherInputData)
    if (isMobile) {
      setShowConfirmOrderScreen(true)
      return;
    }

    processVoucherPurchase(_voucherInputData)
  };

  const processVoucherPurchase = async (dataInput?) => {
    const voucherInput = dataInput || voucherInputData
    try {
      // logger.info(`Purchasing package: ${JSON.stringify(voucherInputData)}`);
      createVoucher({
        variables: { input: { ...voucherInput, salonId: selectedSalon?.id } }
      }).then(({ data }) => {
        if (data?.createVoucher?.status === 200) {
          const responseData = data?.createVoucher.data;
          const paymentAuthorization = data?.createVoucher?.data?.paymentAuthorization;

          setVoucherPurchasedData({
            discountAmount: responseData?.discountAmount,
            processingFeeAmount: responseData?.processingFeeAmount,
            taxAmount: responseData?.taxAmount,
            totalAmountPayable: responseData?.totalAmountPayable,
            subTotal: responseData?.subtotal,
            recipients: voucherInputData?.recipients,
            paymentAuthorization,
          });
          if (paymentAuthorization) {
            setPaymentAuthorizationUrl(paymentAuthorization);
            openPaymentModal();
          }
        } else if (data?.createVoucher?.errors?.length) {
          const message = data?.createVoucher?.errors[0]?.message || API_ERRORS.VOUCHER_PURCHASE_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        } else {
          const message = API_ERRORS.VOUCHER_PURCHASE_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (appointmentError) {
      if (axios.isAxiosError(appointmentError)) {
        const message = appointmentError?.response?.data?.message || API_ERRORS.VOUCHER_PURCHASE_FAILED;
        addToast({
          variant: "error",
          message,
        });
        logger.error(`Failed to purchase packages: ${message}`)
        logger.exception(appointmentError as Error);
      }
    }
  }


  let hidePaymentAndContinue = false;
  const selectedServicesIds = selectedServices?.map((service) => service?.id);
  const clientMembershipServices = client?.clientMembership?.membership?.services?.map((service) => service?.id);
  const membershipIsActive = client?.clientMembership?.membershipStatus === 'live' && client?.membershipWalletBalance > 0 && selectedServicesIds?.some((serviceId) => clientMembershipServices?.includes(serviceId))
  if (membershipIsActive && priceSummary && client?.clientMembership?.membershipStatus === 'live' && client?.membershipWalletBalance > 0 && (client?.membershipWalletBalance >= priceSummary?.totalPaid || client?.membershipWalletBalance >= priceSummary?.depositAmount)) {
    hidePaymentAndContinue = true;
    if (client?.membershipWalletBalance >= priceSummary?.totalPaid) {
      PAYMENT_METHODS = MEMBERSHIP_PAYMENT_METHOD;
    }
    // serviceClientSetValue('paymentMethod', 'membership_wallet')
  } else if (membershipIsActive && priceSummary && client?.clientMembership?.membershipStatus === 'live' && client?.membershipWalletBalance > 0 && (client?.membershipWalletBalance < priceSummary?.totalPaid || client?.membershipWalletBalance < priceSummary?.depositAmount)) {
    hidePaymentAndContinue = false;
  }

  if (priceSummary && client?.clientMembership?.membershipStatus === 'live' && client?.membershipWalletBalance > 0) {
    // check if all the services in selectected servcies is the samee services in client?.clientMembership?.membership?.services
    const allServicesInClientMembership = selectedServicesIds?.every((serviceId) => clientMembershipServices?.includes(serviceId));
    if (!allServicesInClientMembership) {
      hidePaymentAndContinue = false;
    }

    // check if not one service id match
    const oneServiceIdMatch = selectedServicesIds?.some((serviceId) => clientMembershipServices?.includes(serviceId));
    if (!oneServiceIdMatch) {
      hidePaymentAndContinue = false
    }
  };

  const onSubmit = async (input: BookingPersonalInformationFormInput) => {
    if (selectedSalon?.locationType === "home" && (input?.address === "" || !input?.address)) {
      addToast({
        variant: "error",
        message: "Please enter your address",
      });
      return;
    }

    if (selectedSalon?.locationType === "flexible" && input?.isHomeService && (input?.address === "" || !input?.address)) {
      addToast({
        variant: "error",
        message: "Please enter your address",
      });
      return;
    }

    if (!voucherToBeRedeemed && serviceClient && serviceClient?.fullName !== '' && serviceClient?.email !== '' && (serviceClient?.fullName !== input?.fullName || serviceClient?.email !== input?.email)) {
      setNotifyClientToReviewDetails(true)
      setOldDetails(serviceClient)
      setServiceClient({
        fullName: input?.fullName,
        email: input?.email,
        phone: input?.phone,
        callingCode: input?.callingCode,
        countryCode: input?.countryCode,
        note: input?.note,
        address: input?.address || '',
        isHomeService: input?.isHomeService,
        paymentMethod: !voucherToBeRedeemed ? serviceClientWatch('paymentMethod') : PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER.value,
      })
      return;
    }

    setServiceClient({
      fullName: input?.fullName,
      email: input?.email,
      phone: input?.phone,
      callingCode: input?.callingCode,
      countryCode: input?.countryCode,
      note: input?.note,
      address: input?.address || '',
      isHomeService: input?.isHomeService,
      paymentMethod: !voucherToBeRedeemed ? serviceClientWatch('paymentMethod') : PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER.value,
    })

    if (isMobile) {
      setShowConfirmOrderScreen(true)
      return;
    }

    if (!hidePaymentAndContinue && priceSummary && priceSummary?.depositAmount > 0 && !showDepositView && !voucherToBeRedeemed && priceSummary?.totalPrice !== 0) {
      setShowDepositView(true);
      return;
    }

    submitAppointment()
  }

  const completeBooking = () => {
    closeWorkFlowFormModal()
  }

  const submitAppointment = async (paymentType?: string) => {
    if (!serviceClient) return null;
    if (!appointmentDateTime) return null;

    const names = serviceClientWatch('fullName').split(' ');
    const firstName = names[0];
    const lastName = names[1] || "";
    let paymentMethodsInput;
    let appointmentSplitPayments;
    if (membershipIsActive && hidePaymentAndContinue && !voucherToBeRedeemed) {
      if (client?.membershipWalletBalance < priceSummary?.totalPaid && client?.membershipWalletBalance >= priceSummary?.depositAmount && priceSummary?.depositAmount !== 0) {
        paymentMethodsInput = {
          appointmentPaymentMethod: 'membership_wallet',
          paymentType: 'deposit_payment'
        }
      } else if (client?.membershipWalletBalance >= priceSummary?.totalPaid) {
        paymentMethodsInput = {
          appointmentPaymentMethod: 'membership_wallet',
          paymentType: 'full_payment'
        }
      } else if (client?.membershipWalletBalance < priceSummary?.totalPaid) {
        paymentMethodsInput = {
          appointmentPaymentMethod: serviceClientWatch('paymentMethod'),
          paymentType: 'full_payment'
        }
        appointmentSplitPayments = [{
          splitPaymentMode: 'membership_wallet',
          amount: client?.membershipWalletBalance,
        }]
      }
    } else if (membershipIsActive && !hidePaymentAndContinue && !voucherToBeRedeemed) {
      paymentMethodsInput = {
        appointmentPaymentMethod: serviceClientWatch('paymentMethod'),
        paymentType: paymentType === 'deposit_payment' ? 'deposit_payment' : 'full_payment'
      }
      if (paymentType === 'deposit_payment') {
        appointmentSplitPayments = [{
          splitPaymentMode: 'membership_wallet',
          amount: client?.membershipWalletBalance,
        }]
      } else if (paymentType === 'full_payment') {
        appointmentSplitPayments = [{
          splitPaymentMode: 'membership_wallet',
          amount: client?.membershipWalletBalance,
        }]
      }
    } else if (!membershipIsActive && !voucherToBeRedeemed) {
      paymentMethodsInput = {
        appointmentPaymentMethod: serviceClientWatch('paymentMethod'),
        paymentType: paymentType || 'full_payment'
      }
      if (paymentType === 'deposit_payment' && serviceClientWatch('paymentMethod') === 'client_wallet') {
        appointmentSplitPayments = [{
          splitPaymentMode: 'client_wallet',
          amount: priceSummary?.depositAmount,
        }]
      }
    } else if (voucherToBeRedeemed) {
      paymentMethodsInput = {
        appointmentPaymentMethod: PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER.value,
        paymentType: 'full_payment'
      }
    }
    const appointmentData = {
      ...(!voucherToBeRedeemed && {
        clientInput: {
          firstName,
          lastName,
          email: serviceClientWatch('email'),
          phone: serviceClient?.phone,
          callingCode: serviceClient?.callingCode,
          countryCode: serviceClient?.countryCode,
        }
      }),
      ...(voucherToBeRedeemed && { clientId: voucherToBeRedeemed?.clientId }),
      startAt: appointmentDateTime,
      services: voucherToBeRedeemed ? convertVoucherServicesToAppointmentStaffService(voucherServices) : convertServicesToAppointmentStaffService(selectedServices),
      note: serviceClientWatch('note') || '',
      // ...(paymentType ? { paymentType } : { paymentType: 'full_payment' }),
      ...(promoCodeApplication && promoCodeApplication?.isValid && { promoCode: promoCodeApplication?.promoCode }),
      locationType: serviceClientWatch('isHomeService') ? 'home' : 'salon',
      source: "public",
      ...paymentMethodsInput,
      ...(serviceClientWatch('isHomeService') && { address: serviceClientWatch('address') }),
      // appointmentPaymentMethod: !voucherToBeRedeemed ? serviceClientWatch('paymentMethod') : PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER.value,
      appointmentType: 'online',
      splitPayments: appointmentSplitPayments,
      ...(voucherToBeRedeemed ? { voucherId: voucherToBeRedeemed?.id, voucherRedemptionMode: 'services' } : {}),
      ...(selectedReward && { milestoneId: selectedReward?.milestoneId, clientMilestoneId: selectedReward?.id }),
    }
    // console.log({
    //   appointmentData,
    // })
    // return;
    try {
      // logger.info(`Creating an appointment: ${JSON.stringify(appointmentData)}`);
      createAppointment({
        variables: { input: { ...appointmentData, salonId: selectedSalon?.id } }
      }).then(({ data }) => {
        if (data?.createAppointment?.status === 201) {
          const responseData = data?.createAppointment.appointment;
          const paymentAuthorization = data?.createAppointment.paymentAuthorization
          setBookedAppointment(responseData)
          if (voucherToBeRedeemed || !paymentAuthorization) {
            handleNextStep(PURCHASE_SUCCESSFUL_STEP);
            return;
          }

          if (serviceClientWatch('paymentMethod') !== "card" && priceSummary?.depositAmount === 0) {
            handleNextStep(PURCHASE_SUCCESSFUL_STEP);
            return;
          }

          if (serviceClientWatch('paymentMethod') === "card" && paymentAuthorization) {
            setPaymentAuthorizationUrl(paymentAuthorization);
            openPaymentModal();
          }
        } else if (data?.createAppointment?.errors?.length) {
          const message = data?.createAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_CREATION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
          logger.error(`Failed to create an appointment: ${message}`, appointmentData)
          logger.exception(appointmentData as unknown as Error, appointmentData);
        } else {
          const message = API_ERRORS.APPOINTMENT_CREATION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (appointmentError) {
      if (axios.isAxiosError(appointmentError)) {
        const message = appointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_CREATION_FAILED;
        addToast({
          variant: "error",
          message,
        });
        logger.error(`Failed to create an appointment: ${message}`, appointmentData)
        logger.exception(appointmentError as Error, appointmentData);
      }
    }
  }

  const _sanitizePhoneNumber = (prefix: string, phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, watch(`${prefix}.callingCode`) as string);
    setValue(`${prefix}.phone`, _phoneNumber)
    getClientDetailsAsync({
      phone: _phoneNumber,
      countryCode: watch(`${prefix}.countryCode`) as string,
      prefix,
    })
  }

  const closeNotifyClientModal_ = (action: string) => {
    closeNotifyClientModal()
    setNotifyClientToReviewDetails(false)
    if (action === 'continue') {
      if (isMobile) {
        setShowConfirmOrderScreen(true)
        return;
      }

      if (priceSummary && priceSummary?.depositAmount > 0 && !showDepositView && !voucherToBeRedeemed && priceSummary?.totalPrice !== 0) {
        setShowDepositView(true);
        return;
      }

      submitAppointment()
    } else {
      setServiceClient(oldDetails)
    }
  }

  const cleanHtml = DOMPurify.sanitize(businessInfo?.termsAndConditions);

  useEffect(() => {
    if (promoCodeApplication && Array.isArray(selectedPackages) && selectedPackages?.length) {
      if (promoCodeApplication?.redemptionType === 'package' && promoCodeApplication?.redemptionStatus === 'pending') {
        previewPackagesPriceAsync()
      }
    }
  }, [promoCodeApplication])

  useEffect(() => {
    if (!promoCodeApplication && Array.isArray(selectedPackages) && selectedPackages?.length) {
      previewPackagesPriceAsync()
    }
  }, [promoCodeApplication, selectedPackages])

  useEffect(() => {
    if (priceSummary && priceSummary?.totalPrice === 0) {
      serviceClientSetValue('paymentMethod', APPOINTMENT_PAYMENT_METHOD.unpaid)
    }

    if (membershipIsActive && priceSummary && client?.membershipWalletBalance > 0 && client?.membershipWalletBalance >= priceSummary?.totalPaid) {
      serviceClientSetValue('paymentMethod', APPOINTMENT_PAYMENT_METHOD.membership)
    }
  }, [priceSummary])

  useEffect(() => {
    if (notifyClientToReviewDetails) {
      openNotifyClientModal()
    }
  }, [notifyClientToReviewDetails, openNotifyClientModal])

  const previewPackagesPriceAsync = async () => {
    try {
      const inputData = convertToPreviewPurchaseSalonPackageInput(watch(), selectedPackages);

      const voucherInputData = {
        recipients: inputData,
        ...(promoCodeApplication && { promoCode: promoCodeApplication?.promoCode }),
      }

      await previewVoucherPrices({
        variables: {
          input: {
            ...voucherInputData, // Spread the voucher data (recipients and promoCode)
            salonId: selectedSalon?.id // Include salonId separately
          }
        }
      }).then(({ data }) => {
        const summaryPrice = data?.voucherPreview?.data;
        if (summaryPrice) {
          setPackagesPriceSummary(summaryPrice)
          if (summaryPrice?.discountAmount == 0.0) {
            setPromoCodeApplication(null)
          } else {
            if (promoCodeApplication) {
              setPromoCodeApplication({
                promoCode: promoCodeApplication?.promoCode,
                promoValue: summaryPrice?.discountAmount,
                promoType: 'amount',
                isValid: true,
                redemptionType: 'package',
                redemptionStatus: 'active',
              })
            }
          }

          if (promoCodeApplication && promoCodeApplication?.promoCode && summaryPrice?.discountAmount === 0.0) {
            addToast({
              variant: "error",
              message: "Promo code is not valid",
            });
          }
        } else if (data?.voucherPreview?.errors?.length) {
          const message = data?.voucherPreview?.errors?.[0]?.message || "Promo code is not valid";
          addToast({
            variant: "error",
            message,
          });
          return;
        }
      }).catch((error) => {
        console.log(error)
      })
    } catch (appointmentError) {
      if (axios.isAxiosError(appointmentError)) {
        const message = appointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_PRICE_SUMMARY_FAILED;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }

  const _sanitizeCustomerPhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, serviceClient?.callingCode);

    serviceClientSetValue('phone', _phoneNumber)
    if (_phoneNumber?.length >= 9) {
      getBookingClientDetailsAsync({
        phone: _phoneNumber,
        countryCode: serviceClientWatch('countryCode'),
      })
    }
  }

  const checkServicePrice = (service: BookingService, client: Client): {
    staffPrice: number;
    membershipDiscountAmount: number;
  } => {
    // Start with the base service price
    let highestPrice = service.price;
    let membershipDiscountAmount = 0;

    if (!client) {
      return { staffPrice: highestPrice, membershipDiscountAmount: 0 }
    }

    if (client && client?.clientMembership?.membershipStatus === 'live' && client?.membershipWalletBalance > 0) {
      const membershipServices = client?.clientMembership?.membership?.services;
      const serviceIds = membershipServices?.map((service) => service?.id);
      const serviceNames = membershipServices?.map((service) => service?.name?.toLocaleLowerCase());
      const serviceId = service?.id;
      const serviceName = service?.name?.toLocaleLowerCase();
      if (serviceIds?.includes(serviceId) || serviceNames?.includes(serviceName)) {
        // calculating membershipDiscountAmount discount from highest price
        membershipDiscountAmount = highestPrice * (client?.clientMembership?.membership.rewardPercentage / 100);
        highestPrice = highestPrice - membershipDiscountAmount;
      }
    }
    return { staffPrice: highestPrice, membershipDiscountAmount };
  };

  const confirmPayment = () => {
    closePaymentModal();
    handleNextStep(PURCHASE_SUCCESSFUL_STEP);
  }

  const onChangePaymentMethod = (value: string) => {
    serviceClientSetValue('paymentMethod', value)
  }

  const addRecipientToVoucher = (prefix: string) => {
    // Increment the quantity when adding a new recipient
    const quantity = watch(`${prefix}.quantity`) || 0;
    setValue(`${prefix}.quantity`, quantity + 1);
  }

  const getBookingClientDetailsAsync = async ({
    phone,
    countryCode,
  }: {
    phone: string;
    countryCode: string;
  }) => {
    const result = await searchClient({
      salonId: selectedSalon?.id,
      countryCode,
      phone,
    });
    const clientDetailsData: Client = result?.data?.clientSearch;

    if (clientDetailsData) {
      setServiceClient({
        phone: clientDetailsData.phone,
        countryCode: clientDetailsData?.countryCode,
        callingCode: clientDetailsData?.callingCode,
        fullName: clientDetailsData?.firstName + " " + clientDetailsData?.lastName,
        email: clientDetailsData?.email,
        address: clientDetailsData?.address,
      } as BookingPersonalInformationFormInput);
      setClient(clientDetailsData)
      setRewards(clientDetailsData?.activeClientMilestones)
      const priceCheckedServices = [...selectedServices]?.map((service) => {
        const prices = checkServicePrice(service, clientDetailsData);
        return { ...service, ...prices, };
      });
      setSelectedServices(priceCheckedServices);
    } else {
      setServiceClient({
        phone,
        countryCode,
        callingCode: serviceClientWatch('callingCode'),
        fullName: '',
        email: ''
      } as BookingPersonalInformationFormInput)
      setClient(null)
      setRewards([])
    }
  }

  const getClientDetailsAsync = async ({
    phone,
    countryCode,
    prefix,
  }: {
    phone: string;
    countryCode: string;
    prefix: string;
  }) => {
    const result = await searchClient({
      phone, countryCode, salonId: selectedSalon?.id
    })
    const clientDetailsData = result?.data?.clientSearch
    if (clientDetailsData) {
      setValue(`${prefix}.name`, `${clientDetailsData?.firstName}  ${clientDetailsData?.lastName}`);
      setValue(`${prefix}.email`, clientDetailsData?.email || '');
    }
    previewPackagesPriceAsync();
  }

  useEffect(() => {
    if (serviceClient) {
      serviceClientSetValue('address', serviceClient?.address)
      serviceClientSetValue('fullName', serviceClient?.fullName)
      serviceClientSetValue('email', serviceClient?.email)
      serviceClient?.phone && serviceClientSetValue('phone', serviceClient?.phone)
      serviceClientSetValue('note', serviceClient?.note)
      serviceClientSetValue('callingCode', serviceClient?.callingCode)
      serviceClientSetValue('countryCode', serviceClient?.countryCode)
      serviceClientSetValue('isHomeService', serviceClient?.isHomeService || hasHomeAsOnlyLocation(selectedSalon?.locationType))
    }
  }, [serviceClient, serviceClientSetValue])

  useEffect(() => {
    if (serviceClientWatch('paymentMethod') && serviceClient) {
      setServiceClient({
        address: serviceClientWatch('address') || serviceClient?.address,
        fullName: serviceClientWatch('fullName') || serviceClient?.fullName,
        email: serviceClientWatch('email') || serviceClient?.email,
        phone: serviceClientWatch('phone') || serviceClient?.phone,
        note: serviceClientWatch('note') || serviceClient?.note,
        callingCode: serviceClientWatch('callingCode') || serviceClient?.callingCode,
        countryCode: serviceClientWatch('countryCode') || serviceClient?.countryCode,
        isHomeService:
          serviceClientWatch('isHomeService') ||
          serviceClient?.isHomeService ||
          hasHomeAsOnlyLocation(selectedSalon?.locationType),
        paymentMethod: serviceClientWatch('paymentMethod') || serviceClient?.paymentMethod,
      });
    }
  }, [serviceClientWatch('paymentMethod')])

  const confirmOrderAndProceed = () => {
    if (voucherToBeRedeemed) {
      submitAppointment()
      return;
    }

    if (Array?.isArray(selectedServices) && selectedServices?.length && !voucherToBeRedeemed) {
      if (!hidePaymentAndContinue && priceSummary && priceSummary?.depositAmount > 0 && !showDepositView && !voucherToBeRedeemed) {
        setShowDepositView(true);
        return;
      }

      submitAppointment()
      return;
    }
  }
  const getVoucherName = (packages: {
    packageId: string,
    quantity: number
  }[]) => {
    // loop through packages, check for package in selectedPackages, return the name, return a merged name with comma seperated
    let name = '';
    packages.forEach((packageItem) => {
      const selectedPackage = selectedPackages.find((selectedPackage) => selectedPackage?.id === packageItem?.packageId);
      if (selectedPackage) {
        name += `${selectedPackage.name}${packageItem.quantity > 1 ? ` x${packageItem.quantity}` : ''}, `;
      }
    });
    return name.slice(0, -2);
  }

  const modifySelectionTime = () => {
    handleNextStep(TIME_AND_DATE)
  }

  return (
    <>
      <div className='w-full flex flex-col px-[10px] xl:px-[80px] pb-[50px]'>
        <BookingBreadcrumb />
        <div className='w-full flex flex-col-reverse xl:flex-row gap-4 mb-[120px]'>
          {/* Card */}
          <BookingDetailsCard previewPackagesPriceIsLoading={previewPackagesPriceIsLoading} hide={isMobile} client={client} />
          {/* Cart Details */}
          {!showConfirmOrderScreen && (Array.isArray(selectedPackages) && selectedPackages?.length > 0 || Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed) ? (
            <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
              <div className='w-full hidden lg:flex justify-between'>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Enter details</Paragraph>
                <BookingSteps page={"user-details"} />
              </div>

              {/* rewards */}
              {Array?.isArray(rewards) && rewards?.length ? (
                <div className='w-full flex flex-col space-y-3'>
                  <Paragraph size='b5' color={COLORS.BLACK} className='flex items-center gap-x-3'>
                    Reward available
                  </Paragraph>
                  <div className='w-full flex flex-col space-y-3 flex-wrap'>
                    {rewards?.slice(0, 1).map((reward) => {
                      return (
                        <div
                          key={reward?.id}
                          className={`w-full h-[80px] px-4 bg-green-300 rounded-lg cursor-pointer flex justify-between space-x-4 ${reward.id === selectedReward?.id ? 'shadow-md shadow-green-200 border border-black' : ''
                            }`}
                          style={{
                            // backgroundImage: 'url("https://res.cloudinary.com/dqqrgidob/image/upload/v1722591262/ru2wmnxkhjrybwpwrr1v.png")',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '10%',
                            backgroundPosition: "bottom right"
                          }}
                        >
                          <div className='flex space-x-4 py-3'>
                            <span><SvgWhiteDiamond width="30px" height="30px" /></span>
                            <div className='flex flex-col space-y-1'>
                              <Paragraph size='b5' color={COLORS.WHITE}>
                                You have a reward available
                              </Paragraph>
                              {rewardShowAll ?
                                <>
                                  <Paragraph size='b5' color={COLORS.WHITE} weight='semiBold'>
                                    {reward?.milestone?.services?.length === services?.length ? 'All services' : reward?.milestone?.services?.map((service) => service?.name)?.join(", ")}
                                  </Paragraph>
                                  <Paragraph size='b5' color={COLORS.WHITE} weight='semiBold'>
                                    {reward?.milestone?.customerReceivesType === 'percentage' ? `${reward?.milestone?.customerReceives}%` : formatInToPrice(reward?.milestone?.customerReceives, businessInfo?.country?.currency?.symbol)} off
                                  </Paragraph>
                                  <span
                                    className='p-0 underline text-white cursor-pointer font-bold text-b6'
                                    onClick={() => setRewardShowAll(false)}
                                    role='button'
                                  >
                                    Click to view less
                                  </span>
                                </> : <>
                                  <Paragraph size='b5' color={COLORS.WHITE} weight='semiBold'>
                                    {reward?.milestone?.services?.length === services?.length ? 'All services' : limitString(reward?.milestone?.services?.map((service) => service?.name)?.join(", "), 30)}
                                  </Paragraph>
                                  <span
                                    className='p-0 underline text-white cursor-pointer font-bold text-b6'
                                    onClick={() => setRewardShowAll(true)}
                                    role='button'
                                  >
                                    Click to view more
                                  </span>
                                </>}
                            </div>
                          </div>
                          <div role='button' className='h-[80px] pl-2 flex items-center border-l border-white' onClick={() => {
                            setSelectedReward(reward)
                            setRewardSelected(true)
                          }}>
                            <Paragraph size='b5' color={COLORS.WHITE} weight='bold'>
                              Apply
                            </Paragraph>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : null}
              {client?.clientMembership ?
                <div className='w-full flex justify-between p-4 rounded-md text-green-300 bg-green-300/10 space-x-4 items-start'>
                  <div className='flex items-start space-x-4'>
                    <SvgMemberlove width='34px' height='34px' />
                    <div className='w-full flex flex-col space-y-4 '>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREEN[300]}
                        weight='semiBold'
                      >
                        {client?.clientMembership?.membership?.name} Membership
                      </Paragraph>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREEN[300]}
                      >
                        {client?.clientMembership?.membership?.rewardPercentage}% Off
                      </Paragraph>
                    </div>
                  </div>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREEN[300]}
                    weight='semiBold'
                  >
                    Applied
                  </Paragraph>
                </div> : null}
              <div className='w-full flex flex-col space-y-2'>
                {selectedStaff ? <SelectedStaffView selectedStaff={selectedStaff} openSelectStaffPage={openSelectStaffPage} /> : null}
                {appointmentDateTime ? <SelectedCheckinTime appointmentDateTime={appointmentDateTime} modifySelectionTime={modifySelectionTime} /> : null}
                {isMobile ? <SelectedServicesView /> : null}
              </div>
              {Array.isArray(selectedPackages) && selectedPackages?.length > 0 ? (
                <form onSubmit={handleSubmit(onSubmitDetails)} className='w-full flex flex-col gap-4'>
                  {Array.isArray(selectedPackages) && selectedPackages.map((selectedSalonPackage) => {
                    const prefix = selectedSalonPackage?.id?.replace(/\s/g, '');
                    return (
                      <div className='w-full flex flex-col gap-4' key={selectedSalonPackage?.id}>
                        <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']} className='uppercase'>{selectedSalonPackage?.name}</Paragraph>
                        <div className='w-full flex flex-col gap-4'>
                          <Controller
                            control={control}
                            name={`${prefix}.purchaseType`}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <div className="w-full flex flex-col space-y-2">
                                  <FormLabel htmlFor={`${prefix}-purchaseType`}>Who are you purchasing the package for? </FormLabel>
                                  <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                                    {Array.isArray(MYSELF_AND_SOMEONE_ELSE_BOOKING_INPUT) && MYSELF_AND_SOMEONE_ELSE_BOOKING_INPUT.length
                                      ?
                                      MYSELF_AND_SOMEONE_ELSE_BOOKING_INPUT.map((purchaseType: {
                                        label: string;
                                        value: string;
                                      }) => {
                                        return (
                                          <div
                                            className="flex"
                                            onClick={() => onChange(purchaseType?.value)}
                                            key={purchaseType?.label}
                                          >
                                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                              <Checkbox
                                                isChecked={value === purchaseType?.value ? true : false}
                                              />
                                              <span className="text-grey-900 whitespace-nowrap">
                                                {purchaseType?.label}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      })
                                      : null}
                                  </div>
                                </div>
                              );
                            }}
                          />
                          {watch(`${prefix}.purchaseType`) ? (
                            <>
                              <FormPhone
                                country={{
                                  disabled: false,
                                  onSelect: (code, country: string) => {
                                    setValue(`${prefix}.countryCode`, country || selectedSalon?.countryCode);
                                    setValue(`${prefix}.callingCode`, code || selectedSalon?.callingCode);
                                  },
                                  value: watch(`${prefix}.countryCode`),
                                }}
                                phone={{
                                  name: `${prefix}.phone`,
                                  type: "text",
                                  placeholder: "9151930463",
                                  rules: {
                                    required: ERRORS.PHONE_REQUIRED,
                                    pattern: REGEX_PATTERNS.NUMBER,
                                    onChange: (e) => {
                                      _sanitizePhoneNumber(prefix, e.target.value);
                                    },
                                    disabled: false,
                                  },
                                  register,
                                  id: `${prefix}-phone-number`,
                                  label: "Recipient’s phone number *",
                                }}
                              />
                              <Input name={`${prefix}.name`} label="Recipient’s name *" id={`${prefix}-name`} type="text" placeholder='Jane Okon' control={control} rules={{
                                required: ERRORS.NAME_REQUIRED,
                              }} />
                              <Input name={`${prefix}.email`} label="Recipient’s email *" id={`${prefix}-email`} type="email" placeholder='email@acme.com' control={control} rules={{
                                required: ERRORS.EMAIL_REQUIRED,
                                pattern: REGEX_PATTERNS.EMAIL,
                              }} />

                              {selectedSalonPackage?.quantity > 1 && watch(`${prefix}.quantity`) !== selectedSalonPackage?.quantity - 1 ? (
                                <Button
                                  variant='light'
                                  size='xs'
                                  rounded='lg'
                                  type="button"
                                  className='w-fit border border-grey-100'
                                  onClick={() => addRecipientToVoucher(prefix)}
                                >
                                  Add new recipient
                                  <SvgPlus width="14px" height="14px" />
                                </Button>
                              ) : null}
                            </>
                          ) : null}

                          <div className="w-full">
                            {watch(`${prefix}.quantity`) && watch(`${prefix}.quantity`) > 0 ? (
                              <>
                                {Array.from({ length: watch(`${prefix}.quantity`) }, (_, index) => (
                                  <div className='w-full my-4 flex flex-col gap-4'>
                                    <Paragraph size='b6' weight='normal' color={COLORS.GREY['300']} className='uppercase'>RECIPIENT {index + 2}</Paragraph>
                                    <FormPhone
                                      country={{
                                        disabled: false,
                                        onSelect: (code, country: string) => {
                                          setValue(`${prefix}.otherRecipients.${index}.countryCode`, country);
                                          setValue(`${prefix}.otherRecipients.${index}.callingCode`, code);
                                        },
                                        value: watch(`${prefix}.otherRecipients.${index}.countryCode`),
                                      }}
                                      phone={{
                                        name: `${prefix}.otherRecipients.${index}.phone`,
                                        type: "text",
                                        placeholder: "9151930463",
                                        rules: {
                                          required: ERRORS.PHONE_REQUIRED,
                                          pattern: REGEX_PATTERNS.NUMBER,
                                          onChange: (e) => {
                                            _sanitizePhoneNumber(`${prefix}.otherRecipients.${index}`, e.target.value);
                                          },
                                          disabled: false,
                                        },
                                        register,
                                        id: `${prefix}-${index}-phone-number`,
                                        label: "Recipient’s phone number *",
                                      }}
                                    />
                                    <Input name={`${prefix}.otherRecipients.${index}.name`} label="Recipient’s name *" id={`${prefix}-${index}-name`} type="text" placeholder='Jane Okon' control={control} rules={{
                                      required: ERRORS.NAME_REQUIRED,
                                    }} />
                                    <Input name={`${prefix}.otherRecipients.${index}.email`} label="Recipient’s email *" id={`${prefix}-${index}-email`} type="email" placeholder='email@acme.com' control={control} rules={{
                                      required: ERRORS.EMAIL_REQUIRED,
                                      pattern: REGEX_PATTERNS.EMAIL,
                                    }} />
                                  </div>
                                ))}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <Button
                    variant='primary'
                    className=''
                    size='lg'
                    rounded='lg'
                    disabled={createVoucherIsLoading}
                    loading={createVoucherIsLoading}
                  >
                    Pay now
                  </Button>
                </form>
              ) : null}

              {!showDepositView && Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed ? (
                <>
                  <form onSubmit={serviceClientHandleSubmit(onSubmit)} className="w-full space-y-6" autoComplete='off'>
                    <FormPhone
                      country={{
                        disabled: voucherToBeRedeemed || existingAppointment ? true : false,
                        onSelect: (code, country: string) => {
                          serviceClientSetValue(`countryCode`, country);
                          serviceClientSetValue(`callingCode`, code);
                        },
                        value: serviceClientWatch(`countryCode`),
                      }}
                      phone={{
                        name: "phone",
                        type: "text",
                        placeholder: "9151930463",
                        rules: {
                          required: ERRORS.PHONE_REQUIRED,
                          pattern: REGEX_PATTERNS.NUMBER,
                          onChange: (e) => {
                            _sanitizeCustomerPhoneNumber(e.target.value);
                          },
                          disabled: voucherToBeRedeemed || existingAppointment ? true : false,
                        },
                        register: serviceClientRegister,
                        id: "phone-number",
                        label: "Phone Number",
                      }}
                      helperText={getHelperTextForReactHookFormErrors(
                        serviceErrors?.phone?.message as string
                      )}
                    />
                    <Input name="fullName" label="Name" id="full-name" type="text" placeholder='Jane Okon' control={serviceClientControl} rules={{
                      required: ERRORS.NAME_REQUIRED,
                    }} error={serviceErrors.fullName} disabled={voucherToBeRedeemed || existingAppointment ? true : false} />
                    <Input name="email" label="Email" id="email" type="email" placeholder='email@acme.com' control={serviceClientControl} rules={{
                      required: ERRORS.EMAIL_REQUIRED,
                      pattern: REGEX_PATTERNS.EMAIL,
                    }} error={serviceErrors.email} disabled={voucherToBeRedeemed || existingAppointment ? true : false} />
                    {selectedSalon?.locationType === 'home' || selectedSalon?.locationType === 'flexible' ? (
                      <>
                        <Controller
                          control={serviceClientControl}
                          name="isHomeService"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <div className="w-full flex flex-col space-y-4">
                                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                                  <div
                                    className="flex"
                                    onClick={() => onChange(!value)}
                                  >
                                    <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                      <Checkbox
                                        isChecked={value}
                                      />
                                      <span className="text-grey-900 whitespace-nowrap">
                                        Are you booking a home service?
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        />
                        {serviceClientWatch('isHomeService') ? (
                          <Input name="address" label="Home Address" id="home-address" type="text" placeholder='Enter home address' control={serviceClientControl} rules={{
                            required: watch('isHomeService') ? ERRORS.HOME_ADDRESS_REQUIRED : false,
                          }} error={serviceErrors.address} />
                        ) : null}
                      </>
                    ) : null}
                    <Controller
                      control={serviceClientControl}
                      name="note"
                      render={({
                        field: { onChange, onBlur, name, ref, value },
                      }) => {
                        const maxLength = 400;
                        const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                          serviceErrors?.note?.message as string
                        );
                        const helperTexts = [];

                        helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                        if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                        return (
                          <FormTextarea
                            type="text"
                            id="note"
                            label="Note"
                            placeholder="Add a note"
                            {...{
                              ref,
                              name,
                              value,
                              onChange,
                              onBlur,
                              maxLength,
                            }}
                            helperText={helperTexts}
                          />
                        );
                      }}
                    />

                    {priceSummary?.depositAmount > 0 && !voucherToBeRedeemed && priceSummary?.totalPrice !== 0 ? (
                      <Button
                        variant='primary'
                        className=''
                        size='lg'
                        rounded='lg'
                        disabled={!priceSummary}
                      >
                        Continue
                      </Button>
                    ) : (
                      <>
                        {priceSummary?.depositAmount === 0 && priceSummary?.totalPrice !== 0 ? (
                          <Controller
                            control={serviceClientControl}
                            name="paymentMethod"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <div className="w-full flex flex-col space-y-4">
                                  <div className="w-full flex flex-col gap-6">
                                    {Array.isArray(PAYMENT_METHODS) && PAYMENT_METHODS.length
                                      ? PAYMENT_METHODS
                                        .filter((paymentMethod) =>
                                          paymentMethod.value !== 'client_wallet' || (client?.walletBalance > 0)
                                        )
                                        .map((paymentMethod: {
                                          label: string;
                                          value: string;
                                        }) => {
                                          return (
                                            <div
                                              className="flex"
                                              onClick={priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? null : () => onChange(paymentMethod?.value)}
                                              key={paymentMethod?.value}
                                            >
                                              <div className={`flex items-start ${priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? 'cursor-not-allowed' : 'cursor-pointer'} text-b5 text-grey-900 space-x-2`}>
                                                <RadioButton
                                                  isChecked={value === paymentMethod?.value}
                                                  disabled={priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? true : false}
                                                />
                                                <div className='flex flex-col space-y-2'>
                                                  <div className="text-grey-900 flex space-x-2 whitespace-nowrap">
                                                    <span className='text-grey-900 text-b5 whitespace-nowrap'>{paymentMethod?.label}</span>
                                                    {paymentMethod?.value === 'client_wallet' && client ? (
                                                      <span className='text-grey-900 text-b5 whitespace-nowrap'>
                                                        ({formatInToPrice(client?.walletBalance || 0, businessInfo?.country?.currency?.symbol)})
                                                      </span>
                                                    ) : null}
                                                  </div>
                                                  {membershipIsActive ?
                                                    <div className='w-full flex space-x-3 items-center'>
                                                      <SvgGreyCheckbox />
                                                      <span className='text-grey-900 text-b5 whitespace-nowrap'>
                                                        Membership wallet applied ({formatInToPrice(client?.membershipWalletBalance || 0, businessInfo?.country?.currency?.symbol)})
                                                      </span>
                                                    </div> : null}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </div>
                              );
                            }}
                          />
                        ) : null}
                        <Button
                          variant='primary'
                          className=''
                          disabled={createAppointmentIsLoading}
                          loading={createAppointmentIsLoading}
                          size='lg'
                          rounded='lg'
                        >
                          {voucherToBeRedeemed ? 'Redeem voucher' : 'Continue'}
                        </Button>
                      </>
                    )}

                    {businessInfo?.termsAndConditions && cleanHtml !== '<p><br></p>' ?
                      <span className="text-grey-900 whitespace-wrap w-full text-center line-clamp-1 font-semibold">
                        By booking this appointment, you agree to {businessInfo?.name}’s <span role='button' className='text-green-300 underline' onClick={openTermAndConditions}>terms and conditions.</span>
                      </span>
                      : null}
                  </form>
                </>
              ) : null}
              {showDepositView && appointmentDateTime ? (
                <div className='w-full flex flex-col space-y-4'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    To secure your appointment, please make a deposit. Thank you, and we look forward to your visit!
                  </Paragraph>
                  <div className="w-full flex justify-between px-5 py-3 border border-green-300 rounded-sm shadow-sm">
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>
                      Deposit required
                    </Paragraph>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>
                      {membershipIsActive && client?.membershipWalletBalance < priceSummary?.depositAmount ?
                        <span className='flex space-x-3'><span className='line-through text-grey-300'>{formatInToPrice(priceSummary?.depositAmount, businessInfo?.country?.currency?.symbol)}</span> <span className='text-grey-900'>{formatInToPrice(priceSummary?.depositAmount - client?.membershipWalletBalance, businessInfo?.country?.currency?.symbol)}</span></span> :
                        <span className='flex space-x-3 text-grey-900'>{formatInToPrice(priceSummary?.depositAmount, businessInfo?.country?.currency?.symbol)}</span>
                      }
                    </Paragraph>
                  </div>

                  <div className="w-full flex flex-col space-y-4">
                    <div className="w-full flex flex-col gap-6">
                      {Array.isArray(PAYMENT_METHODS) && PAYMENT_METHODS.length
                        ? PAYMENT_METHODS
                          .filter((paymentMethod) =>
                            paymentMethod.value !== 'client_wallet' || (client?.walletBalance > 0)
                          )
                          .map((paymentMethod: {
                            label: string;
                            value: string;
                          }) => {
                            return paymentMethod?.value !== 'unpaid' && (
                              <div
                                className="flex"
                                onClick={priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? null : () => onChangePaymentMethod(paymentMethod?.value)}
                                key={paymentMethod?.value}
                              >
                                <div className={`flex items-start ${priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? 'cursor-not-allowed' : 'cursor-pointer'} text-b5 text-grey-900 space-x-2`}>
                                  <RadioButton
                                    isChecked={serviceClientWatch('paymentMethod') === paymentMethod?.value}
                                    disabled={priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? true : false}
                                  />
                                  <div className='flex flex-col space-y-2'>
                                    <div className="text-grey-900 flex space-x-2 whitespace-nowrap">
                                      <span className='text-grey-900 text-b5 whitespace-nowrap'>{paymentMethod?.label}</span>
                                      {paymentMethod?.value === 'client_wallet' && client ? (
                                        <span className='text-grey-900 text-b5 whitespace-nowrap'>
                                          ({formatInToPrice(client?.walletBalance || 0, businessInfo?.country?.currency?.symbol)})
                                        </span>
                                      ) : null}
                                    </div>
                                    {membershipIsActive ?
                                      <div className='w-full flex space-x-3 items-center'>
                                        <SvgGreyCheckbox />
                                        <span className='text-grey-900 text-b5 whitespace-nowrap'>
                                          Membership wallet applied ({formatInToPrice(client?.membershipWalletBalance || 0, businessInfo?.country?.currency?.symbol)})
                                        </span>
                                      </div> : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <Button
                    variant='secondary'
                    className=''
                    disabled={createAppointmentIsLoading}
                    loading={createAppointmentIsLoading}
                    size='lg'
                    rounded='lg'
                    type='button'
                    onClick={() => processAppointmentPayment('deposit_payment')}
                  >
                    Pay deposit
                  </Button>
                  <Button
                    variant='primary'
                    className=''
                    disabled={createAppointmentIsLoading}
                    loading={createAppointmentIsLoading}
                    size='lg'
                    rounded='lg'
                    type='button'
                    onClick={() => processAppointmentPayment('full_payment')}
                  >
                    Pay in full
                  </Button>
                  {businessInfo?.termsAndConditions && cleanHtml !== '<p><br></p>' ?
                    <span className="text-grey-900 whitespace-wrap w-full text-center line-clamp-1 font-semibold">
                      By booking this appointment, you agree to {businessInfo?.name}’s <span role='button' className='text-green-300 underline' onClick={openTermAndConditions}>terms and conditions.</span>
                    </span>
                    : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {showConfirmOrderScreen && (Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed) ? (
            <div className='w-full flex flex-col gap-4'>
              <div className='w-full h-fit flex flex-col px-2 gap-8'>
                {/* appointment details */}
                <div className='w-full px-5 py-4 flex flex-col bg-grey-50 rounded-sm space-y-3'>
                  <div className='w-full flex justify-start items-center gap-2 text-green-300'>
                    <SvgCalendar width="20px" height="20px" />
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Appointment Details</Paragraph>
                  </div>
                  {appointmentDateTime ?
                    <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                      <div className='flex flex-col space-y-2'>
                        <Paragraph size='b6' weight='semiBold' color={COLORS.GREY[900]}>Date</Paragraph>
                        <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[900]}>{getNumberMonthYearAndTimeFromDate(appointmentDateTime)}</Paragraph>
                      </div>
                      <Button
                        variant='light'
                        size='sm'
                        className='m-0 bg-green-300/10 text-green-300'
                        type='button'
                        onClick={() => {
                          handleNextStep &&
                            handleNextStep(TIME_AND_DATE)
                        }}
                      >
                        Edit
                      </Button>
                    </div> : null}
                  {serviceClient ?
                    <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                      <div className='flex flex-col space-y-2'>
                        <Paragraph size='b6' weight='semiBold' color={COLORS.GREY[900]}>Who</Paragraph>
                        {voucherToBeRedeemed ?
                          <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[900]}>
                            {voucherToBeRedeemed?.client?.firstName} · <span className='text-grey-300'>{voucherToBeRedeemed?.client?.phone} | {limitString(voucherToBeRedeemed?.client?.email, 5, true)}</span>
                          </Paragraph> :
                          <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[900]}>
                            {serviceClientWatch('fullName')} · <span className='text-grey-300'>{serviceClientWatch('phone')} | {limitString(serviceClientWatch('email'), 5, true)}</span>
                          </Paragraph>}
                      </div>
                      <Button
                        variant='light'
                        size='sm'
                        className='m-0 bg-green-300/10 text-green-300'
                        type='button'
                        onClick={() => {
                          setShowConfirmOrderScreen(false)
                        }}
                      >
                        Edit
                      </Button>
                    </div> : null}
                </div>

                {/* cart details */}
                <div className='w-full px-5 py-4 flex flex-col bg-grey-50 rounded-sm space-y-3 overflow-y-auto'>
                  <div className='w-full flex justify-between items-center'>
                    <div className='w-full flex justify-start items-center gap-2 text-green-300'>
                      <SvgCartBag width="20px" height="20px" />
                      <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Cart</Paragraph>
                    </div>
                    <Button
                      variant='text'
                      size='none'
                      type='button'
                      className='border-0 text-green-300 underline'
                      fontSize='b4'
                      fontWeight='semiBold'
                      onClick={() => handleNextStep(INITIAL_STEP)}
                    >
                      Edit
                    </Button>
                  </div>
                  {Array.isArray(selectedServices) && selectedServices.map((service) => {
                    const quantity = 1
                    return (
                      <div className='relative'>
                        <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                          <div className='flex flex-col space-y-2'>
                            <Paragraph size='b6' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                            <div className='flex space-x-2'>
                              {service?.membershipDiscountAmount > 0 ?
                                <Paragraph
                                  size="b6"
                                  color={COLORS.GREEN[900]}
                                  className="line-through"
                                >
                                  {businessInfo?.country?.currency?.symbol}
                                  <FormatNumber
                                    value={(service?.staffPrice + service?.membershipDiscountAmount) * quantity}
                                  />
                                </Paragraph>
                                : null}
                              <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN['900']}>{service?.staffPrice ? formatInToPrice(service?.staffPrice * quantity, businessInfo?.country?.currency?.symbol) : service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {!service?.staffPrice ? formatInToPrice(service?.price * quantity, businessInfo?.country?.currency?.symbol) : null} · <span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                            </div>
                          </div>

                          <div className='flex items-center py-2 px-4 rounded-xl text-green-300 bg-green-300/10'>
                            <SvgUser width="16px" height="16px" />
                            <span className='text-green-300 text-b6 font-semibold'>{service?.staff?.user?.firstName}</span>
                          </div>
                        </div>
                        <div className='absolute -top-1.5 -right-1.5'>
                          <Button
                            variant='icon'
                            size="none"
                            type="button"
                            rounded='lg'
                            className="p-2 border border-red-300 text-red-500 shadow-sm"
                            onClick={() => deleteService(service?.id)}
                          >
                            <SvgTrash width="12px" height="12px" />
                          </Button>
                        </div>
                      </div>
                    )
                  })}

                  {Array.isArray(voucherServices) && voucherServices.map((service) => {
                    return (
                      <div className='relative'>
                        <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                          <div className='flex flex-col space-y-2'>
                            <Paragraph size='b6' weight='normal' color={COLORS.GREY['300']}>{service?.name} x{service?.cartQuantity}</Paragraph>
                            <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN['900']}>{formatInToPrice(0, businessInfo?.country?.currency?.symbol)}</Paragraph>
                          </div>

                          <div className='flex items-center py-2 px-4 rounded-xl text-green-300 bg-green-300/10'>
                            <SvgUser width="16px" height="16px" />
                            <span className='text-green-300 text-b6 font-semibold'>{service?.staff?.user?.firstName}</span>
                          </div>
                        </div>
                        <div className='absolute -top-1.5 -right-1.5'>
                          <Button
                            variant='icon'
                            size="none"
                            type="button"
                            rounded='lg'
                            className="p-2 border border-red-300 text-red-500 shadow-sm"
                            onClick={() => deleteService(service?.id)}
                          >
                            <SvgTrash width="12px" height="12px" />
                          </Button>
                        </div>
                      </div>
                    )
                  })}

                  <BookingDetailsCard showOnlyPrices={true} client={client} />
                </div>
              </div>
              {showDepositView && appointmentDateTime ? (
                <div className='w-full flex flex-col space-y-4'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    To secure your appointment, please make a deposit. Thank you, and we look forward to your visit!
                  </Paragraph>
                  <div className="w-full flex justify-between px-5 py-3 border border-green-300 rounded-sm shadow-sm">
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>
                      Deposit required
                    </Paragraph>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>
                      {membershipIsActive && client?.membershipWalletBalance < priceSummary?.depositAmount ?
                        <span className='flex space-x-3'><span className='line-through text-grey-300'>{formatInToPrice(priceSummary?.depositAmount, businessInfo?.country?.currency?.symbol)}</span> <span className='text-grey-900'>{formatInToPrice(priceSummary?.depositAmount - client?.membershipWalletBalance, businessInfo?.country?.currency?.symbol)}</span></span> :
                        <span className='flex space-x-3 text-grey-900'>{formatInToPrice(priceSummary?.depositAmount, businessInfo?.country?.currency?.symbol)}</span>
                      }
                    </Paragraph>
                  </div>

                  <div className="w-full flex flex-col space-y-4">
                    <div className="w-full flex flex-col gap-6">
                      {Array.isArray(PAYMENT_METHODS) && PAYMENT_METHODS.length
                        ? PAYMENT_METHODS
                          .filter((paymentMethod) =>
                            paymentMethod.value !== 'client_wallet' || (client?.walletBalance > 0)
                          )
                          .map((paymentMethod: {
                            label: string;
                            value: string;
                          }) => {
                            return paymentMethod?.value !== 'unpaid' && (
                              <div
                                className="flex"
                                onClick={priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? null : () => onChangePaymentMethod(paymentMethod?.value)}
                                key={paymentMethod?.value}
                              >
                                <div className={`flex items-start ${priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? 'cursor-not-allowed' : 'cursor-pointer'} text-b5 text-grey-900 space-x-2`}>
                                  <RadioButton
                                    isChecked={serviceClientWatch('paymentMethod') === paymentMethod?.value}
                                    disabled={priceSummary && priceSummary?.totalPaid === 0 && paymentMethod?.value === 'card' ? true : false}
                                  />
                                  <div className='flex flex-col space-y-2'>
                                    <div className="text-grey-900 flex space-x-2 whitespace-nowrap">
                                      <span className='text-grey-900 text-b5 whitespace-nowrap'>{paymentMethod?.label}</span>
                                      {paymentMethod?.value === 'client_wallet' && client ? (
                                        <span className='text-grey-900 text-b5 whitespace-nowrap'>
                                          ({formatInToPrice(client?.walletBalance || 0, businessInfo?.country?.currency?.symbol)})
                                        </span>
                                      ) : null}
                                    </div>
                                    {membershipIsActive ?
                                      <div className='w-full flex space-x-3 items-center'>
                                        <SvgGreyCheckbox />
                                        <span className='text-grey-900 text-b5 whitespace-nowrap'>
                                          Membership wallet applied ({formatInToPrice(client?.membershipWalletBalance || 0, businessInfo?.country?.currency?.symbol)})
                                        </span>
                                      </div> : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <Button
                    variant='secondary'
                    className=''
                    disabled={createAppointmentIsLoading}
                    loading={createAppointmentIsLoading}
                    size='lg'
                    rounded='lg'
                    type='button'
                    onClick={() => processAppointmentPayment('deposit_payment')}
                  >
                    Pay deposit
                  </Button>
                  <Button
                    variant='primary'
                    className=''
                    disabled={createAppointmentIsLoading}
                    loading={createAppointmentIsLoading}
                    size='lg'
                    rounded='lg'
                    type='button'
                    onClick={() => processAppointmentPayment('full_payment')}
                  >
                    Pay in full
                  </Button>
                </div>
              ) : null}
              {!showDepositView ?
                <div className="w-full flex lg:hidden fixed bottom-[20px] h-[60px] px-[20px]">
                  <Button
                    variant='primary'
                    className=''
                    size='lg'
                    rounded='lg'
                    disabled={createAppointmentIsLoading}
                    loading={createAppointmentIsLoading}
                    onClick={() => confirmOrderAndProceed()}
                  >
                    <div className='w-full flex justify-between items-center px-4 py-2'>
                      <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE} className='w-full text-center'>Book appointment</Paragraph>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                  </Button>
                </div>
                : null}
            </div>
          ) : null}
          {showConfirmOrderScreen && Array.isArray(selectedPackages) && selectedPackages?.length > 0 ? (
            <>
              <div className='w-full h-fit flex flex-col px-2 gap-8'>
                {/* cart details */}
                <div className='w-full px-5 py-4 flex flex-col bg-grey-50 rounded-sm space-y-3'>
                  <div className='w-full flex justify-between items-center'>
                    <div className='w-full flex justify-start items-center gap-2 text-green-300'>
                      <SvgCartBag width="20px" height="20px" />
                      <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Cart</Paragraph>
                    </div>
                  </div>
                  {Array.isArray(voucherInputData?.recipients) && voucherInputData?.recipients.map((recipient) => {
                    // const price = voucher?.usesCustomPrice ? voucher?.customPrice : voucher?.totalPrice;
                    return (
                      <div className='relative'>
                        <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                          <div className='flex flex-col space-y-2'>
                            <Paragraph size='b6' weight='normal' color={COLORS.GREY['300']}>{recipient?.firstName} {recipient?.lastName}</Paragraph>
                            <Paragraph size='b6' weight='semiBold' color={COLORS.GREY['400']}>{getVoucherName(recipient?.packages)} Packages</Paragraph>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  <BookingDetailsCard showOnlyPrices={true} />
                </div>
              </div>
              <div className="w-full flex lg:hidden fixed bottom-[20px] h-[60px] px-[20px]">
                <Button
                  variant='primary'
                  className=''
                  size='lg'
                  rounded='lg'
                  disabled={createVoucherIsLoading}
                  loading={createVoucherIsLoading}
                  onClick={() => processVoucherPurchase()}
                >
                  <div className='w-full flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE} className='w-full text-center'>Purchase voucher</Paragraph>
                    <SvgArrowRight width="16px" height="16px" />
                  </div>
                </Button>
              </div>
            </>
          ) : null}
          <div className='w-full flex lg:hidden flex-col space-y-4'>
            <div className='w-full flex flex-col space-y-2'>
              <div className='w-full flex space-x-2 items-center' onClick={() => showConfirmOrderScreen ? setShowConfirmOrderScreen(false) : handleNextStep(PHONE_FORM_STEP)}>
                <SvgArrowLeft />
                <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]} className=''>Back {!showConfirmOrderScreen ? 'to Phone' : null}</Paragraph>
              </div>
              <Heading variant='h1' size='h9' weight='bold'>{showConfirmOrderScreen ? 'Checkout - Confirm your details' : 'Checkout - Enter your details'}</Heading>
            </div>
          </div>
        </div>
      </div>

      <PaymentModal
        isVisible={paymentModalIsVisible}
        closeModal={closePaymentModal}
        confirmPayment={confirmPayment}
        paymentAuthorization={paymentAuthorizationUrl}
        salonId={selectedSalon?.id}
        propertyId={bookedAppointment?.id}
        propertyType={selectedServices?.length ? 'appointment' : selectedPackages?.length ? 'voucher' : ''}
      ></PaymentModal>

      {isTermAndConditionsModalVisible ?
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[99999]">
          <div className="table-row">
            <div className="table-cell align-middle">
              <div className='w-full flex flex-col max-w-[600px] px-4'>
                <div className='w-full flex justify-between items-start bg-grey-50 p-6'>
                  <div className='w-full flex flex-col space-y-4'>
                    <Heading variant='h1' size='h10' color={COLORS.BLACK} weight='bold'>Terms and Conditions</Heading>
                    {/* <Paragraph size="b6" color={COLORS.GREY[300]} weight="semiBold">
                      Last updated: 8th November, 2024
                    </Paragraph> */}
                  </div>

                  <span
                    className="bg-[#F3F6F699] p-1 cursor-pointer"
                    onClick={() => {
                      closeTermAndConditionsModal()
                    }}
                    role='button'
                  >
                    <SvgClose width="20px" height="20px" />
                  </span>
                </div>
                <div className='w-full bg-[#fafafa] py-[30px] px-6'>
                  <div className='w-full rounded-md shadow-md p-6 bg-white'>
                    <div className='w-full h-[400px] overflow-y-auto'>
                      <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : null}

      {isNotifyClientModal ?
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[99999]">
          <div className="table-row">
            <div className="w-full table-cell align-middle">
              <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>You’re about to make {oldDetails?.fullName !== serviceClient?.fullName ? 'name' : oldDetails?.email !== serviceClient?.email ? 'email' : ''} change</Heading>
                  <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                    Do you want to change the booking {oldDetails?.fullName !== serviceClient?.fullName ? 'name' : oldDetails?.email !== serviceClient?.email ? 'email' : ''} from {oldDetails?.fullName !== serviceClient?.fullName ? oldDetails?.fullName : oldDetails?.email !== serviceClient?.email ? oldDetails?.email : ''}?
                  </Paragraph>

                  <Button
                    variant="primary"
                    size='lg'
                    rounded='lg'
                    fontSize='b5'
                    className='capitalize'
                    onClick={() => closeNotifyClientModal_('continue')}
                  >
                    Change {oldDetails?.fullName !== serviceClient?.fullName ? 'name' : oldDetails?.email !== serviceClient?.email ? 'email' : ''}
                  </Button>
                  <Button
                    variant="text"
                    size='none'
                    rounded='none'
                    fontSize='b5'
                    className='mx-0'
                    onClick={() => closeNotifyClientModal_('edit')}
                  >
                    Keep Old {oldDetails?.fullName !== serviceClient?.fullName ? 'name' : oldDetails?.email !== serviceClient?.email ? 'email' : ''}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> : null}
      <SelectStaff
        isVisible={isSelectStaffModalVisible}
        closeModal={closeSelectStaffPage}
        staffList={selectedSalon?.salonStaffs}
        selectedStaff={selectedStaff}
        service={selectedServices?.length ? selectedServices[0] : null}
      />
      <BookingSiteWorkFlow
        isWorkFlowFormModalVisible={isWorkFlowFormModalVisible}
        addToast={addToast}
        businessInfo={businessInfo}
        workFlowData={workFlowForm}
        closeModal={completeBooking}
        serviceClient={serviceClient}
      />
    </>
  )
}

export default CheckoutUserDetails
