import React, { useEffect, useState } from 'react'
import { Button, FormTextarea, Input, Modal, Paragraph, SvgClose } from 'ui'
import { ClientDetailsInput, CustomSectionsInput, FormModalProps, InputField } from '../types'
import { COLORS } from 'constants/colors'
import { Controller, useForm } from 'react-hook-form'
import { MAXIMUM_NOTE_LENGTH } from 'constants/form'
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form'
import { CLIENT_DETAILS_APPLICABLE_FIELDS } from '../constants'
import { cleanUpText } from 'components/utils/misc'

const ClientDetailsForm = (props: FormModalProps) => {
  const [applicableFields, setApplicableFields] = useState<string[]>([])
  const {
    control,
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ClientDetailsInput>();

  const addClientDetailsFormSubmit = async (data: ClientDetailsInput) => {
    if (!applicableFields.length) {
      props?.addToast({
        variant: 'error',
        message: 'Please select at least one field'
      })
      return
    }

    // loop through CLIENT_DETAILS_APPLICABLE_FIELDS
    let fields = [];
    const section = Array?.isArray(props?.customSections) && props?.customSections?.length ? props?.customSections?.find((section) => section?.type === 'personal_info') : null;
    const sectionIndex = Array?.isArray(props?.customSections) && props?.customSections?.length ? props?.customSections?.findIndex((section) => section?.type === 'personal_info') : null;


    const registeredFields = applicableFields?.filter((field) => field !== 'other');
    if (registeredFields && registeredFields?.length > 0) {
      fields = registeredFields?.map((field) => {
        return {
          key: field,
          label: cleanUpText(field),
          description: cleanUpText(field),
          type: 'text',
          isRequired: Array?.isArray(section?.questions) && section?.questions?.length ? section?.questions?.find((qst) => qst?.key === field)?.isRequired : true
        }
      })
    }

    const payload = {
      ...data,
      applicables: fields
    }

    const formQuestion: CustomSectionsInput = {
      ...data,
      questions: fields as InputField[],
      type: 'personal_info',
      index: section ? sectionIndex : props?.customSections?.length || 0,
    }

    let updatedSections: CustomSectionsInput[] = [];
    if (section && sectionIndex !== null && sectionIndex !== undefined) {
      updatedSections = [...props.customSections];
      updatedSections[sectionIndex] = formQuestion;
    } else {
      updatedSections = Array.isArray(props?.customSections ?? []) && props?.customSections?.length
        ? [...props.customSections, formQuestion]
        : [formQuestion];
    }

    props?.setClientDetails && props.setClientDetails(payload)
    props?.setCustomSections && props?.setCustomSections(updatedSections)
    props?.closeModal()
  }

  const addOrRemoveApplicableField = (field: string) => {
    // add or remove field from applicableFields
    const newApplicableFields = [...applicableFields].includes(field)
      ? applicableFields.filter((f) => f !== field)
      : [...applicableFields, field];
    setApplicableFields(newApplicableFields);
  }

  const _closeModal = () => {
    setValue('name', '')
    setValue('description', '')
    setApplicableFields([])
    props?.closeModal()
  }

  useEffect(() => {
    if (props?.isVisible) {
      const section = Array?.isArray(props?.customSections) && props?.customSections?.length ? props?.customSections?.find((section) => section?.type === 'personal_info') : null;
      if (section) {
        setValue('name', section?.name || '')
        setValue('description', section?.description || '')
        setApplicableFields(section?.questions?.map((question) => question?.key) || [])
      }
    }
  }, [props?.isVisible, props?.customSections])

  return (
    <Modal
      variant='topCenter'
      show={props?.isVisible}
      title='Client details'
      closeModal={_closeModal}
      size='lg'
      showCloseButton={true}
    >
      <div className='w-full flx flex-col'>
        <div className='w-full'>
          <form onSubmit={handleSubmit(addClientDetailsFormSubmit)} className="w-full flex flex-col py-4 rounded-md mt-6 space-y-8" autoComplete='off'>
            <div className="w-full flex flex-col border border-grey-100/60 py-4 rounded-md space-y-6">
              <div className='w-full flex flex-col space-y-4 px-4'>
                <Input name="name" label="Section name" id="section-name" type="text" placeholder='Enter section name here' control={control} rules={{
                  required: 'Section name is required',
                }} error={errors.name} />
                <Controller
                  control={control}
                  name="description"
                  render={({
                    field: { onChange, onBlur, name, ref, value },
                    formState: { errors },
                  }) => {
                    const maxLength = MAXIMUM_NOTE_LENGTH;
                    const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                      errors?.description?.message as string
                    );
                    const helperTexts = [];

                    helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                    if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                    return (
                      <FormTextarea
                        type="text"
                        id="about"
                        label="Section description"
                        placeholder="Enter the details"
                        {...{
                          ref,
                          name,
                          value,
                          onChange,
                          onBlur,
                          maxLength,
                        }}
                        helperText={helperTexts}
                      />
                    );
                  }}
                />
              </div>
              <div className='w-full flex flex-col space-y-4 p-4 border-t border-grey-100/60'>
                <Paragraph size='b5' color={COLORS.GREY[900]} weight='normal'>
                  Section fields
                </Paragraph>

                <div className='w-full flex flex-wrap gap-4'>
                  {Array.isArray(CLIENT_DETAILS_APPLICABLE_FIELDS) && CLIENT_DETAILS_APPLICABLE_FIELDS?.length
                    ? CLIENT_DETAILS_APPLICABLE_FIELDS?.map(
                      (applicableField) => {
                        const Icon = applicableField?.icon;
                        const activeField = [...applicableFields].includes(applicableField?.value);
                        const isDisabled = false;

                        return (
                          <div
                            className="flex"
                            onClick={() => addOrRemoveApplicableField(applicableField?.value)}
                            key={applicableField?.value}
                          >
                            <div className={`flex flex-col items-center justify-center w-[150px] h-[102px] ${activeField ? 'bg-green-300/10' : 'bg-grey-50/70'} border ${activeField ? 'border-green-300' : 'border-grey-100'} rounded-md ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-b5 ${activeField ? 'text-green-300' : 'text-grey-300'} space-y-2`}>
                              <Icon height="24px" width="24px" color={activeField ? COLORS.GREEN[300] : COLORS.GREY[300]} />
                              <span className={`flex flex-col items-center justify-center ${activeField ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                {applicableField?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            </div>
            <div className='w-full flex justify-end items-center space-x-6'>
              <Button
                variant='text'
                className='text-red-500'
                size='none'
                type='button'
                fontWeight="semiBold"
                onClick={_closeModal}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                className=''
                size='md'
                rounded='lg'
              >
                Add
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default ClientDetailsForm