import axios from 'axios';
import { ERRORS } from "constants/errors";
import { useManageAuth } from "modules/auth/hooks/useManageAuth";
import jwt_decode from "jwt-decode";
import { routes } from "pages/pageRoutes";
import { routes as authRoutes } from "modules/auth/routes";
import { JsonDecodeProps } from "modules/auth";

const queryClient = axios.create({
  baseURL: process.env.REACT_APP_APOLLO_CLIENT_REST_URI,
  withCredentials: true, // Important for sending cookies cross-origin
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

queryClient.interceptors.request.use(
  config => {
    const { getAppAuthToken, getCustomerAuthToken, logout, logoutCustomer } = useManageAuth();
    const appToken = getAppAuthToken();
    const customerToken = getCustomerAuthToken();
    const token = appToken || customerToken;

    const requiresAuth = (url: string) => {
      const allRoutes = [...routes, ...authRoutes]?.filter((route) => route?.path !== '/');
      const route = allRoutes.find(route => url.includes(route.path));
      return route?.meta?.requiresAuth || false;
    };

    if (token && (requiresAuth(window.location.pathname) || window.location.pathname === "/customer-dashboard" || window.location.pathname === "/login" || window.location.pathname === "/forgot-password-otp" || window.location.pathname === '/generate-password' || window.location.pathname === '/form/:id')) {
      const tokenDecoded: JsonDecodeProps = jwt_decode(token as string);
      const expirationTime = (tokenDecoded.exp * 1000) - 60000
      if (Date.now() >= expirationTime) {
        appToken && logout();
        customerToken && logoutCustomer();
        window.location.href = '/';
      }

      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

queryClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 unauthorized (e.g., log out the user)
      // You can call a logout function from your authentication module
      // For example, if you have a logout function in useAuth() hook, you can call it like this:
      const { logout } = useManageAuth();
      logout();
    }
    return Promise.reject(error);
  }
);

export default queryClient;