import React, { useEffect, useState } from 'react'
import { Button, FormTextarea, Input, Modal, Paragraph, SvgClose } from 'ui'
import { ClientDetailsInput, CustomSectionsInput, FormModalProps } from '../types'
import { COLORS } from 'constants/colors'
import { Controller, useForm } from 'react-hook-form'
import { MAXIMUM_NOTE_LENGTH } from 'constants/form'
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form'
import { CLIENT_DETAILS_APPLICABLE_FIELDS } from '../constants'

const CustomForm = (props: FormModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ClientDetailsInput>();

  const addClientDetailsFormSubmit = async (data: ClientDetailsInput) => {
    const customSectionLength = props?.customSections?.length;
    const section = Array?.isArray(props?.customSections) && props?.customSections?.length ? props?.customSections[props?.customIndex - 1] : null
    const customSection: CustomSectionsInput = {
      name: data.name,
      description: data.description,
      index: customSectionLength,
      questions: section ? section?.questions : [],
      type: 'custom'
    }
    if (props?.setCustomSections) {
      if (!section) {
        props.setCustomSections([...props.customSections, customSection]);
      } else {
        const customSections = props?.customSections;
        customSections[props?.customIndex - 1] = customSection;
        props.setCustomSections(customSections);
      }
    }

    _closeModal()
  }

  useEffect(() => {
    if (props?.isVisible && props?.customIndex) {
      if (Array?.isArray(props?.customSections) && props?.customSections?.length) {
        const customSection = props?.customSections[props?.customIndex - 1];
        setValue('name', customSection?.name)
        setValue('description', customSection?.description)
      }
    }
  }, [props?.isVisible, props?.customIndex])

  const _closeModal = () => {
    setValue('name', '')
    setValue('description', '')
    props?.closeModal()
  }
  return (
    <Modal
      variant='topCenter'
      show={props?.isVisible}
      title='Add custom section'
      closeModal={_closeModal}
      size='lg'
      showCloseButton={true}
    >
      <div className='w-full flx flex-col'>
        <div className='w-full'>
          <form onSubmit={handleSubmit(addClientDetailsFormSubmit)} className="w-full flex flex-col py-4 rounded-md mt-6 space-y-8" autoComplete='off'>
            <div className="w-full flex flex-col border border-grey-100/60 py-4 rounded-md space-y-6">
              <div className='w-full flex flex-col space-y-4 px-4'>
                <Input name="name" label="Section name" id="section-name" type="text" placeholder='Enter section name here' control={control} rules={{
                  required: 'Section name is required',
                }} error={errors.name} />
                <Controller
                  control={control}
                  name="description"
                  render={({
                    field: { onChange, onBlur, name, ref, value },
                    formState: { errors },
                  }) => {
                    const maxLength = MAXIMUM_NOTE_LENGTH;
                    const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                      errors?.description?.message as string
                    );
                    const helperTexts = [];

                    helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                    if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                    return (
                      <FormTextarea
                        type="text"
                        id="about"
                        label="Section description"
                        placeholder="Enter the details"
                        {...{
                          ref,
                          name,
                          value,
                          onChange,
                          onBlur,
                          maxLength,
                        }}
                        helperText={helperTexts}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className='w-full flex justify-end items-center space-x-6'>
              <Button
                variant='text'
                className='text-red-500'
                size='none'
                type='button'
                fontWeight="semiBold"
                onClick={_closeModal}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                className=''
                size='md'
                rounded='lg'
              >
                {props?.customIndex ? 'Save' : 'Add'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default CustomForm