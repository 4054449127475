import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMultipleChoice = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="#000" />
    <path
      d="M10 18a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8Zm0-14.857A6.857 6.857 0 1 0 16.857 10 6.865 6.865 0 0 0 10 3.143Z"
      fill="#000"
    />
  </svg>
);
export default SvgMultipleChoice;
