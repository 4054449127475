import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFormsCompleted = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      x={0.5}
      width={28}
      height={28}
      rx={14}
      fill="#28A745"
      fillOpacity={0.08}
    />
    <path
      d="M15.322 6v1.72a6.805 6.805 0 0 1 5.924 7.593c-.391 3.099-2.826 5.559-5.924 5.925v1.702a8.492 8.492 0 0 0 7.618-9.304C22.557 9.592 19.348 6.4 15.321 6Zm-1.703.026c-1.66.161-3.243.8-4.537 1.872l1.217 1.26a6.807 6.807 0 0 1 3.32-1.43V6.026ZM7.88 9.099A8.428 8.428 0 0 0 6 13.636h1.703a6.824 6.824 0 0 1 1.396-3.32L7.88 9.099Zm9.569 2.409-4.155 4.154-1.804-1.805-.903.903 2.707 2.707 5.057-5.057-.902-.902Zm-11.441 3.83a8.546 8.546 0 0 0 1.88 4.538l1.21-1.218a6.81 6.81 0 0 1-1.388-3.32H6.01Zm4.29 4.572-1.217 1.166A8.513 8.513 0 0 0 13.619 23v-1.703a6.81 6.81 0 0 1-3.32-1.387Z"
      fill="#28A745"
    />
  </svg>
);
export default SvgFormsCompleted;
