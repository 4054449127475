import { useMutation, useQuery, gql } from "@apollo/client";
import { Appointment, ClosedPeriod, SalonStaff, SalonStaffDetails, StaffBlockedTime, StaffTimeOff } from "core/generated";

const CLOSED_PERIODS = gql`
  query ClosedPeriods($startDate: String, $endDate: String, $salonId: ID!) {
    closedPeriods(startDate: $startDate, endDate: $endDate, salonId: $salonId) {
      businessId
      createdAt
      deletedAt
      endAt
      id
      startAt
      title
      updatedAt
    }
  }
`;

export const useClosedPeriods = ({
  startDate, endDate, salonId,
}: {
  startDate?: string; endDate?: string; salonId: string;
  }) => {
  return useQuery<{
    closedPeriods: ClosedPeriod[];
  }>(CLOSED_PERIODS, {
    variables: {
      startDate,
      endDate,
      salonId,
    },
    skip: !salonId,
  });
}

const DELETE_CLOSED_PERIOD = gql`
  mutation DeleteClosedPeriod($input: DeleteClosedPeriodInput!) {
    deleteClosedPeriod(input: $input) {
      status
      errors {
        message
        property
      }
      closedPeriod {
        id
        title
      }
    }
  }
`;

export const useDeleteClosedPeriod = () => {
  const [deleteClosedPeriod, options] = useMutation<{
    deleteClosedPeriod: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      closedPeriod: {
        id: string;
        title: string;
      };
    };
  }>(DELETE_CLOSED_PERIOD)

  return {
    deleteClosedPeriod,
    ...options,
  }
}

const CREATE_CLOSED_PERIOD = gql`
  mutation CreateClosedPeriod($input: CreateClosedPeriodInput!) {
    createClosedPeriod(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`

export const useCreateClosedPeriod = () => {
  const [createClosedPeriod, options] = useMutation<{
    createClosedPeriod: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_CLOSED_PERIOD)

  return {
    createClosedPeriod,
    ...options,
  }
}

const STAFF_LIST = gql`
  query StaffManagement($salonId: ID!, $q: String, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $active: Boolean) {
    staffManagement(salonId: $salonId, q: $q, active: $active) {
      staffs {
        addedById
        businessId
        active
        canLogin
        canServeCustomers
        createdAt
        deletedAt
        id
        level
        salonId
        specialty
        updatedAt
        role
        staffHours {
          id
          salonStaffId
          day
          openTime
          closeTime
          status
          salonId
        }
        staffTimeOffs(startDate: $startDate, endDate: $endDate) {
          id
          title
          startAt
          endAt
        }
        user {
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          profileUrl
          firstName
          fullName
          id
          lastLoggedInAt
          lastName
          note
          phone
        }
      }
      totalStaffs
      activeStaffs
      totalSales
      averageRating
    }
  }
`;

export const useGetStaffList = ({
  salonId, startDate, endDate, active, q
}: {
  salonId: string;
  startDate?: string;
  endDate?: string;
  active?: boolean;
  q?: string;
}) => {
  return useQuery<{
    staffManagement: {
      staffs: SalonStaff[];
      totalStaffs: number;
      activeStaffs: number;
      totalSales: number;
      averageRating: number;
    };
  }>(STAFF_LIST, {
    variables: {
      salonId,
      q,
      startDate,
      endDate,
      active,
    },
    skip: !salonId,
  });
}

const GET_STAFF_TIME_OFF = gql`
  query StaffTimeOffs($salonId: ID!, $startDate: String, $endDate: String) {
	staffTimeOffs(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
		createdAt
		endAt
		id
		salonId
		salonStaff {
			addedById
			businessId
			canLogin
			canServeCustomers
			createdAt
			deletedAt
			id
			level
			role
			salonId
			specialty
			updatedAt
			user {
				altCallingCode
				altCountryCode
				altPhone
				callingCode
				countryCode
				email
				firstName
				fullName
				lastName
				note
				phone
			}
		}
		startAt
		title
		updatedAt
	}
}
`;

export const useGetStaffTimeOff = ({
  salonId, startDate, endDate
}: {
  salonId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery<{
    staffTimeOffs: StaffTimeOff[];
  }>(GET_STAFF_TIME_OFF, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STAFF_BLOCK_TIME = gql`
  query StaffBlockedTimes($salonId: ID!, $startDate: String!, $endDate: String!) {
    staffBlockedTimes(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      createdAt
      endAt
      id
      salonId
      salonStaff {
        addedById
        businessId
        canLogin
        canServeCustomers
        createdAt
        deletedAt
        id
        level
        role
        salonId
        specialty
        updatedAt
        user {
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          fullName
          id
          lastName
          phone
        }
      }
      startAt
      title
      updatedAt
      timezone
    }
  }
`;

export const useGetStaffBlockedTimes = ({
  salonId, startDate, endDate
}: {
  salonId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery<{
    staffBlockedTimes: StaffBlockedTime[];
  }>(STAFF_BLOCK_TIME, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STAFF = gql`
  query SalonStaff($salonStaffId: ID!) {
    salonStaff(salonStaffId: $salonStaffId) {
      id
      addedById
      businessId
      canLogin
      canServeCustomers
      createdAt
      deletedAt
      id
      level
      role
      receiveBusinessReports
      receivePushNotifications
      salonId
      salonIds
      salons {
        id
        branchName
      }
      specialty
      rating
      totalSalesValue
      totalLeaveDays
      daysLeft
      appointmentsCompleted
      employmentDetails {
        startDate
        endDate
        canLogin
        specialty
        canServeCustomers
      }
      bonus
      taxDeduction
      assignedServices
      formattedWorkingHours
      updatedAt
      staffHours {
        id
        salonStaffId
        day
        openTime
        closeTime
        status
      }
      staffTimeOffs {
        id
        title
        startAt
        endAt
      }
      user {
        altCallingCode
        altCountryCode
        altPhone
        callingCode
        countryCode
        createdAt
        deletedAt
        email
        emailVerified
        firstName
        fullName
        profileUrl
        id
        lastLoggedInAt
        lastName
        note
        phone
        phoneVerified
        updatedAt
        userType
        dateOfBirth
      }
      employmentDetails {
        startDate
        endDate
        canLogin
        present
        salonId
        canServeCustomers
        services {
          label
          value
        }
        level
      }
      leave {
        daysEntitled
        leaveType
        daysLeft
      }
      salaryInformation {
        period
        baseSalary
        bonus
        bonusType
        deduction
        deductionType
      }
      reminder {
        birthday
        workAnniversary
      }
      workingSchedule {
        workSchedule {
          openTime
          closeTime
          day
          salonId
        }
        workScheduleType
      }
      leaveHistory {
        leavePeriod
        reason
        daysTaken
        totalLeaveDays
        daysLeft
      }
      workHistory {
        transactionDate
        paymentMethod
        depositPaymentMethod
        services
        client
        payment
        rating
        staff
        note
      }
    }
  }
`;

export const useGetStaff = ({
  salonStaffId
}: {
  salonStaffId: string;
}) => {
  return useQuery<{
    salonStaff: SalonStaffDetails;
  }>(STAFF, {
    variables: {
      salonStaffId,
    },
    skip: !salonStaffId,
  });
}

const UPDATE_STAFF = gql`
  mutation updateSalonStaffDetails($input: UpdateSalonStaffDetailsInput!) {
    updateSalonStaffDetails(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateStaff = () => {
  const [updateSalonStaff, options] = useMutation<{
    updateSalonStaffDetails: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPDATE_STAFF);

  return {
    updateSalonStaff,
    ...options,
  };
};

const DELETE_STAFF = gql`
  mutation DeleteSalonStaff($input: DeleteSalonStaffInput!) {
    deleteSalonStaff(input: $input) {
      clientMutationId
      status
      salonStaff {
        id
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteStaff = () => {
  const [deleteStaff, options] = useMutation<{
    deleteSalonStaff: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      salonStaff: {
        id: string;
      };
    };
  }>(DELETE_STAFF);

  return {
    deleteStaff,
    ...options,
  };
};

const CREATE_SALON_STAFF = gql`
  mutation CreateSalonStaff($input: CreateSalonStaffInput!){
    createSalonStaff(input: $input){
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateSalonStaff = () => {
  const [createSalonStaff, options] = useMutation<{
    createSalonStaff: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_SALON_STAFF);

  return {
    createSalonStaff,
    ...options,
  };
};

const UPLOAD_STAFF = gql`
  mutation UploadStaff($input: UploadStaffInput!) {
    uploadStaff(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useUploadStaff = () => {
  const [uploadStaff, options] = useMutation<{
    uploadStaff: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPLOAD_STAFF);

  return {
    uploadStaff,
    ...options,
  };
};

const CREATE_STAFF_TIME_OFF = gql`
  mutation CreateStaffTimeOff($input: CreateStaffTimeOffInput!) {
    createStaffTimeOff(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateStaffTimeOff = () => {
  const [createStaffTimeOff, options] = useMutation<{
    createStaffTimeOff: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_STAFF_TIME_OFF);

  return {
    createStaffTimeOff,
    ...options,
  };
}

const DELETE_STAFF_TIME_OFF = gql`
  mutation DeleteStaffTimeOff($input: DeleteStaffTimeOffInput!) {
    deleteStaffTimeOff(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteStaffTimeOff = () => {
  const [deleteStaffTimeOff, options] = useMutation<{
    deleteStaffTimeOff: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_STAFF_TIME_OFF);

  return {
    deleteStaffTimeOff,
    ...options,
  };
}

const CREATE_STAFF_HOUR = gql`
  mutation CreateStaffHour($input: CreateStaffHourInput!) {
    createStaffHour(input: $input) {
      status
      errors {
        message
        property
      }
      staffHours {
        id
        day
        openTime
        closeTime
      }
    }
  }
`;

export const useCreateStaffHour = () => {
  const [createStaffHour, options] = useMutation<{
    createStaffHour: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      staffHours: {
        id: string;
        day: string;
        openTime: string;
        closeTime: string;
      }[];
    };
  }>(CREATE_STAFF_HOUR);

  return {
    createStaffHour,
    ...options,
  };
}

const CREATE_STAFF_BLOCK_TIME = gql`
  mutation CreateStaffBlockedTime($input: CreateStaffBlockedTimeInput!) {
    createStaffBlockedTime(input: $input) {
      clientMutationId
      status
      staffBlockedTime {
        createdAt
        endAt
        id
        salonId
        startAt
        title
        updatedAt
      }
      errors {
        message
        property
      }
    }
  }
`

export const useCreateBlockTime = () => {
  const [createStaffBlockedTime, options] = useMutation<{
    createStaffBlockedTime: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_STAFF_BLOCK_TIME);

  return {
    createStaffBlockedTime,
    ...options,
  };
}

const DELETE_STAFF_BLOCK_TIME = gql`
  mutation DeleteStaffBlockedTime($input: DeleteStaffBlockedTimeInput!) {
    deleteStaffBlockedTime(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteBlockTime = () => {
  const [deleteStaffBlockedTime, options] = useMutation<{
    deleteStaffBlockedTime: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_STAFF_BLOCK_TIME);

  return {
    deleteStaffBlockedTime,
    ...options,
  };
}

const GET_STAFF_APPOINTMENTS = gql`
  query AppointmentsByStaffReport($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $salonStaffId: ID!) {
    appointmentsByStaffReport(startDate: $startDate, endDate: $endDate, salonStaffId: $salonStaffId) {
        client
        createdAt
        duration
        paid
        price
        salon
        scheduledDate
        service
        status
        reviews
    }
  }
`;

export const useGetStaffAppointments = ({
  salonStaffId, startDate, endDate
}: {
  salonStaffId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery<{
    appointmentsByStaffReport: {
      client: string;
      createdAt: string;
      duration: number;
      paid: boolean;
      price: number;
      salon: string;
      scheduledDate: string;
      service: string;
      status: string;
      reviews: number
    }[];
  }>(GET_STAFF_APPOINTMENTS, {
    variables: {
      salonStaffId,
      startDate,
      endDate,
    },
    skip: !salonStaffId,
  });
}

const STAFF_PERFORMANCE_SUMMARY = gql`
  query StaffPerformanceSummaryReport($salonId: ID!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
    staffPerformanceSummaryReport(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
      averageAppointmentSales
      newClients
      refundsIssued
      reviews
      staff
      totalAppointmentSales
      totalAppointments
      totalClients
      totalDiscounts
      totalInvoicedProducts
      totalOnlineAppointments
      totalSales
      totalServicesBooked
    }
  }
`

export const useStaffPerformanceSummary = ({
  salonId, startDate, endDate
}: {
  salonId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery<{
    staffPerformanceSummaryReport: {
      averageAppointmentSales: number;
      newClients: number;
      refundsIssued: number;
      reviews: number;
      staff: number;
      totalAppointmentSales: number;
      totalAppointments: number;
      totalClients: number;
      totalDiscounts: number;
      totalInvoicedProducts: number;
      totalOnlineAppointments: number;
      totalSales: number;
      totalServicesBooked: number;
    };
  }>(STAFF_PERFORMANCE_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const GET_SPECIALTIES = gql`
  query Specialties($specialtyType: String!) {
    specialties(specialtyType: $specialtyType) {
        label
        value
    }
  }
`;

export const useGetSpecialties = ({
  specialtyType
}: {
  specialtyType: string;
}) => {
  return useQuery<{
    specialties: {
      label: string;
      value: string;
    }[];
  }>(GET_SPECIALTIES, {
    variables: {
      specialtyType,
    },
  });
}