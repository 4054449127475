export const PAGE_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  OTP: '/otp',
  AUTH_INFO: '/auth/info',
  APP_LOADER: '/',
  FORGOT_PASSWORD: '/forgot-password',
  GENERATE_PASSWORD: '/generate-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  APPLY: 'get-started',
  HOME: '/home',
  ACCOUNT_SETUP: '/account-setup',
  BOOKING: '/s/:id',
  CLIENTS: '/clients',
  INVENTORY: '/catalogue',
  CALENDAR: '/calendar',
  NOTIFICATIONS: '/notifications',
  STAFF: '/staff',
  SALES: '/sales',
  LOYALTY_AND_PROMOS: '/rewards',
  MONEY: '/money',
  MESSAGE: '/messaging',
  WALLET: '/wallet',
  SETTINGS: '/settings',
  COMPLETE: '/complete-registration',
  NOT_FOUND: '*',
  BUSINESS_PERFORMANCE: '/reports',
  CLIENTS_REPORTS: '/reports/clients',
  LOYALTY_REPORTS: '/reports/loyalty',
  SALES_REPORTS: '/reports/sales',
  TIPS_REPORTS: '/reports/tips',
  MEMBERSHIPS_REPORTS: '/reports/memberships',
  STAFF_REPORTS: '/reports/staff',
  INVENTORY_REPORTS: '/reports/inventory',
  APPOINTMENT_REPORTS: '/reports/appointment',
  REVIEW: '/review/:bookingId',
  RECEIPT: '/receipt/:bookingId',
  SALE_RECEIPT: '/sale/:saleId',
  COMPLETE_PAYMENT: '/pay/:bookingId',
  PAY_OUTSTANDING: '/pay-cancellation-fee/:id',
  APPOINTMENT: '/calendar/:appointmentId',
  APPOINTMENT_SUMMARY: '/b/:bookingId',
  FILL_FORMS: '/form/:id',
  REDEEM_VOUCHER: '/customer-dashboard',
  PACKAGES_REPORTS: '/reports/vouchers',
  SUPPORT: '/support',
  CLOSED_PERIOD: '/staff/closed-period',
}
