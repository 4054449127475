import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgForms = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 208 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M30.81 149.163c40.42 21.116 105.959 21.116 146.379 0 40.421-21.116 40.421-55.352 0-76.473-40.42-21.12-105.94-21.116-146.379 0-40.438 21.116-40.401 55.357 0 76.473Z"
      fill="#F5F5F5"
    />
    <path
      d="m19.543 114.532-13.14-7.584V78.766l13.14 7.588v28.178Z"
      fill="#E0E0E0"
    />
    <path
      d="M19.543 86.357 38.316 75.52v28.178l-18.773 10.838V86.357Z"
      fill="#EBEBEB"
    />
    <path
      d="M38.316 75.518 19.543 86.356l-13.14-7.589L25.178 67.93l13.139 7.588Z"
      fill="#F5F5F5"
    />
    <path
      d="m21.424 87.436 15.016-8.67.346.198v10.44l-15.02 8.67-.347-.198.005-10.44Z"
      fill="#E0E0E0"
    />
    <path
      d="m21.766 87.64 15.02-8.675v10.44l-15.02 8.67V87.639Z"
      fill="#F5F5F5"
    />
    <path
      d="m27.654 86.007 2.556-1.474c.328-.194.596-.106.596.194a1.197 1.197 0 0 1-.596.887l-2.556 1.475c-.333.194-.6.106-.6-.194a1.193 1.193 0 0 1 .6-.888ZM21.077 100.647l15.016-8.67v10.44l.346.198-15.02 8.67-.342-.198v-10.44Z"
      fill="#E0E0E0"
    />
    <path
      d="m21.42 100.846 15.02-8.67v10.44l-15.02 8.67v-10.44Z"
      fill="#F5F5F5"
    />
    <path
      d="m27.307 99.217 2.58-1.47c.332-.193.6-.106.6.195a1.194 1.194 0 0 1-.6.887l-2.557 1.475c-.332.194-.6.106-.6-.195a1.194 1.194 0 0 1 .577-.892ZM19.543 84.727 6.403 77.14V48.96l13.14 7.584v28.182Z"
      fill="#E0E0E0"
    />
    <path
      d="m19.543 56.548 18.773-10.837v28.182L19.543 84.731V56.549Z"
      fill="#EBEBEB"
    />
    <path
      d="M38.316 45.709 19.543 56.547l-13.14-7.584 18.774-10.838 13.139 7.584Z"
      fill="#F5F5F5"
    />
    <path
      d="m21.42 57.631 15.02-8.67.346.199V59.6l-15.02 8.67-.347-.203V57.63Z"
      fill="#E0E0E0"
    />
    <path
      d="m27.654 56.2 2.551-1.475c.333-.19.6-.102.6.199a1.2 1.2 0 0 1-.6.887l-2.551 1.474c-.333.19-.6.107-.6-.194a1.205 1.205 0 0 1 .6-.892ZM21.077 70.842l15.016-8.67v10.435l.346.2-15.02 8.67-.342-.2V70.842Z"
      fill="#E0E0E0"
    />
    <path
      d="m21.42 71.041 15.02-8.67v10.436l-15.02 8.67V71.04Z"
      fill="#F5F5F5"
    />
    <path
      d="m27.307 69.41 2.58-1.474c.327-.19.6-.102.6.198a1.22 1.22 0 0 1-.6.888l-2.58 1.46c-.332.19-.6.102-.6-.194a1.206 1.206 0 0 1 .6-.878ZM21.42 57.631l15.02-8.67v10.435l-15.02 8.67V57.632Z"
      fill="#E0E0E0"
    />
    <path
      d="m43.95 54.121-8.222-4.746v9.197l8.222 4.742V54.12Z"
      fill="#E0E0E0"
    />
    <path
      d="M27.053 69.555v-13.01l3.221-1.867.532.786 9.386-5.421v11.924l-13.139 7.588Z"
      fill="#EBEBEB"
    />
    <path
      d="m27.053 69.557.938-11.383 13.14-7.584-.939 11.378-13.139 7.589Z"
      fill="#F5F5F5"
    />
    <path
      d="m28.93 62.789-7.164-4.137v9.197l7.163 4.132v-9.192Z"
      fill="#EBEBEB"
    />
    <path
      d="m28.93 61.967 15.02-8.67v10.435l-15.02 8.67V61.967Z"
      fill="#F5F5F5"
    />
    <path
      d="m34.817 60.336 2.551-1.474c.333-.19.601-.102.601.198a1.207 1.207 0 0 1-.6.888l-2.552 1.474c-.332.19-.6.102-.6-.194a1.206 1.206 0 0 1 .6-.892ZM28.93 61.966l-.5-.286v10.435l.5.287V61.966Z"
      fill="#E0E0E0"
    />
    <path
      d="m28.43 61.682 15.016-8.674.504.291-15.02 8.67-.5-.287Z"
      fill="#EBEBEB"
    />
    <path
      d="m174.772 97.462-13.143-7.589V61.695l13.143 7.584v28.183Z"
      fill="#E0E0E0"
    />
    <path
      d="m174.772 69.279 18.773-10.838v28.183L174.772 97.46V69.28Z"
      fill="#EBEBEB"
    />
    <path
      d="m193.545 58.44-18.773 10.838-13.143-7.584 18.777-10.842 13.139 7.588Z"
      fill="#F5F5F5"
    />
    <path
      d="m176.649 70.365 15.02-8.67.347.199-.005 10.435L176.995 81l-.346-.198V70.365Z"
      fill="#E0E0E0"
    />
    <path
      d="m176.995 70.565 15.02-8.67-.004 10.435L176.995 81V70.565Z"
      fill="#F5F5F5"
    />
    <path
      d="m182.883 68.934 2.551-1.475c.333-.189.601-.101.601.195a1.205 1.205 0 0 1-.601.892l-2.551 1.474c-.332.19-.601.102-.601-.194a1.22 1.22 0 0 1 .601-.892ZM176.302 83.573l15.02-8.675v10.44l.347.2-15.02 8.67-.347-.2V83.574Z"
      fill="#E0E0E0"
    />
    <path
      d="m176.649 83.772 15.02-8.67v10.435l-15.02 8.67V83.772Z"
      fill="#F5F5F5"
    />
    <path
      d="m182.537 82.14 2.555-1.474c.328-.189.597-.101.597.195a1.214 1.214 0 0 1-.597.891l-2.555 1.475c-.333.19-.601.102-.601-.199a1.209 1.209 0 0 1 .601-.887Z"
      fill="#E0E0E0"
    />
    <path
      d="M49.759 126.914s.05-7.33-4.57-12.825c-4.09-4.867-6.268-7.954-4.978-9.377 1.29-1.424 4.062-1.766 7.801 2.172 3.739 3.937 4.03 14.844 3.512 17.312l-1.765 2.718Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M49.759 126.914s.05-7.33-4.57-12.825c-4.09-4.867-6.268-7.954-4.978-9.377 1.29-1.424 4.062-1.766 7.801 2.172 3.739 3.937 4.03 14.844 3.512 17.312l-1.765 2.718Z"
      fill="#000"
    />
    <path
      d="M50.535 119.038a.232.232 0 0 1-.226-.181c-1.618-7.565-5.768-11.748-8.102-13.569a.229.229 0 0 1-.041-.323.235.235 0 0 1 .328-.042c2.38 1.849 6.618 6.128 8.268 13.837a.227.227 0 0 1-.176.273l-.05.005Z"
      fill="#fff"
    />
    <path
      d="m49.583 129.638-1.88 1.087a3.402 3.402 0 0 0-2.533-2.168c-2.057-.462-4.192-.384-5.634-2.167-1.442-1.784.314-3.725 0-5.038-.314-1.313-.518-1.691-3.096-3.004-2.58-1.313-3.596-3.489-1.655-4.964 1.941-1.474 5.084-.392 6.988.925 1.691 1.155 3.3 1.46 5.19 1.696 1.89.236 4.968 1.285 4.497 5.675a34.589 34.589 0 0 1-1.877 7.958Z"
      fill="#16796F"
    />
    <path
      d="M42.364 123.87a.233.233 0 0 1-.221-.162.234.234 0 0 1 .017-.176.234.234 0 0 1 .135-.115c.166-.051 4.16-1.317 5.856.042a.23.23 0 0 1 .022.312.232.232 0 0 1-.309.048c-1.497-1.201-5.389.028-5.43.042l-.07.009Z"
      fill="#fff"
    />
    <path
      d="M49.791 129.285a.221.221 0 0 1-.152-.058.224.224 0 0 1-.074-.145c-1.03-9.733-12.756-14.35-12.871-14.396a.235.235 0 0 1-.13-.122.234.234 0 0 1-.004-.179.236.236 0 0 1 .296-.134c.124.051 12.108 4.765 13.166 14.789a.23.23 0 0 1-.203.255l-.028-.01Z"
      fill="#fff"
    />
    <path
      d="M164.744 123.246c.05-.162 2.033-10.366 0-17.604-1.849-6.636-4.262-9.086-7.7-9.626-3.438-.541-4.931 1.936-3.106 5.291 1.826 3.356 6.221 7.806 7.298 15.302.462 3.088.813 5.768.813 5.768l2.695.869Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M164.744 123.246c.05-.162 2.033-10.366 0-17.604-1.849-6.636-4.262-9.086-7.7-9.626-3.438-.541-4.931 1.936-3.106 5.291 1.826 3.356 6.221 7.806 7.298 15.302.462 3.088.813 5.768.813 5.768l2.695.869Z"
      fill="#000"
    />
    <path
      d="M163.584 117.386a.239.239 0 0 1-.16-.077.244.244 0 0 1-.058-.168c.463-8.263-2.236-15.639-7.075-19.258a.229.229 0 0 1-.09-.152.229.229 0 0 1 .196-.26.236.236 0 0 1 .171.042c4.959 3.697 7.741 11.24 7.256 19.656a.23.23 0 0 1-.24.217Z"
      fill="#fff"
    />
    <path
      d="M170.063 128.561c-.263-1.9.827-4.746 3.42-5.421 2.593-.675 3.748-2.163 2.815-3.79-.934-1.627-.717-3.119 1.636-3.794 2.352-.675 4.413-1.849 3.059-4.335-.984-1.798-1.201-2.963 1.211-6.503 2.001-2.939.365-4.663-2.14-4.339-2.505.323-4.053 3.309-5.62 3.808-1.566.499-1.927-2.163-3.993-.971-2.066 1.193-1.941 4.247-2.005 6.378-.065 2.131-.204 2.611-2.071 2.352-1.867-.258-3.697.047-3.656 4.562.033 3.873 2.099 8.67 2.099 8.67l5.245 3.383Z"
      fill="#16796F"
    />
    <path
      d="M166.786 125.671h-.023a.236.236 0 0 1-.208-.259c1.304-11.641 11.295-21.462 13.902-23.454a.231.231 0 0 1 .371.216.233.233 0 0 1-.089.154c-2.574 1.964-12.437 11.655-13.722 23.135a.232.232 0 0 1-.231.208Z"
      fill="#fff"
    />
    <path
      d="M171.343 113.225a.236.236 0 0 1-.217-.148.24.24 0 0 1 .139-.301c3.725-1.386 7.949-.73 8.129-.702a.23.23 0 0 1 .096.412.24.24 0 0 1-.17.045c-.042 0-4.284-.665-7.894.68a.283.283 0 0 1-.083.014ZM168.025 120.225a.23.23 0 0 1-.171-.079 13.631 13.631 0 0 1-3.046-6.317.254.254 0 0 1 .004-.091.243.243 0 0 1 .105-.142.235.235 0 0 1 .176-.027.225.225 0 0 1 .081.039.235.235 0 0 1 .092.151 13.356 13.356 0 0 0 2.935 6.078.239.239 0 0 1 .035.249.232.232 0 0 1-.086.1.23.23 0 0 1-.125.039Z"
      fill="#fff"
    />
    <path
      d="M49.583 58.106v71.533l73.215-42.269-.004-65.03L68.356 53.77l-1.88-5.421-16.893 9.756Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="M49.583 58.106v71.533l73.215-42.269-.004-65.03L68.356 53.77l-1.88-5.421-16.893 9.756Z"
      fill="#000"
    />
    <path
      d="m49.583 129.639 3.753-65.03 73.215-42.27-3.753 65.031-73.215 42.269Z"
      fill="#E0E0E0"
    />
    <path
      d="m49.583 129.639 5.63-63.944 73.214-42.273-5.629 63.949-73.215 42.268Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m49.583 129.639 5.63-63.944 73.214-42.273-5.629 63.949-73.215 42.268Z"
      fill="#000"
    />
    <path
      d="M60.846 64.61v71.537l73.215-42.273v-65.03L79.619 60.275l-1.876-5.421-16.897 9.756Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M60.846 64.61v71.537l73.215-42.273v-65.03L79.619 60.275l-1.876-5.421-16.897 9.756Z"
      fill="#000"
    />
    <path
      d="M60.846 136.147 64.6 71.113l73.215-42.27-3.753 65.03-73.215 42.274Z"
      fill="#E0E0E0"
    />
    <path
      d="m60.846 136.148 5.63-63.949 73.214-42.273-5.629 63.948-73.215 42.274Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m60.846 136.148 5.63-63.949 73.214-42.273-5.629 63.948-73.215 42.274Z"
      fill="#000"
    />
    <path
      d="M122.78 158.91c-13.144-7.589-25.336-15.838-30.9-25.516l16.915-9.765 13.985 35.281Z"
      fill="#E0E0E0"
    />
    <path
      d="M73.985 72.2v71.533l73.215-42.269v-65.03l-54.442 31.43-1.876-5.42L73.985 72.2Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M73.985 72.2v71.533l73.215-42.269v-65.03l-54.442 31.43-1.876-5.42L73.985 72.2Z"
      fill="#000"
    />
    <path
      d="M95.078 93.348c-.185 0-.213-.213-.213-.338V32.005a1.534 1.534 0 0 1 .684-1.183L148.392.32a.495.495 0 0 1 .241-.074c.185 0 .212.213.212.337v61.005a1.527 1.527 0 0 1-.684 1.188L95.318 93.278a.504.504 0 0 1-.24.07Z"
      fill="#fff"
    />
    <path
      d="M148.605.458a.432.432 0 0 1 0 .101v61.005a1.328 1.328 0 0 1-.568.989L95.193 93.055l-.092.042a.28.28 0 0 1 0-.102V31.991a1.326 1.326 0 0 1 .569-.985L148.512.504a.257.257 0 0 1 .097-.042m.027-.462a.723.723 0 0 0-.356.106L95.438 30.61a1.78 1.78 0 0 0-.8 1.386V93c0 .36.181.568.463.568a.711.711 0 0 0 .356-.106L148.3 62.96a1.763 1.763 0 0 0 .8-1.387V.57c0-.361-.181-.574-.463-.569Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="M148.605.458a.432.432 0 0 1 0 .101v61.005a1.328 1.328 0 0 1-.568.989L95.193 93.055l-.092.042a.28.28 0 0 1 0-.102V31.991a1.326 1.326 0 0 1 .569-.985L148.512.504a.257.257 0 0 1 .097-.042m.027-.462a.723.723 0 0 0-.356.106L95.438 30.61a1.78 1.78 0 0 0-.8 1.386V93c0 .36.181.568.463.568a.711.711 0 0 0 .356-.106L148.3 62.96a1.763 1.763 0 0 0 .8-1.387V.57c0-.361-.181-.574-.463-.569Z"
      fill="#fff"
    />
    <path
      d="m100.268 34.318 18.773-10.837v21.675l-18.773 10.837V34.318Z"
      fill="#16796F"
    />
    <path
      opacity={0.8}
      d="m100.268 34.318 18.773-10.837v21.675l-18.773 10.837V34.318Z"
      fill="#fff"
    />
    <path
      d="M116.226 28.887v6.516l-.591.328V33.74c0-1.1-.892-1.474-2.001-.836L105.68 37.5c-1.105.638-2.001 2.048-2.001 3.148v1.992l-.587.35V36.49l13.134-7.602Z"
      fill="#fff"
    />
    <path
      d="M112.612 45.477c-.961.555-1.742-.028-1.742-1.308V33.74l-2.417 1.312v10.514c0 1.275-.781 2.773-1.743 3.314l-1.746 1.007v.693l9.386-5.42v-.69l-1.738 1.008Z"
      fill="#fff"
    />
    <path
      d="M122.78 22.413c0 .601.54.772 1.201.393l18.265-10.56a2.406 2.406 0 0 0 1.201-1.78c0-.596-.54-.771-1.201-.388l-18.251 10.538a2.405 2.405 0 0 0-1.215 1.797ZM122.78 28.886c0 .601.54.777 1.201.393l18.251-10.537a2.418 2.418 0 0 0 1.201-1.78c0-.596-.54-.771-1.201-.388l-18.251 10.538a2.41 2.41 0 0 0-1.201 1.774ZM122.78 35.402c0 .6.54.776 1.201.392l18.251-10.537a2.417 2.417 0 0 0 1.201-1.775c0-.6-.54-.776-1.201-.392l-18.251 10.537a2.41 2.41 0 0 0-1.201 1.775ZM122.78 41.906c0 .6.54.776 1.201.392l18.251-10.537a2.417 2.417 0 0 0 1.201-1.775c0-.6-.54-.776-1.201-.392L123.981 40.13a2.411 2.411 0 0 0-1.201 1.775ZM100.268 61.417c0 .6.536.776 1.202.393l40.776-23.57a2.396 2.396 0 0 0 1.201-1.775c0-.6-.54-.772-1.201-.393l-40.776 23.57a2.394 2.394 0 0 0-1.202 1.775ZM100.268 67.919c0 .6.536.776 1.202.393l40.776-23.543a2.407 2.407 0 0 0 1.201-1.779c0-.596-.54-.772-1.201-.388L101.47 66.144a2.394 2.394 0 0 0-1.202 1.775ZM100.268 74.427c0 .596.536.772 1.202.388l40.776-23.542a2.407 2.407 0 0 0 1.201-1.78c0-.595-.54-.77-1.201-.387L101.47 72.648a2.39 2.39 0 0 0-1.202 1.78Z"
      fill="#455A64"
    />
    <path
      d="m73.985 143.731 13.14-57.442 73.214-42.273-13.139 57.446-73.215 42.269Z"
      fill="#16796F"
    />
    <path
      d="M91.898 90.787v42.606l16.897-9.756V81.035l-16.897 9.752Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M91.898 90.787v42.606l16.897-9.756V81.035l-16.897 9.752Z"
      fill="#000"
    />
    <path
      d="M122.812 158.629c-1.437-1.072-15.293-11.66-22.336-26.805-7.496-16.102-7.487-34.884-7.487-41.95v-.222l50.227-28.996c0 7.131 0 25.941 7.529 42.098 6.881 14.789 20.205 25.243 22.317 26.842l-50.25 29.033Z"
      fill="#fff"
    />
    <path
      d="M142.985 61.074c0 7.432.143 25.881 7.552 41.798 6.65 14.281 19.295 24.55 22.109 26.717l-49.82 28.765c-1.946-1.465-15.279-11.878-22.137-26.607-7.487-16.055-7.487-34.8-7.487-41.857v-.088l49.765-28.732m.462-.8L92.739 89.54c0 6.65-.138 25.973 7.51 42.403 7.529 16.124 22.531 26.966 22.531 26.966l50.703-29.273s-15.02-10.842-22.526-26.966c-7.653-16.43-7.51-35.753-7.51-42.403l-.018.004Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="M142.985 61.074c0 7.432.143 25.881 7.552 41.798 6.65 14.281 19.295 24.55 22.109 26.717l-49.82 28.765c-1.946-1.465-15.279-11.878-22.137-26.607-7.487-16.055-7.487-34.8-7.487-41.857v-.088l49.765-28.732m.462-.8L92.739 89.54c0 6.65-.138 25.973 7.51 42.403 7.529 16.124 22.531 26.966 22.531 26.966l50.703-29.273s-15.02-10.842-22.526-26.966c-7.653-16.43-7.51-35.753-7.51-42.403l-.018.004Z"
      fill="#fff"
    />
    <path
      d="m137.712 70.084-16.028 9.243a2.274 2.274 0 0 0-1.164 1.752c.023.587.582.753 1.243.37l16.032-9.243a2.277 2.277 0 0 0 1.16-1.729c-.028-.61-.578-.776-1.243-.393ZM138.054 76.393l-16.028 9.243a2.138 2.138 0 0 0-1.118 1.729c.051.568.633.72 1.294.337l16.032-9.243a2.102 2.102 0 0 0 1.105-1.724c-.046-.573-.62-.712-1.285-.342ZM138.696 82.527l-16.032 9.243c-.661.383-1.141 1.146-1.063 1.696.079.55.684.693 1.35.31l16.032-9.244c.661-.383 1.132-1.141 1.049-1.691-.083-.55-.675-.698-1.336-.314ZM139.658 88.477l-16.033 9.243c-.66.383-1.118 1.132-1.007 1.668.111.536.739.656 1.386.273l16.028-9.243c.665-.384 1.109-1.128.998-1.664-.111-.536-.688-.661-1.372-.278ZM140.961 94.225l-16.032 9.243c-.661.383-1.091 1.114-.925 1.627.167.513.8.628 1.461.245l16.032-9.244c.666-.383 1.081-1.109.924-1.626-.157-.518-.799-.629-1.46-.245ZM142.652 99.766l-39.07 22.558c-.661.383-1.063 1.095-.883 1.589.18.495.869.587 1.53.204l39.07-22.558c.666-.384 1.049-1.091.869-1.59-.18-.5-.878-.587-1.516-.203ZM144.709 105.056l-39.066 22.558c-.666.384-1.031 1.077-.809 1.549.222.471.924.545 1.599.161l39.071-22.558c.665-.383 1.021-1.076.799-1.548-.221-.471-.928-.541-1.594-.162ZM147.274 110.099l-39.071 22.558c-.665.384-.993 1.058-.73 1.507.263.448 1.017.494 1.682.111l39.043-22.558c.661-.379.98-1.049.717-1.498-.264-.448-.994-.503-1.641-.12ZM150.264 114.869l-39.066 22.553c-.666.384-.961 1.045-.661 1.465.301.421 1.091.463 1.756.074l39.066-22.557c.666-.384.952-1.036.652-1.461-.3-.425-1.081-.457-1.747-.074ZM153.675 119.401l-39.071 22.554c-.661.383-.924 1.026-.592 1.428.333.402 1.151.42 1.817.037l39.071-22.568c.66-.383.924-1.016.582-1.418-.342-.402-1.142-.43-1.807-.033ZM157.441 123.718l-39.056 22.553c-.666.384-.925 1.012-.537 1.387.389.374 1.211.383 1.872 0l39.071-22.558c.665-.384.897-1.003.527-1.387-.37-.383-1.202-.374-1.877.005ZM161.601 127.847l-39.071 22.554c-.661.383-.878.998-.462 1.363s1.266.351 1.932-.032l39.038-22.558c.661-.384.869-.985.462-1.354-.406-.37-1.252-.356-1.899.027Z"
      fill="#455A64"
    />
    <path
      d="m115.875 82.7-18.43 10.629c.203 6.678.965 16.309 3.757 26.01l18.435-10.63c-2.796-9.7-3.559-19.336-3.762-26.01Z"
      fill="#16796F"
    />
    <path
      opacity={0.8}
      d="m115.875 82.7-18.43 10.629c.203 6.678.965 16.309 3.757 26.01l18.435-10.63c-2.796-9.7-3.559-19.336-3.762-26.01Z"
      fill="#fff"
    />
    <path
      d="M104.959 91.858a3.036 3.036 0 0 0-.897-2.339l-6.618 3.818c.056 1.765.148 3.739.31 5.865a4.34 4.34 0 0 0 2.5-.462c2.602-1.193 4.705-4.285 4.705-6.882Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M104.959 91.858a3.036 3.036 0 0 0-.897-2.339l-6.618 3.818c.056 1.765.148 3.739.31 5.865a4.34 4.34 0 0 0 2.5-.462c2.602-1.193 4.705-4.285 4.705-6.882Z"
      fill="#fff"
    />
    <path
      d="M118.07 102.332c-1.229-1.548-3.729-6.331-4.797-8.415a.466.466 0 0 0-.454-.251.464.464 0 0 0-.401.33 50.714 50.714 0 0 0-1.996 12.131 17.551 17.551 0 0 1-3.915-4.354.413.413 0 0 0-.615-.068.418.418 0 0 0-.12.198c-2.015 7.759-1.003 15.394-1.003 15.394l14.868-8.587a85.633 85.633 0 0 1-1.567-6.378Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M118.07 102.332c-1.229-1.548-3.729-6.331-4.797-8.415a.466.466 0 0 0-.454-.251.464.464 0 0 0-.401.33 50.714 50.714 0 0 0-1.996 12.131 17.551 17.551 0 0 1-3.915-4.354.413.413 0 0 0-.615-.068.418.418 0 0 0-.12.198c-2.015 7.759-1.003 15.394-1.003 15.394l14.868-8.587a85.633 85.633 0 0 1-1.567-6.378Z"
      fill="#fff"
    />
  </svg>
);
export default SvgForms;
