import { COLORS } from 'constants/colors'
import { useModal, useSalonCache, useToast, useUserCache } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, FormLabel, Heading, MultiSelectOption, Paragraph, RadioButton, SvgArrowLeft, SvgCharmMenuKebab, SvgCheckMark, SvgClose, SvgForms, SvgNote, SvgPlus, SvgSections, ToastProps, ToastWrapper, ToggleOnly } from 'ui'
import ClientDetailsForm from '../modals/ClientDetailsForm'
import CustomForm from '../modals/CustomForm'
import { Menu, Transition } from '@headlessui/react'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { AUTOMATIC_RULES_OPTIONS, CUSTOM_FORM_QUESTION_TYPES, RULES_OPTIONS } from '../constants'
import { ClientDetailsInputWithApplicables, CustomSectionsInput, InputField, WorkFlowForm, WorkFlowFormInput } from '../types'
import { Service } from 'core/generated'
import { useBusinessServices } from 'api/useLoyaltyAndPromos'
import SelectService from '../modals/SelectService'
import PreviewOrCompleteFormScreen from './PreviewOrCompleteFormScreen'
import { useCreateWorkflowForm, useUpdateWorkflowForm } from 'api/useForms'
import axios from 'axios'

const CreateForm = ({
  closeCreateForm, formsCreationOrModification, selectedWorkFlow
}: {
  closeCreateForm: () => void,
  formsCreationOrModification: boolean,
  selectedWorkFlow?: WorkFlowForm
}) => {
  const { getUserData } = useUserCache()
  const { addToast, toast } = useToast()
  const { getSalonData } = useSalonCache()
  const createWorkflowForm = useCreateWorkflowForm();
  const updateWorkflowForm = useUpdateWorkflowForm();
  const user = getUserData()
  const salon = getSalonData()
  const [previewForm, setPreviewForm] = useState(false);
  const [clientDetails, setClientDetails] = useState<ClientDetailsInputWithApplicables | null>(null)
  const [formQuestions, setFormQuestions] = useState<CustomSectionsInput[]>([])
  const [selectedServices, setSelectedServices] = useState<Service[]>([])
  const [customIndex, setCustomIndex] = useState(null)
  const [formDetails, setFormDetails] = useState({
    name: '',
    whenToFillForm: '',
    whenTheFormShouldBeSentOut: '',
    signature: false
  })
  const {
    data: servicesData,
    refetch: refetchBusinessServices
  } = useBusinessServices({
    businessId: user?.business?.id
  });
  const services = useMemo(() => servicesData?.businessServices, [servicesData])

  useEffect(() => {
    refetchBusinessServices()
  }, [salon?.id])

  useEffect(() => {
    if (selectedWorkFlow && formsCreationOrModification) {
      setFormDetails({
        name: selectedWorkFlow?.title,
        whenToFillForm: selectedWorkFlow?.form_fill_method,
        whenTheFormShouldBeSentOut: selectedWorkFlow?.send_condition,
        signature: selectedWorkFlow?.signature
      })
      const workFlowServices = selectedWorkFlow?.services?.map((service) => service?.service_id);
      const selectedServices = services?.filter((service) => workFlowServices?.includes(service?.id)) || [];
      setSelectedServices(selectedServices)
      const sections: CustomSectionsInput[] = Array?.isArray(selectedWorkFlow?.sections) && selectedWorkFlow?.sections?.length ? selectedWorkFlow?.sections?.map((section, index) => {
        return {
          name: section?.title,
          description: section?.description,
          index,
          type: section?.type === 'personal_info' ? 'personal_info' : 'custom', // or 'client' depending on your logic
          questions: Array?.isArray(section?.inputs) && section?.inputs?.length ? section?.inputs?.map((question) => {
            return {
              key: question?.key,
              isRequired: question?.is_required,
              description: question?.description,
              type: question?.type?.toLowerCase(),
              label: question?.label,
              options: question?.options
            }
          }) : []
        }
      }) : [];
      const clientDetails = sections?.find((section) => section?.type === 'personal_info')
      setClientDetails(clientDetails)
      setFormQuestions(sections)
    }
  }, [selectedWorkFlow])
  const {
    isVisible: isServiceSelectedVisible,
    openModal: openServiceSelected,
    closeModal: closeServiceSelected
  } = useModal()
  const {
    isVisible: isClientDetailsFormVisible,
    openModal: openClientDetailsForm,
    closeModal: closeClientDetailsForm
  } = useModal()

  const {
    isVisible: isCustomDetailsFormVisible,
    openModal: openCustomDetailsForm,
    closeModal: closeCustomDetailsForm
  } = useModal()

  const updateFormDetailsState = (value: string, field: string) => {
    if (field === 'name') {
      // update form details
      setFormDetails({
        ...formDetails,
        name: value
      })
    }

    if (field === 'whenToFillForm') {
      setFormDetails({
        ...formDetails,
        whenToFillForm: value
      })
    }

    if (field === 'whenTheFormShouldBeSentOut') {
      setFormDetails({
        ...formDetails,
        whenTheFormShouldBeSentOut: value
      })
    }
  }
  const keyOptions = CUSTOM_FORM_QUESTION_TYPES?.map?.((category) => {
    const Icon = category?.icon;
    return {
      label: (
        <div className='space-x-3' style={{ display: 'flex', alignItems: 'center' }}>
          <Icon width="24px" height="24px" />
          <span>{category?.label}</span>
        </div>
      ),
      value: category?.value
    };
  }) as unknown as MultiSelectOption[];

  const onChangeQuestionType = (questionIndex: number, index: number) => (_question: MultiSelectOption) => {
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === questionIndex) {
              return {
                ...question,
                type: _question?.value
              }
            }
            return question
          })
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const addNewQuestion = (index: number) => {
    const newQuestion: InputField = {
      label: '',
      key: '',
      type: '',
      options: [],
      isRequired: false,
      description: ''
    }
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: [...(custom?.questions || []), newQuestion]
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const onChangeQuestion = (questionIndex: number, index: number, value: string) => {
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === questionIndex) {
              return {
                ...question,
                label: value,
                description: value,
                key: value?.toLowerCase()?.replace(/\s/g, '_')
              }
            }
            return question
          })
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const getQuestionSelectedValue = (value?: string) => {
    if (!value) return [];

    if (value === '') return []

    const question = Array?.isArray(keyOptions) && keyOptions?.length && keyOptions?.find(
      (option) => option?.value === value
    );
    return [question];
  }

  const setClientQuestionRequirementRule = (index: number) => {
    const newQuestions = [...formQuestions].map((custom) => {
      if (custom?.type === 'personal_info') {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === index) {
              return {
                ...question,
                isRequired: !question?.isRequired
              }
            }
            return question
          })
        }
      }
      return custom
    })

    setFormQuestions(newQuestions)
  }

  const setQuestionRequirementRule = (questionIndex: number, index: number) => {
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === questionIndex) {
              return {
                ...question,
                isRequired: !question?.isRequired
              }
            }
            return question
          })
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const formatOptionsIndexValue = (options: string[], index: number, value: string) => {
    const newOptions = [...options]
    newOptions[index] = value
    return newOptions
  }

  const onChangeOptionText = (questionIndex: number, index: number, optionIndex: number, value: string) => {
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === questionIndex) {
              return {
                ...question,
                options: formatOptionsIndexValue(question?.options, optionIndex, value)
              }
            }
            return question
          })
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const cancelOption = (questionIndex: number, index: number, optionIndex: number) => {
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === questionIndex) {
              return {
                ...question,
                options: question?.options?.filter((option, k) => k !== optionIndex)
              }
            }
            return question
          })
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const addNewOption = (questionIndex: number, index: number) => {
    const newQuestions = [...formQuestions].map((custom, i) => {
      if (i === index) {
        return {
          ...custom,
          questions: custom?.questions?.map((question, j) => {
            if (j === questionIndex) {
              return {
                ...question,
                options: [...(question?.options || []), '']
              }
            }
            return question
          })
        }
      }
      return custom
    })
    setFormQuestions(newQuestions)
  }

  const goBack = () => {
    if (previewForm) {
      setPreviewForm(false)
    } else {
      closeCreateForm()
    }
  }

  const changeFormDetails = () => {
    setFormDetails({
      ...formDetails,
      signature: !formDetails?.signature
    })
  }

  const removeQuestion = (index: number) => {
    // get index item  from formQuestions
    const formQuestion = Array.isArray(formQuestions) && formQuestions.length ? formQuestions.find((formQst, i) => index === i) : null;
    if (formQuestion) {
      const newQuestions = formQuestions.filter((formQst, i) => index !== i)
      setFormQuestions(newQuestions)
      if (formQuestion?.type === 'personal_info') {
        setClientDetails(null)
      }
    }
  }

  const editSection = (index: number) => {
    const formQuestion = Array.isArray(formQuestions) && formQuestions.length ? formQuestions.find((formQst, i) => index === i) : null;
    if (formQuestion) {
      if (formQuestion?.type === 'personal_info') {
        openClientDetailsForm()
      } else {
        setCustomIndex(index + 1)
        openCustomDetailsForm()
      }
    }
  }

  const submitWorkFlowForm = async () => {
    if (!formDetails?.name) {
      addToast({
        variant: "error",
        message: "Please enter a name for the form",
      });
      return
    }

    if (!formDetails?.whenToFillForm) {
      addToast({
        variant: "error",
        message: "Please select when to fill the form",
      });
      return
    }

    if (!formDetails?.whenTheFormShouldBeSentOut) {
      addToast({
        variant: "error",
        message: "Please select when the form should be sent out",
      });
      return;
    }
    // form submission
    const sections = Array?.isArray(formQuestions) && formQuestions?.length ? formQuestions?.map((custom, index) => {
      return {
        title: custom?.name,
        description: custom?.description,
        type: custom?.type === 'personal_info' ? 'personal_info' : 'custom',
        order: index + 1,
        inputs: custom?.questions?.map((question) => ({
          key: question?.key,
          label: question?.label,
          description: question?.description,
          type: question?.type?.toUpperCase(),
          is_required: question?.isRequired,
          options: question?.options || []
        }))
      }
    }) : []
    const services = Array?.isArray(selectedServices) && selectedServices?.length ? selectedServices?.map((service) => service?.id) : []
    const payload: WorkFlowFormInput = {
      title: formDetails?.name,
      description: "",
      sections,
      services,
      signature: formDetails?.signature,
      form_fill_method: formDetails?.whenToFillForm,
      send_condition: formDetails?.whenTheFormShouldBeSentOut
    }
    try {
      if (selectedWorkFlow) {
        const id = selectedWorkFlow?.id;
        const workflowFormData = await updateWorkflowForm.mutateAsync({
          args: { ...payload },
          id
        })
        if (workflowFormData?.status === 'success') {
          addToast({
            variant: 'error',
            message: 'Form updated successfully'
          })
          closeCreateForm()
        }
        return
      } else {
        const workflowFormData = await createWorkflowForm.mutateAsync({
          ...payload,
        })
        if (workflowFormData?.status === 'success') {
          addToast({
            variant: 'error',
            message: 'Form created successfully'
          })
          closeCreateForm()
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className='w-full flex flex-col'>
        <div className='w-full py-4 px-8 flex items-start justify-between space-x-4 border-y border-grey-100'>
          <div className='w-full flex items-start space-x-4'>
            <span role='button' onClick={goBack}>
              <SvgArrowLeft width="20px" height="20px" />
            </span>
            <div className="flex flex-col space-y-3">
              <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
                {previewForm ? 'Complete form' : selectedWorkFlow ? 'Update form' : 'Create new form'}
              </Paragraph>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
                {previewForm ? 'Confirm details to complete creating a form' : selectedWorkFlow ? 'Modify form details' : 'Enter the details to create a form'}
              </Paragraph>
            </div>
          </div>
          {(clientDetails || formQuestions.length) && !previewForm ?
            <div className='flex items-start space-x-4'>
              <Button
                variant='secondary'
                className=''
                size='md'
                rounded='md'
                type='button'
                onClick={() => setPreviewForm(true)}
              >
                Preview
              </Button>
              <Button
                variant='primary'
                className=''
                size='md'
                rounded='md'
                type='button'
                onClick={() => setPreviewForm(true)}
              >
                Proceed
              </Button>
            </div> : null}
          {previewForm ?
            <div className='flex items-start space-x-4'>
              <Button
                variant='primary'
                className=''
                size='md'
                rounded='md'
                type='button'
                onClick={submitWorkFlowForm}
                disabled={createWorkflowForm?.isLoading || updateWorkflowForm?.isLoading}
                loading={createWorkflowForm?.isLoading || updateWorkflowForm?.isLoading}
              >
                Complete
              </Button>
            </div> : null}
        </div>
        <div className='w-full flex flex-col xl:flex-row'>
          {/* section type */}
          {previewForm ?
            <div className='w-full max-w-[400px] min-h-screen flex flex-col border-r border-grey-100'>
              <div className='w-full flex flex-col justify-center min-h-[83px] border-b border-grey-100 p-6'>
                <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold' className='flex items-center space-x-2'>
                  <SvgNote /> <span>Form details</span>
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
                  Complete filling form details
                </Paragraph>
              </div>
              <div className='w-full flex flex-col space-y-4 min-h-[83px] border-b border-grey-100 p-6'>
                <FormLabel htmlFor='amount'>
                  Form name*
                </FormLabel>
                <input
                  className="w-full background-none px-3 py-3 rounded-md border border-grey-100 font-semibold text-b4 text-grey-900 focus:outline-none placeholder:text-grey-300"
                  type="text"
                  value={formDetails?.name}
                  onChange={(e) => {
                    updateFormDetailsState(e.target.value, 'name');
                  }}
                  placeholder="Enter form name"
                />
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='normal'>
                  This is what your clients will see.
                </Paragraph>
              </div>
              <div className='w-full flex flex-col space-y-6 min-h-[83px] border-b border-grey-100 p-6'>
                <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
                  RULES
                </Paragraph>
                <div className='w-full flex flex-col space-y-3'>
                  <FormLabel htmlFor='amount'>
                    When should clients fill out form?
                  </FormLabel>
                  <div className="w-full flex flex-col gap-2">
                    {Array.isArray(RULES_OPTIONS) && RULES_OPTIONS.length
                      ? RULES_OPTIONS
                        .map((rule: {
                          label: string;
                          value: string;
                        }) => {
                          return (
                            <div
                              className="flex"
                              onClick={() => updateFormDetailsState(rule?.value, 'whenToFillForm')}
                              key={rule?.value}
                            >
                              <div className={`flex items-start cursor-pointer text-b5 text-grey-900 space-x-2`}>
                                <RadioButton
                                  isChecked={formDetails?.whenToFillForm === rule?.value}
                                />
                                <span className='text-grey-900 text-b5 whitespace-nowrap'>{rule?.label}</span>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>

                {formDetails?.whenToFillForm === 'AUTOMATIC_BEFORE_APPOINTMENT' ?
                  <div className='w-full flex flex-col space-y-3'>
                    <FormLabel htmlFor='whenTheFormShouldBeSentOut'>
                      When form be sent automatically to clients?
                    </FormLabel>
                    <div className="w-full flex flex-col gap-2">
                      {Array.isArray(AUTOMATIC_RULES_OPTIONS) && AUTOMATIC_RULES_OPTIONS.length
                        ? AUTOMATIC_RULES_OPTIONS
                          .map((rule: {
                            label: string;
                            value: string;
                          }) => {
                            return (
                              <div
                                className="flex"
                                onClick={() => updateFormDetailsState(rule?.value, 'whenTheFormShouldBeSentOut')}
                                key={rule?.value}
                              >
                                <div className={`flex items-start cursor-pointer text-b5 text-grey-900 space-x-2`}>
                                  <RadioButton
                                    isChecked={formDetails?.whenTheFormShouldBeSentOut === rule?.value}
                                  />
                                  <span className='text-grey-900 text-b5 whitespace-nowrap'>{rule?.label}</span>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div> : null}

                <div className='w-full flex flex-col space-y-3'>
                  <FormLabel htmlFor='amount'>
                    What services should the form apply to?
                  </FormLabel>

                  <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between'>
                    <Paragraph
                      size="b5"
                      color={COLORS.GREY[300]}
                      weight="normal"
                    > {selectedServices?.length ? selectedServices?.length : 'No'} Services added</Paragraph>
                    <Button
                      variant="light"
                      size="xs"
                      rounded="lg"
                      type="button"
                      className="border border-grey-100 text-grey-300 font-semibold"
                      onClick={() => openServiceSelected()}
                    >
                      <SvgPlus width="14px" height="14px" />
                      Add service
                    </Button>
                  </div>
                </div>
              </div>
              <div className='w-full flex space-x-6 items-center justify-between min-h-[68px] border-b border-grey-100 p-6 cursor-pointer'>
                <Paragraph size='b5' color={COLORS.GREY[900]} weight='normal'>
                  Require clients’ signatures
                </Paragraph>
                <span role='button' onClick={changeFormDetails}>
                  <ToggleOnly isChecked={formDetails?.signature} />
                </span>
              </div>
            </div> :
            <div className='w-full max-w-[400px] min-h-screen flex flex-col border-r border-grey-100'>
              <div className='w-full flex flex-col justify-center min-h-[83px] border-b border-grey-100 p-6'>
                <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold' className='flex items-center space-x-2'>
                  <SvgSections /> <span>SECTIONS</span>
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
                  Select applicable section
                </Paragraph>
              </div>
              <div className={`w-full flex justify-between items-start min-h-[83px] border-b border-grey-100 p-6 ${!clientDetails ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={!clientDetails ? openClientDetailsForm : null}>
                <div className='flex flex-col'>
                  <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold'>
                    Client details
                  </Paragraph>
                  <Paragraph size='b4' color={!clientDetails ? COLORS.GREY[900] : COLORS.GREY[400]} weight='normal'>
                    Add first name, last name, email, phone, address, gender, and birthday.
                  </Paragraph>
                </div>
                <div className='w-fit flex justify-between items-start text-grey-300 rounded-full p-1.5 bg-[#16796F0D]'>
                  {!clientDetails ? <SvgPlus width="24px" height="24px" /> : <SvgCheckMark width="18px" height="18px" />}
                </div>
              </div>
              <div className='w-full flex justify-between items-start min-h-[83px] border-b border-grey-100 p-6 cursor-pointer' onClick={openCustomDetailsForm}>
                <div className='flex flex-col'>
                  <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold'>
                    Custom section
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[900]} weight='normal'>
                    Create preferences, special requests, and other personalized fields.
                  </Paragraph>
                </div>
                <div className='w-fit flex justify-between items-start text-grey-300 rounded-full p-1.5 bg-[#16796F0D]'>
                  <SvgPlus width="24px" height="24px" />
                </div>
              </div>
            </div>}
          {/* form type */}
          {!previewForm ?
            <div className='w-full flex flex-col p-6 space-y-6 bg-[#F3F6F659]'>
              {Array?.isArray(formQuestions) && formQuestions?.length ? formQuestions?.map((customSection, index) => {
                return (
                  <div className='w-full bg-white border border-[#E9E9E9BF] flex flex-col space-y-3 rounded-md' key={index}>
                    <div className='w-full flex py-5 px-6 items-center border-b border-[#E9E9E9BF] justify-between'>
                      <div className='w-full flex flex-col'>
                        <Paragraph size='b4' weight='bold' className=''>
                          {customSection?.name}
                        </Paragraph>
                        <Paragraph size='b5' weight='bold' color={COLORS.GREY[300]} className=''>
                          {customSection?.description}
                        </Paragraph>
                      </div>

                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex space-x-2 w-full items-center justify-center px-4 py-2 text-b5 font-medium text-grey-300 border border-grey-50/70 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0">
                            <SvgCharmMenuKebab width="24px" height="24px" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            <div className="flex flex-col p-4 space-y-4">
                              <Menu.Item>
                                <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => editSection(index)}>
                                  Edit
                                </span>
                              </Menu.Item>
                              <Menu.Item>
                                <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => removeQuestion(index)}>
                                  Delete
                                </span>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    {customSection?.type === 'custom' ?
                      <div className='w-full flex flex-col gap-y-8 p-6'>
                        {Array?.isArray(customSection?.questions) && customSection?.questions?.length ? customSection?.questions?.map((question, questionIndex) => {
                          return (
                            <div className='w-full flex flex-col space-y-2' key={index}>
                              <div className='w-full flex flex-col xl:flex-row gap-4'>
                                <div className='w-full flex flex-col space-y-2'>
                                  <FormLabel htmlFor='amount'>
                                    What is your question?
                                  </FormLabel>
                                  <input
                                    className="w-full background-none px-3 py-3 rounded-md border border-grey-100 font-semibold text-b4 text-grey-900 focus:outline-none placeholder:text-grey-300"
                                    type="text"
                                    value={question?.label}
                                    onChange={(e) => {
                                      onChangeQuestion(questionIndex, index, e.target.value);
                                    }}
                                    placeholder="Enter question"
                                  />
                                </div>
                                <div className='w-full flex flex-col space-y-2'>
                                  <FormLabel htmlFor='amount'>
                                    What is your question?
                                  </FormLabel>
                                  <SingleSelect
                                    selected={getQuestionSelectedValue(question?.type)}
                                    options={keyOptions}
                                    placeholder={'Question type'}
                                    setSelected={onChangeQuestionType(questionIndex, index)}
                                  />
                                </div>
                              </div>
                              {question?.type === 'radio' || question?.type === 'checkbox' || question?.type === 'select' ?
                                <div className='w-full flex flex-col space-y-2'>
                                  {Array?.isArray(question?.options) && question?.options?.length ? question?.options?.map((option, optionIndex) => {
                                    return (
                                      <div className='w-full flex items-center justify-between' key={index}>
                                        {question?.type === 'radio' ? (
                                          <span className='w-[16px] h-[16px] border rounded-full'></span>
                                        ) : question?.type === 'checkbox' ? (
                                          <span className='w-[16px] h-[16px] border rounded-sm'></span>
                                        ) : question?.type === 'select' ? (
                                          <span className='min-w-[16px] h-[16px]'>
                                            {optionIndex + 1}
                                          </span>
                                        ) : null}
                                        <input
                                          className="w-full background-none px-3 py-1 font-semibold text-b4 text-grey-900 focus:outline-none placeholder:text-grey-300"
                                          type="text"
                                          value={option}
                                          onChange={(e) => {
                                            onChangeOptionText(questionIndex, index, optionIndex, e.target.value);
                                          }}
                                          placeholder="Enter your option"
                                        />
                                        <span className='w-fit flex justify-between items-start text-grey-300 rounded-full p-1 bg-[#16796F0D]' role='button' onClick={() => cancelOption(questionIndex, index, optionIndex)}>
                                          <SvgClose width="14px" height="14px" />
                                        </span>
                                      </div>
                                    )
                                  }) : null}
                                  <div className='w-full flex space-x-3 items-center'>
                                    <span role='button' className="w-fit text-grey-300 text-b4 font-semibold cursor-text" onClick={() => addNewOption(questionIndex, index)}>
                                      Add option
                                    </span>
                                    <span role='button' className="w-fit text-green-300 text-b4 font-semibold cursor-pointer">
                                      or Other
                                    </span>
                                  </div>
                                </div> : null}
                              <div className='w-full flex justify-end'>
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2' onClick={() => setQuestionRequirementRule(questionIndex, index)}>
                                  <span>Make required</span>
                                  <ToggleOnly isChecked={question?.isRequired} />
                                </div>
                              </div>
                            </div>
                          )
                        }) : null}


                        <Button
                          variant='text'
                          size='lg'
                          className='w-full border-2 border-dashed border-grey-400 text-grey-300 hover:border-grey-800'
                          fontSize='b4'
                          onClick={() => addNewQuestion(index)}
                        >
                          <SvgPlus width="24px" height="24px" />
                          <span>Add new question</span></Button>
                      </div> : null}
                    {customSection?.type === 'personal_info' ?
                      <div className='w-full flex flex-col gap-y-8 p-6'>
                        {Array?.isArray(customSection?.questions) && customSection?.questions?.length ? customSection?.questions?.map((field, index) => {
                          return (
                            <div className='w-full flex flex-col space-y-2' key={index}>
                              <div className='w-full flex justify-between items-center'>
                                <Paragraph size='b5' weight='bold' className=''>
                                  {field?.label}
                                </Paragraph>
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2' onClick={() => setClientQuestionRequirementRule(index)}>
                                  <span>Make required</span>
                                  <ToggleOnly isChecked={field?.isRequired} />
                                </div>
                              </div>
                              <div className='w-full flex flex-col space-y-1 py-2 px-4 rounded-md border border-[#E9E9E9BF]'>
                                <Paragraph size='b4' weight='bold' color={COLORS.GREY[400]} className=''>
                                  Enter {field?.label}
                                </Paragraph>
                              </div>
                            </div>
                          )
                        }) : null}
                      </div>
                      : null}
                  </div>
                )
              }) : null}
              {!clientDetails && !formQuestions.length ?
                <div className='w-full flex flex-col items-center'>
                  <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
                    <SvgForms width="100%" height="100%" />
                    <Heading variant='h1' size='h10' weight='bold' color={COLORS.GREY[900]}>
                      Build your form by adding a first section
                    </Heading>
                  </div>
                </div> : null}
            </div> :
            <PreviewOrCompleteFormScreen
              customQuestions={formQuestions}
            />}
        </div>
      </div>
      <ClientDetailsForm
        isVisible={isClientDetailsFormVisible}
        closeModal={closeClientDetailsForm}
        addToast={addToast}
        setClientDetails={setClientDetails}
        customSections={formQuestions}
        setCustomSections={setFormQuestions}
      />
      <CustomForm
        isVisible={isCustomDetailsFormVisible}
        addToast={addToast}
        closeModal={() => {
          closeCustomDetailsForm()
          setCustomIndex(null)
        }}
        setCustomSections={setFormQuestions}
        customSections={formQuestions}
        customIndex={customIndex}
      />
      <SelectService
        isVisible={isServiceSelectedVisible}
        closeModal={closeServiceSelected}
        addToast={addToast}
        setSelectedServices={setSelectedServices}
        selectedServices={selectedServices}
        services={services}
      />
    </>
  )
}

export default CreateForm