import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCallOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m6.63 2.515.973-.292a2.292 2.292 0 0 1 2.752 1.254l.752 1.671a2.291 2.291 0 0 1-.528 2.616L9.333 8.925a.208.208 0 0 0-.065.13c-.036.33.188.974.705 1.87.375.65.716 1.108 1.005 1.364.203.18.314.218.361.204l1.675-.512a2.291 2.291 0 0 1 2.529.85l1.066 1.48a2.291 2.291 0 0 1-.282 3.004l-.739.7a3.125 3.125 0 0 1-2.989.74c-2.295-.64-4.352-2.577-6.196-5.77-1.845-3.196-2.493-5.95-1.895-8.26a3.125 3.125 0 0 1 2.123-2.21Zm.362 1.198a1.875 1.875 0 0 0-1.275 1.325c-.501 1.943.073 4.384 1.77 7.323 1.695 2.935 3.519 4.652 5.45 5.192a1.875 1.875 0 0 0 1.793-.445l.738-.7a1.042 1.042 0 0 0 .129-1.366l-1.067-1.48a1.042 1.042 0 0 0-1.15-.386l-1.68.514c-.974.29-1.858-.494-2.808-2.14-.642-1.108-.94-1.967-.865-2.634.038-.347.2-.667.454-.905l1.246-1.16a1.042 1.042 0 0 0 .239-1.19l-.751-1.672a1.042 1.042 0 0 0-1.25-.57l-.973.294Z"
      fill="#000"
    />
  </svg>
);
export default SvgCallOutline;
