import React, { useEffect, useMemo } from 'react';
import { AccountSetupTemplateProps } from './types';
import { useAccountSetupNav } from 'hooks/useNav';
import { useUserCache } from 'hooks/useUserCache';
import { Logo } from 'ui/atoms/logo';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import Heading from 'ui/atoms/heading/Heading';
import Button from 'ui/atoms/button/Button';
import { SvgArrowRight } from 'ui';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from 'constants/routes';
import { useLazyQuery } from '@apollo/client';
import { USER, useUserAuthorizations } from 'api/useAccount';
import { User } from 'core/generated';
import { useSalonCache } from 'hooks';

const AccountSetupTemplate: React.FunctionComponent<
  AccountSetupTemplateProps
> = (props: AccountSetupTemplateProps) => {
  const { setSalonData, getSalonData } = useSalonCache();
  const { getBusinessData, setBusinessData } = useUserCache();
  const navigate = useNavigate();
  const salon = getSalonData();

  const {
    data: dataPermissions,
    refetch: refetchPermission
  } = useUserAuthorizations()

  useMemo(() => {
    if (dataPermissions) {
      localStorage.setItem(
        'permissions',
        JSON.stringify(dataPermissions?.auhorizations)
      );
    }
  }, [dataPermissions])
  
  useEffect(() => {
    if (salon?.id) {
      refetchPermission()
    }
  }, [salon?.id, refetchPermission])
  
  const {
    initialPageValues,
    pages,
    currentSubPageComp,
    deleteAccountSetupNav,
  } = useAccountSetupNav()

  const { skipSetUp } = useUserCache();

  const [getUser, { loading, error, data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );

      // Set additional state based on user data
      setBusinessData(currentUser?.business);
      const salons = currentUser?.business?.salons
      const getSalon = salons.find((salon) => salon.id === salon?.id);
      setSalonData(getSalon || salons[0])

      // Navigate to the next page
      deleteAccountSetupNav();
      skipSetUp({
        skip: true,
      });

      // navigate(PAGE_ROUTES.HOME, {
      //   replace: true,
      // });
      window.location.href = PAGE_ROUTES.HOME
    }

    return currentUser;
  }, [data?.user]);

  const goToDashboard = () => {
    getUser()
  };

  return (
    <main className="flex flex-col xl:flex-row items-start justify-start w-full min-h-screen bg-white xl:bg-grey-100">
      <div className="w-full xl:h-screen xl:w-[450px] flex flex-col space-y-[80px] py-4 xl:py-[60px] px-6 xl:px-[50px] bg-[#FFFDF5]">
        <div className="flex flex-col space-y-2">
          <Logo className="w-[79px]" />
        </div>
        <div className="w-full flex flex-col space-y-[30px] justify-center">
          <div className='w-full flex flex-col space-y-2'>
            <Heading variant='h1' size='h5' weight='semiBold'>Finish your account setup</Heading>
            <Paragraph
              size="b5"
              weight="normal"
              className="w-full"
              color={COLORS.GREY[400]}
            >
              Welcome, let’s get you all setup on Splice today.
            </Paragraph>
          </div>
          <nav className="w-fit xl:w-full flex flex-row xl:flex-col space-x-6 xl:space-y-6 xl:space-x-0 mb-5">
            {Array.isArray(pages) &&
              pages.map((page, index) => {
                const { name, key, subPages } = page;
                const pageIsActive = initialPageValues?.currentPage >= index;
                const pageIsCurrent = initialPageValues?.currentPage === index;
                const isCurrentSubPage = currentSubPageComp?.key === key;
                const isCurrentPageOrSubPage = pageIsActive || isCurrentSubPage;
                return (
                  <li
                    key={index}
                    className={`w-full flex space-x-6
                  ${!isCurrentPageOrSubPage ? 'cursor-not-allowed' : ''}
                `}
                  >

                    <div className="hidden w-full xl:flex flex-col space-y-2">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        className={`w-full flex items-center justify-between p-4 rounded-md ${isCurrentPageOrSubPage ? 'bg-green-300' : ''}`}
                        color={
                          isCurrentPageOrSubPage
                            ? COLORS.WHITE
                            : COLORS.GREY[400]
                        }
                      >
                        <span>{name}</span>
                        <SvgArrowRight />
                      </Paragraph>
                      <ul
                        className={`${!pageIsCurrent
                            ? 'hidden'
                            : 'flex flex-col pl-[30px] space-y-4'
                          }`}
                      >
                        {Array.isArray(subPages) &&
                          subPages.map((subPage, subPageIndex) => {
                            const { name: subPageName, key: subPageKey } =
                              subPage;
                            const subPageIsActive =
                              initialPageValues?.currentSubPage >= subPageIndex;
                            return (
                              <li
                                key={subPageKey}
                                className={`w-full flex space-x-6 text-b6
                          ${subPageIsActive
                                    ? 'text-grey-300 font-semibold'
                                    : 'text-grey-400 cursor-not-allowed'
                                  }
                        `}
                              >
                                <span>{subPageName}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                );
              })}
          </nav>
        </div>
      </div>
      <div className="w-full h-screen relative justify-start items-center xl:items-start bg-white border-l border-grey-20">
        <div className="w-full h-full max-w-[1000px] overflow-auto px-9 xl:px-[100px] pt-[100px] pb-[140px]">
          <div className="flex flex-col items-start space-y-6">
            <Heading
              variant="h1"
              size="h9"
              className="text-center"
              weight="bold"
            >
              {props.title}
            </Heading>
            {props?.subtitle ? (
              <Paragraph
                size="b4"
                weight="normal"
                className="w-full"
                color={COLORS.GREY[400]}
              >
                {props.subtitle}
              </Paragraph>
            ) : null}
            {props.children}
          </div>
        </div>
        {/* skip button */}
        {props?.skip ? (
          <div className="w-full absolute bottom-0 flex py-6 px-4 border-t border-grey-20 bg-white z-30">
            <Button
              variant="text"
              className="text-green-300 underline"
              disabled={false}
              loading={false}
              size="none"
              fontSize="b5"
              fontWeight="semiBold"
              type="button"
              onClick={props.skip?.onClick ?? goToDashboard}
            >
              {props.skip.text}
            </Button>
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default AccountSetupTemplate;
