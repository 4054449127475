import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHouseOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m17.384 8.99-6.25-6.25a1.25 1.25 0 0 0-1.768 0l-6.25 6.25a1.24 1.24 0 0 0-.366.885v7.5a.625.625 0 0 0 .625.625h5A.625.625 0 0 0 9 17.375V13h2.5v4.375a.625.625 0 0 0 .625.625h5a.625.625 0 0 0 .625-.625v-7.5a1.24 1.24 0 0 0-.366-.884Zm-.884 7.76h-3.75v-4.375a.624.624 0 0 0-.625-.625h-3.75a.625.625 0 0 0-.625.625v4.375H4V9.875l6.25-6.25 6.25 6.25v6.875Z"
      fill="#000"
    />
  </svg>
);
export default SvgHouseOutline;
