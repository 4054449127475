import { useMutation, useQuery, gql } from "@apollo/client";
import { Business, Client, ClientMilestone, Milestone, MilestoneActivity, PackageVoucher } from "core/generated";

const GETE_BUSINESS_VIA_IDENTIFIER = gql`
  query Business($bookingUrlIdentifier: String!) {
    business(bookingUrlIdentifier: $bookingUrlIdentifier) {
      about
      acceptsOnlineBookings
      cancellationFee
      noShowFee
      bookingManagement
      bookingUrlIdentifier
      bvn
      callingCode
      cancellationPolicy
      countryCode
      timezone
      termsAndConditions
      headerImageUrl
      headerImageUrls {
        businessId
        createdAt
        id
        imageUrl
        updatedAt
      }
      id
      isAccountSetupComplete
      isAppointmentLinkSetup
      isBvnVerified
      allowServiceStaffSelection
      singleStaffBooking
      allowEmail
      allowSms
      isDeleted
      logoUrl
      name
      note
      ownerId
      phone
      rcNumber
      slug
      teamSize
      updatedAt
      country {
        name
        code
        emojiFlag
        collectionsSupported
        payoutsSupported
        timezone
        utcOffset
        currency {
          code
          symbol
        }
        utcOffset
      }
      virtualAccount {
        accountName
        accountNumber
        bankName
        businessId
        id
        subaccountId
      }
      salons {
        address
        bearsProcessingFee
        branchName
        callingCode
        countryCode
        city
        depositLinkValidityHours
        depositType
        depositValue
        email
        id
        intervalMinutes
        isDepositRequired
        isTaxVisible
        locationType
        onboardingStatus
        phone
        state
        status
        taxInclusivePricing
        salonStaffs {
          role
          id
          user {
            firstName
            fullName
            lastName
          }
          specialty
        }
        packages {
          activateTax
          addedById
          businessId
          customPrice
          description
          id
          isDiscountApplicable
          isPurchasableAsVoucher
          name
          salonId
          allowSaleOn
          status
          totalPrice
          updatedAt
          usesCustomPrice
          validityMonths
          packageServices {
            createdAt
            duration
            id
            name
            packageId
            price
            quantity
            serviceId
            updatedAt
          }
        }
        services {
          addedById
          businessId
          createdAt
          customerCanSchedule
          deletedAt
          depositType
          depositValue
          description
          duration
          id
          isDepositRequired
          name
          isPublic
          featured
          featuredIndex
          price
          pricingType
          salonId
          updatedAt
          category {
            createdAt
            id
            name
            updatedAt
            orderIndex
          }
          serviceStaffs {
            id
            serviceId
            price
            salonStaff {
              id
              specialty
              role
              user {
                fullName
                firstName
                lastName
              }
            }
          }
        }
        salonHours {
          closeTime
          createdAt
          day
          id
          openTime
          updatedAt
        }
      }
      addons {
        expiryDate
        subscribed
        price
        addon {
          createdAt
          featureList
          id
          name
          updatedAt
        }
      }
      activeAddons {
        createdAt
        featureList
        id
        name
        updatedAt
      }
      roles {
        createdAt
        id
        name
        updatedAt
        authorizations {
          createdAt
          description
          id
          name
          updatedAt
          addon {
            createdAt
            name
            updatedAt
          }
        }
      }
    }
  }
`;

export const useGetBusinessViaIdentifier = (bookingUrlIdentifier: string) => {
  return useQuery<{
    business: Business
  }>(GETE_BUSINESS_VIA_IDENTIFIER, {
    variables: { bookingUrlIdentifier },
    skip: !bookingUrlIdentifier,
  });
};

const SALON_AVAILABLE = gql`
  query SalonAvailability($salonId: ID!, $services: [AvailabilityInput!]!, $startDate: String!, $endDate: String!) {
    salonAvailability(
      salonId: $salonId
      services: $services
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      results {
        startTime
        endTime
      }
    }
  }
`;

export const useSalonAvailability = ({
  salonId,
  services,
  startDate,
  endDate,
}: {
  salonId: string;
  services: { serviceId: string; staffIds: string[] }[];
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery<{
    salonAvailability: {
      date: string;
      results: { startTime: string; endTime: string }[];
    }[];
  }>(SALON_AVAILABLE, {
    fetchPolicy: 'network-only',
    variables: { salonId, services, startDate, endDate },
    skip: !salonId || !services?.length || !startDate || !endDate,
  });
}

const SEARCH_CLIENT = gql`
  query ClientSearch($salonId: ID!, $phone: String!, $countryCode: String!) {
    clientSearch(salonId: $salonId, phone: $phone, countryCode: $countryCode) {
      addedById
      address
      amountSpent
      businessId
      createdAt
      deletedAt
      dob
      id
      lastVisit
      pointsEarned
      salonId
      updatedAt
      altCallingCode
      altCountryCode
      altPhone
      callingCode
      countryCode
      email
      firstName
      lastName
      phone
      clientMembership {
				id
				membershipStatus
				membership {
					name
					rewardPercentage
					services {
						id
						name
					}
				}
			}
      walletBalance
			membershipWalletBalance
      activeClientMilestones {
        clientId
        createdAt
        id
        milestoneId
        status
        updatedAt
        milestone {
          createdAt
          customerPointsAttained
          customerReceives
          customerReceivesType
          id
          loyaltyProgramId
          name
          updatedAt
          services {
            name
          }
        }
      }
    }
  }
`;

export const useSearchClient = ({
  salonId,
  phone,
  countryCode,
}: {
  salonId: string;
  phone?: string;
  countryCode?: string;
}) => {
  return useQuery<{
    clientSearch: Client;
  }>(SEARCH_CLIENT, {
    variables: { salonId, phone, countryCode },
    skip: !salonId || !phone || !countryCode,
  });
};

const SERVICE_STAFF = gql`
  query ServiceStaffs($serviceIds: [ID!]!) {
    serviceStaffs(serviceIds: $serviceIds) {
      serviceStaffs {
        id
        serviceId
        updatedAt
        salonStaff {
          canServeCustomers
          id
          level
          role
          specialty
          user {
            firstName
            fullName
            id
            lastName
          }
        }
      }
    }
  }
`;

export const useServiceStaff = (serviceIds: string[]) => {
  return useQuery<{
    serviceStaffs: {
      serviceStaffs: {
        id: string;
        serviceId: string;
        updatedAt: string;
        salonStaff: {
          canServeCustomers: boolean;
          id: string;
          level: string;
          role: string;
          specialty: string;
          user: {
            firstName: string;
            fullName: string;
            id: string;
            lastName: string;
          };
        };
      }[];
    };
  }>(SERVICE_STAFF, {
    variables: { serviceIds },
    skip: !serviceIds || !serviceIds.length,
  });
};

const REDEEM_VOUCHER = gql`
  mutation RedeemVoucherCode($input: RedeemVoucherCodeInput!) {
    redeemVoucherCode(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      voucher {
        amountRedeemed
        clientId
        code
        createdAt
        expiresAt
        id
        isPaymentConfirmed
        lastUsageAt
        price
        redemptionMode
        reference
        salonId
        status
        updatedAt
        validityMonths
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
        voucherServices {
          createdAt
          duration
          id
          isRedeemed
          name
          quantity
          packageVoucherId
          price
          redeemedCount
          serviceId
          updatedAt
        }
      }
    }
  }
`;

export const useRedeemVoucher = () => {
  const [redeemVoucher, options] = useMutation<{
    redeemVoucherCode: {
      status: string;
      errors: { message: string; property: string }[];
      voucher: PackageVoucher;
    };
  }>(REDEEM_VOUCHER)

  return {
    redeemVoucher,
    ...options,
  };
}

const CREATE_VOUCHER = gql`
  mutation CreateVoucher($input: CreateVoucherInput!) {
    createVoucher(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      data {
        discountAmount
        processingFeeAmount
        subtotal
        taxAmount
        totalAmountPayable
        paymentAuthorization {
          accessCode
          authorizationUrl
          reference
        }
      }
    }
  }
`;

export const useCreateVoucher = () => {
  const [createVoucher, options] = useMutation<{
    createVoucher: {
      status: number;
      errors: { message: string; property: string }[];
      data: {
        discountAmount: number;
        processingFeeAmount: number;
        subtotal: number;
        taxAmount: number;
        totalAmountPayable: number;
        paymentAuthorization: {
          accessCode: string;
          authorizationUrl: string;
          reference: string;
        };
      };
    };
  }>(CREATE_VOUCHER)

  return {
    createVoucher,
    ...options,
  };
}

const PACKAGES_PREVIEW_PRICE = gql`
  mutation VoucherPreview($input: VoucherPreviewInput!) {
    voucherPreview(input: $input) {
      clientMutationId
      status
      data {
        discountAmount
        processingFeeAmount
        subtotal
        taxAmount
        totalAmountPayable
      }
      errors {
        message
        property
      }
    }
  }
`;

export const usePreviewVoucherPrices = () => {
  const [previewVoucherPrices, options] = useMutation<{
    voucherPreview: {
      status: number;
      data: {
        discountAmount: number;
        processingFeeAmount: number;
        subtotal: number;
        taxAmount: number;
        totalAmountPayable: number;
      };
      errors: { message: string; property: string }[];
    };
  }>(PACKAGES_PREVIEW_PRICE)

  return {
    previewVoucherPrices,
    ...options,
  };
}

const CLIENT_LOGIN = gql`
  mutation ClientLogin($input: ClientLoginInput!) {
    clientLogin(input: $input) {
      clientMutationId
      status
      token
      errors {
        message
        property
      }
      client {
        address
        dob
        id
        pointsEarned
        salonId
        businessId
        updatedAt
        altCallingCode
        altCountryCode
        altPhone
        callingCode
        countryCode
        email
        firstName
        fullName
        id
        lastName
        phone
        business {
          id
          name
          country {
            timezone
            currency {
              code
              id
              name
              symbol
            }
          }
        }
        businesses {
          id
          name
          country {
            currency {
              code
              id
              name
              symbol
            }
          }
          salons {
            id
          }
        }
      }
    }
  }
`;

export const useClientLogin = () => {
  const [clientLogin, options] = useMutation<{
    clientLogin: {
      status: number;
      token: string;
      errors: { message: string; property: string }[];
      client: Client;
    };
  }>(CLIENT_LOGIN)

  return {
    clientLogin,
    ...options,
  };
}

const CLIENT_SET_PASSWORD = gql`
  mutation ClientSetPassword($input: ClientSetPasswordInput!) {
    clientSetPassword(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useClientSetPassword = () => {
  const [clientSetPassword, options] = useMutation<{
    clientSetPassword: {
      status: number;
      errors: { message: string; property: string }[];
    };
  }>(CLIENT_SET_PASSWORD)

  return {
    clientSetPassword,
    ...options,
  };
}

const CUSTOMER_DASHBOARD_LOYALTY_OVERVIEW = gql`
  query CustomerLoyaltyOverviewData($businessId: ID!) {
    customerLoyaltyOverviewData(businessId: $businessId) {
      clientMilestones {
        clientId
        createdAt
        id
        milestoneId
        status
        updatedAt
        milestone {
          createdAt
          customerPointsAttained
          customerReceives
          customerReceivesType
          id
          loyaltyProgramId
          name
          updatedAt
          loyaltyProgram {
            business {
              name
            }
            createdAt
            id
            rewardPoint
            ruleRequirement
            ruleValue
            updatedAt
            validityMonths
            milestones {
              customerPointsAttained
              customerReceives
              customerReceivesType
            }
          }
          services {
            name
          }
        }
        appointment {
          appointmentServices {
            name
            appointmentServiceStaff {
              salonStaff {
                user {
                  firstName
                }
              }
            }
          }
          discountAmount
          startAt
          discountType
          discountValue
        }
      }
      activeRewards
      activity {
        activityDone
        date
        pointsBalance
        pointsEarned
      }
      cumulativePoints
      rewards {
        createdAt
        customerPointsAttained
        customerReceives
        customerReceivesType
        id
        loyaltyProgramId
        name
        updatedAt
      }
      totalMilestonesReached
      business {
        timezone
        country {
          currency {
            code
            id
            name
            symbol
          }
        }
      }
    }
  }
`;

export const useClientLoyaltyOverview = (id: string) => {
  return useQuery<{
    customerLoyaltyOverviewData: {
      clientMilestones: ClientMilestone[];
      activeRewards: number;
      activity: MilestoneActivity[]
      cumulativePoints: number;
      rewards: Milestone[];
      totalMilestonesReached: number;
      business: Business
    };
  }>(CUSTOMER_DASHBOARD_LOYALTY_OVERVIEW, {
    variables: { businessId: id },
    skip: !id,
  });
}

const CLIENT_VOUCHERS = gql`
  query ClientVouchers($clientId: ID!) {
    clientVouchers(clientId: $clientId) {
      amountRedeemed
      clientId
      code
      createdAt
      expiresAt
      id
      isPaymentConfirmed
      lastUsageAt
      price
      redemptionMode
      reference
      salonId
      status
      updatedAt
      validityMonths
      business {
        about
        acceptsOnlineBookings
        addedById
        bookingManagement
        bookingUrlIdentifier
        bvn
        callingCode
        cancellationPolicy
        countryCode
        createdAt
        deletedAt
        headerImageUrl
        id
        isAccountSetupComplete
        isAppointmentLinkSetup
        isBvnVerified
        isDeleted
        logoUrl
        name
        note
        ownerId
        phone
        rcNumber
        slug
        teamSize
        updatedAt
        country {
          timezone
          currency {
            code
            id
            name
            symbol
          }
        }
        salons {
          address
          bearsProcessingFee
          branchName
          callingCode
          city
          createdAt
          createdById
          deletedAt
          depositLinkValidityHours
          depositType
          depositValue
          email
          id
          intervalMinutes
          isDepositRequired
          isTaxVisible
          locationType
          onboardingStatus
          phone
          state
          status
          taxInclusivePricing
          updatedAt
        }
      }
      package {
        activateTax
        addedById
        businessId
        createdAt
        customPrice
        deletedAt
        description
        id
        isDiscountApplicable
        isPurchasableAsVoucher
        name
        salonId
        status
        totalPrice
        updatedAt
        usesCustomPrice
        validityMonths
        packageServices {
          createdAt
          duration
          id
          name
          packageId
          price
          serviceId
          updatedAt
        }
      }
      voucherServices {
        createdAt
        duration
        id
        isRedeemed
        name
        packageVoucherId
        price
        quantity
        redeemedCount
        serviceId
        updatedAt
      }
    }
  }
`;

export const useClientVouchers = (id: string) => {
  return useQuery<{
    clientVouchers: PackageVoucher[];
  }>(CLIENT_VOUCHERS, {
    variables: { clientId: id },
    skip: !id,
  });
}

const CLIENT_MEMBERSHIP = gql`
  query ClientSearch($salonId: ID!, $phone: String!, $countryCode: String!) {
    clientSearch(salonId: $salonId, phone: $phone, countryCode: $countryCode) {
      membershipWalletBalance
			membershipWalletTransactions {
				amount
				createdAt
				description
				id
				purposeType
				transactionType
				updatedAt
        trailingBalance
			}
			clientWalletTransactions {
				amount
				createdAt
				description
				id
				purposeType
				transactionType
				updatedAt
        trailingBalance
			}
			walletBalance
      clientMembership {
        createdAt
        id
        membershipExpirationDate
        membershipStatus
        purchaseAmount
        purchaseDate
        updatedAt
        membership {
          createdAt
          fee
          id
          name
          rewardPercentage
          updatedAt
          validityPeriod
          services {
            id
            name
          }
        }
      }
    }
  }
`;

export const useClientMembership = ({
  salonId,
  phone,
  countryCode,
}: {
  salonId: string;
  phone?: string;
  countryCode?: string;
}) => {
  return useQuery<{
    clientSearch: Client;
  }>(CLIENT_MEMBERSHIP, {
    variables: { salonId, phone, countryCode },
    skip: !salonId || !phone || !countryCode,
  });
}