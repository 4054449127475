import * as React from "react";
import { CheckboxProps } from "./types";
import { SvgBlackCheckmark, SvgCheckMark } from "ui";

const getSize = (size: CheckboxProps["size"]) => {
  switch (size) {
    case "Sm":
      return "w-[16px] h-[16px] rounded-sm";
    case "Md":
      return "w-[20px] h-[20px] rounded-md";
    case "Lg":
      return "w-[24px] h-[24px] rounded-lg ";
  }
  return "w-[20px] h-[20px]";
}

// TODO: create variant that allows for not checked value to have a different border color
const Checkbox = ({ isChecked, className, borderType, size = 'Md', disabled }: CheckboxProps) => {
  return (
    <label
      className={`${getSize(size)} flex text-white justify-center items-center border p-1 ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"} ${isChecked
          ? borderType === "Black" ? "border-grey-900 bg-white" : "border-green-300 bg-green-300"
          : borderType === "Black" ? "border-grey-900 bg-white" : "border-grey-20 bg-grey-20"
        } ${className || ""}`}
      aria-disabled={disabled}
    >
      {isChecked && borderType !== 'Black' ? (<SvgCheckMark width="100%" height="100%" />) : isChecked && borderType === 'Black' ? (<SvgBlackCheckmark width="100%" height="100%" />) : null}
    </label>
  );
};

export default Checkbox;
