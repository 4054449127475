import { useAddClient } from 'api/useClient';
import { getHelperTextForReactHookFormErrors } from 'components/utils/form';
import { convertFullDateStringToDate, sanitizePhoneNumber, validatePhoneNumber, formatDateToOriginalDate } from 'components/utils/misc';
import { ACCOUNT_SETUP_ERRORS } from 'constants/information';
import { REGEX_PATTERNS } from 'constants/pattern';
import { CreateClientInput, Salon } from 'core/generated';
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormLabel, FormPhone, Heading, Input, Modal, Paragraph, SvgArrowBack, SvgChevronLeft } from 'ui';
import { AddClientModalProps } from '../types';
import { COLORS } from 'constants/colors';
import ToggleOnly from 'ui/atoms/toggle/ToggleOnly';
import { useSalonCache } from 'hooks';
import { ERRORS } from 'constants/errors';

const AddClientModal = ({
  isVisible,
  closeModal,
  setClient,
  client,
  addToast
}: AddClientModalProps<'editClient' | 'deleteClient'>) => {
  const countryLocalstorage = localStorage.getItem('country')
  const country = countryLocalstorage ? JSON.parse(countryLocalstorage) : null;
  const { getSalonData } = useSalonCache();
  const salon: Salon = getSalonData();
  const salonId = salon?.id;
  const [countryCode, setCountryCode] = useState(country?.code)
  const [callingCode, setCallingCode] = useState(country?.countryCode)
  const [altCountryCode, setAltCountryCode] = useState(country?.code)
  const [altCallingCode, setAltCallingCode] = useState(country?.countryCode)
  const {
    loading: isLoading,
    createClient
  } = useAddClient()
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    setError,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<CreateClientInput>()

  // Effect to populate form when client data changes
  useMemo(() => {
    if (client) {
      setValue('firstName', client?.firstName || '');
      setValue('lastName', client?.lastName || '');
      setValue('phone', client?.phone);
      setValue('altPhone', client?.altPhone);
      setValue('email', client?.email || '');
      setValue('dob', convertFullDateStringToDate(client?.dob) || '');
      setValue('address', client?.address || '');
      setValue('optOutEmail', client?.optOutEmail === true ? false : true);
      setValue('optOutSms', client?.optOutSms === true ? false : true);
      setCountryCode(client?.countryCode || country?.code);
      setCallingCode(client?.callingCode || country?.countryCode);
      setAltCountryCode(client?.altCountryCode || country?.code);
      setAltCallingCode(client?.altCallingCode || country?.countryCode);
    }
  }, [client, setValue]);

  // console.log({
  //   country,
  //   countryCode,
  //   callingCode,
  //   altCountryCode,
  //   altCallingCode
  // })

  const resetClientFormInput = () => {
    reset({
      firstName: '',
      lastName: '',
      phone: '',
      altPhone: '',
      email: '',
      dob: '',
      address: '',
      optOutEmail: false,
      optOutSms: false
    });
    setCountryCode(country?.code);
    setCallingCode(country?.countryCode);
    setAltCountryCode(country?.code);
    setAltCallingCode(country?.countryCode);
  };

  const _closeModal = () => {
    closeModal();
    resetClientFormInput();
  };

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode)
    setValue('phone', _phoneNumber)
  }

  const _sanitizeAltPhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, altCallingCode)
    setValue('altPhone', _phoneNumber)
  }

  const addClient = async (input: CreateClientInput) => {
    const payload = {
      ...input,
      lastName: input?.lastName || "",
      dob:
        input?.dob && input?.dob !== '-'
          ? formatDateToOriginalDate(input.dob as unknown as Date, 'start')
          : null,
      salonId,
      altCallingCode,
      callingCode,
      countryCode,
      altCountryCode,
      optOutEmail: input?.optOutEmail === true ? false : true,
      optOutSms: input?.optOutSms === true ? false : true,
      id: client ? client.id : null
    }

    createClient({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { createClient: cc } = data
      if (cc.status === 200 || cc.status === 201) {
        closeModal()
        resetClientFormInput()
        addToast({ message: 'Client saved successfully', variant: 'success' })
      } else {
        addToast({ message: cc.errors[0].message, variant: 'error' })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  }

  return (
    <Modal
      show={isVisible}
      closeModal={_closeModal}
      variant="right"
    >
      <form
        onSubmit={handleSubmit(addClient)}
        className='w-full relative my-[80px]'
        autoComplete='off'
      >
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
            <Button
              variant="text"
              size="none"
              type="button"
              className="w-fit"
              fontSize="b4"
              onClick={_closeModal}
            >
              <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
            </Button>
          </div>
          <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
            <Button
              variant="icon"
              size="square"
              type="button"
              rounded="md"
              className="w-fit"
              fontSize="b4"
              onClick={_closeModal}
            >
              <SvgChevronLeft width="24px" height="24px" />
            </Button>
            <Paragraph size="b3" weight="bold">
              {client ? 'Edit client' : 'Add client'}
            </Paragraph>
          </div>
        </div>
        <div className="w-full flex flex-col px-8 space-y-6 py-3">
          <Heading variant="h1" size="h9" weight="semiBold">
            {client ? 'Edit client' : 'Add client'}
          </Heading>
          <Paragraph size="b4" color={COLORS.GREY[300]}>
            {
              client ? 'Edit client details' : 'Enter client information below'
            }
          </Paragraph>
        </div>
        <div className="w-full py-6 px-8 space-y-6">
          <div className='w-full flex gap-x-4'>
            <Input
              name='firstName'
              label='First name'
              id='first-name'
              type='text'
              placeholder='Enter first name here'
              control={control}
              rules={{
                required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED
              }}
              error={errors.firstName}
            />
            <Input
              name='lastName'
              label='Last name'
              id='last-name'
              type='text'
              placeholder='Enter last name here'
              control={control}
              error={errors.lastName}
            />
          </div>
          <div className='w-full flex flex-col md:flex-row gap-4'>
            <FormPhone
              country={{
                disabled: false,
                onSelect: (code, country: string) => {
                  setCallingCode(code)
                  setCountryCode(country)
                },
                value: countryCode
              }}
              phone={{
                name: 'phone',
                type: 'text',
                placeholder: '9151930463',
                rules: {
                  required: 'Phone number is required',
                  pattern: REGEX_PATTERNS.NUMBER,
                  onChange: (e) => {
                    _sanitizePhoneNumber(e.target.value)
                  },
                  disabled: false
                },
                value: watch('phone'),
                register,
                id: 'phone-number',
                label: 'Phone Number'
              }}
              helperText={getHelperTextForReactHookFormErrors(
                errors?.phone?.message as string
              )}
            />

            <FormPhone
              country={{
                disabled: false,
                onSelect: (code, country: string) => {
                  setAltCallingCode(code)
                  setAltCountryCode(country)
                },
                value: altCountryCode
              }}
              phone={{
                name: 'altPhone',
                type: 'text',
                placeholder: '9151930463',
                rules: {
                  pattern: REGEX_PATTERNS.NUMBER,
                  onChange: (e) => {
                    _sanitizeAltPhoneNumber(e.target.value)
                  },
                  disabled: false
                },
                value: watch('altPhone'),
                register,
                id: 'alt-phone-number',
                label: 'Secondary Phone Number'
              }}
              helperText={getHelperTextForReactHookFormErrors(
                errors?.altPhone?.message as string
              )}
            />
          </div>
          <div className='w-full flex flex-col md:flex-row gap-4'>
            <Input
              name='email'
              label='Email'
              id='email'
              type='email'
              placeholder='email@acme.com'
              control={control}
              rules={{
                pattern: REGEX_PATTERNS.EMAIL
              }}
              error={errors.email}
            />

            <Input
              name='dob'
              label='Client Birthday'
              id='dob'
              type='date'
              placeholder='DD/MM/YY'
              control={control}
              value={convertFullDateStringToDate(client?.dob)}
            />
          </div>
          <Input
            name='address'
            label='Home Address'
            id='address'
            type='text'
            placeholder='address'
            control={control}
            error={errors.address}
          />
          <Controller
            control={control}
            name='optOutSms'
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                  onClick={() => onChange(!value)}
                >
                  <FormLabel htmlFor='optOutSms'>
                    Does this client want to receive SMS reminders?
                  </FormLabel>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <ToggleOnly isChecked={value} />
                  </div>
                </div>
              )
            }}
          />
          <Controller
            control={control}
            name='optOutEmail'
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                  onClick={() => onChange(!value)}
                >
                  <FormLabel htmlFor='optOutEmail'>
                    Does this client want to receive Email reminders?
                  </FormLabel>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <ToggleOnly isChecked={value} />
                  </div>
                </div>
              )
            }}
          />
        </div>
        <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
          <div className="w-full flex justify-end py-4 px-8">
            <Button
              variant='text'
              size='sm'
              type='button'
              onClick={closeModal}
              disabled={false}
              loading={false}
              fontWeight="semiBold"
              className="w-fit text-red-500"
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              className=''
              disabled={false}
              loading={isLoading}
              size='md'
              rounded='lg'
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddClientModal
