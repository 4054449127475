import { FormSectionPublicAnswers } from "modules/settings/types";

export const transformSectionsToAnswers = (sections): { [key: string]: string } => {
  const answersObj = {};

  // Iterate through all sections and their answers
  sections?.forEach(section => {
    section.answers.forEach(qa => {
      // Use the original question key as the property name
      answersObj[qa.question] = qa.answer;
    });
  });

  return answersObj;
}