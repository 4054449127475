import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFormsSent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={28} height={28} rx={14} fill="#2B81BF" fillOpacity={0.08} />
    <g clipPath="url(#forms-sent_svg__a)">
      <path
        d="M20.978 6.902 10.073 15.99m2.57 2.58 2.488 2.478a1.257 1.257 0 0 0 1.2.343 1.278 1.278 0 0 0 .925-.845l4.087-12.252a1.278 1.278 0 0 0-1.644-1.643L7.448 10.739a1.28 1.28 0 0 0-.846.981 1.256 1.256 0 0 0 .343 1.143l3.128 3.128-.103 3.961 2.672-1.38v-.001Z"
        stroke="#2B81BF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="forms-sent_svg__a">
        <path fill="#fff" transform="translate(6 6)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFormsSent;
