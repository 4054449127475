import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSections = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.188 7.875c.14 0 .278.023.413.07.134.047.257.109.369.185.111.076.208.173.29.29.082.117.152.24.21.37a183.455 183.455 0 0 0 .483 1.23c.118.293.236.6.353.922.117.323.228.624.334.906.105.28.19.521.255.72.064.2.1.323.105.37 0 .158-.056.29-.167.395a.59.59 0 0 1-.395.167H7.313a.556.556 0 0 1-.563-.563c0-.07.023-.181.07-.333a6.42 6.42 0 0 1 .176-.493 85.01 85.01 0 0 1 .352-.861h-3.41a.556.556 0 0 1-.563-.563c0-.07.023-.181.07-.333.047-.153.106-.317.176-.493A85.67 85.67 0 0 1 3.973 9H.563A.556.556 0 0 1 0 8.437c0-.046.032-.17.097-.369.064-.199.15-.44.255-.72.105-.282.22-.583.342-.906.123-.322.24-.632.352-.931.111-.3.21-.557.299-.774.088-.216.15-.372.184-.466.1-.263.267-.477.501-.641.235-.164.495-.25.783-.255h5.624c.141 0 .279.023.414.07.134.047.257.109.369.185.111.076.208.173.29.29.082.117.152.24.21.37l.51 1.335h1.582c.141 0 .279.023.414.07.134.047.257.109.369.185.111.076.208.173.29.29.082.117.152.24.21.37l.51 1.335h1.582Zm-10.793 0 .51-1.354c.099-.263.266-.477.5-.641.235-.164.495-.25.782-.255h2.84l-.361-.958a.234.234 0 0 0-.088-.105.297.297 0 0 0-.14-.062H2.811a.224.224 0 0 0-.131.053.234.234 0 0 0-.088.105L1.37 7.875h3.024Zm3.375 2.25.51-1.354c.099-.263.266-.477.5-.641.235-.164.495-.25.783-.255H12.4l-.36-.958a.234.234 0 0 0-.088-.105.297.297 0 0 0-.14-.062H6.186a.224.224 0 0 0-.131.053.234.234 0 0 0-.088.105c-.205.54-.407 1.075-.607 1.609-.199.533-.404 1.069-.615 1.608H7.77Zm8.859 2.25-1.213-3.208a.234.234 0 0 0-.088-.105.297.297 0 0 0-.14-.062H9.561a.224.224 0 0 0-.131.053.234.234 0 0 0-.088.105l-.37.967-.421 1.125c-.14.375-.284.75-.43 1.125h8.507Z"
      fill="#000"
    />
  </svg>
);
export default SvgSections;
