import SvgArrowDownAnswer from "assets/icons/ArrowDownAnswer";
import SvgCalendarOutline from "assets/icons/CalendarOutline";
import SvgCallOutline from "assets/icons/CallOutline";
import SvgCheckboxAnswer from "assets/icons/CheckboxAnswer";
import SvgEmailOutline from "assets/icons/EmailOutline";
import SvgGenderOutline from "assets/icons/GenderOutline";
import SvgHouseOutline from "assets/icons/HouseOutline";
import SvgLongAnswer from "assets/icons/LongAnswer";
import SvgMultipleChoice from "assets/icons/MultipleChoice";
import SvgPlus from "assets/icons/Plus";
import SvgShortAnswer from "assets/icons/ShortAnswer";
import SvgUserOutline from "assets/icons/UserOutline";

export const CLIENT_DETAILS_APPLICABLE_FIELDS = [
  {
    label: 'First name',
    value: 'first_name',
    icon: SvgUserOutline
  }, {
    label: 'Last name',
    value: 'last_name',
    icon: SvgUserOutline
  }, {
    label: 'Email address',
    value: 'email',
    icon: SvgEmailOutline
  }, {
    label: 'Phone number',
    value: 'phone',
    icon: SvgCallOutline
  }, {
    label: 'Address',
    value: 'address',
    icon: SvgHouseOutline
  }, {
    label: 'Birthday',
    value: 'birthday',
    icon: SvgCalendarOutline
  }, {
    label: 'Gender',
    value: 'gender',
    icon: SvgGenderOutline
  }, {
    label: 'Add custom',
    value: 'custom',
    icon: SvgPlus
  }
]

export const CUSTOM_FORM_QUESTION_TYPES = [
  {
    label: 'Short answer',
    value: 'text',
    icon: SvgShortAnswer
  }, {
    label: 'Long answer',
    value: 'textarea',
    icon: SvgLongAnswer
  }, {
    label: 'Multiple choice',
    value: 'radio',
    icon: SvgMultipleChoice
  }, {
    label: 'Checkboxes',
    value: 'checkbox',
    icon: SvgCheckboxAnswer
  }, {
    label: 'Dropdown',
    value: 'select',
    icon: SvgArrowDownAnswer
  }
]

export const RULES_OPTIONS = [
  {
    label: 'Automatically, before any appointment',
    value: 'AUTOMATIC_BEFORE_APPOINTMENT'
  }, {
    label: 'Manually',
    value: 'MANUAL'
  }
]

export const AUTOMATIC_RULES_OPTIONS = [
  {
    label: 'Before booking is completed',
    value: 'BEFORE_BOOKING_COMPLETE'
  },
  {
    label: 'After booking is confirmed',
    value: 'AFTER_BOOKING_CONFIRMED'
  }, {
    label: 'After an appointment has been completed',
    value: 'AFTER_APPOINTMENT'
  }
]

export const FORM_TABS = [
  {
    title: 'All',
    key: 'all'
  }, {
    title: 'Sent',
    key: 'sent'
  }, {
    title: 'Completed',
    key: 'completed'
  }
]

export const FORMS_HISTORY_HEADERS = [
  {
    label: 'Clients',
    value: 'clients'
  }, {
    label: 'Email addresses',
    value: 'email'
  }, {
    label: 'Form added',
    value: 'date'
  }, {
    label: 'Status',
    value: 'status'
  }
]

export const MOBILE_FORMS_HISTORY_HEADERS = [
  {
    title: 'Clients',
    key: 'clients'
  }, {
    title: 'Email addresses',
    key: 'email'
  }, {
    title: 'Form added',
    key: 'date'
  }, {
    title: 'Status',
    key: 'status'
  }
]