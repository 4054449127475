import { formatDateToOriginalDate, getDefaultQueryDates, getNumberMonthAndYearFromDate } from 'components/utils/misc'
import { useModal, useSalonCache } from 'hooks'
import React, { Fragment, useMemo, useState } from 'react'
import { Menu, Transition } from "@headlessui/react"
import { Client } from 'core/generated'
import { Button, CalendarPicker, Checkbox, Paginator, Paragraph, Pill, SearchTerm, SvgFilter, Table, ToastProps } from 'ui'
import Label from 'ui/atoms/formLabel/Label'
import { CLIENT_FORMS_HISTORY_HEADERS, FORMS_KEY_OPTIONS, MOBILE_CLIENT_FORMS_HISTORY_HEADERS } from '../constants'
import AddClientForm from '../modals/AddClientForm'
import ViewClientForm from '../modals/ViewClientForm'
import { useClientFormsSubmissions } from 'api/useForms'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'

const ClientForms = ({
  client, addToast
}: {
  client: Client,
  addToast: (toast: ToastProps) => void
  }) => {
  const [selectedForm, setSelectedForm] = useState(null)
  const {
    isVisible: isOpenSendFormModalVisible,
    openModal: openSendFormModal,
    closeModal: closeSendFormModal
  } = useModal()
  const {
    isVisible: isOpenViewFormModalVisible,
    openModal: openViewFormModal,
    closeModal: closeViewFormModal
  } = useModal()
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [keyFilter, setKeyFilter] = useState(FORMS_KEY_OPTIONS?.map((option) => {
    return {
      label: option?.label,
      value: option?.value,
      show: true
    }
  }))
  const [page, setPage] = useState<number>(1)
  const [pageInfo, setPageInfo] = useState<{
    endCursor: string,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    startCursor: string,
  }>()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )

  const handleHeadingCheckboxChange = (value: string) => {
    // modify keyPreferencesFilter show
    const index = keyFilter?.findIndex?.((filter) => filter?.value === value)
    if (index === -1) return
    const newkeyFilter = [...keyFilter];
    newkeyFilter[index].show = !newkeyFilter[index].show;
    setKeyFilter(newkeyFilter)
  }

  const {
    data: formSubmissionsData,
    refetch,
    isLoading: loading
  } = useClientFormsSubmissions({
    clientId: client?.id,
    q: debouncedSearchQuery,
    startDate: formatDateToOriginalDate(selectedDates?.[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates?.[1], 'end'),
    limit: 30,
    page
  })
  const clientForm = useMemo(() => {
    const pagination = formSubmissionsData?.data?.pagination;
    if (pagination) {
      setPageInfo({
        endCursor: +pagination?.page > 1 ? (+pagination?.page - 1).toString() : "1",
        hasNextPage: +pagination?.totalPages > 0 && +pagination?.totalPages > +pagination?.page,
        hasPreviousPage: +pagination?.totalPages > 0 && +pagination?.page > +pagination?.totalPages,
        startCursor: +pagination?.page > 1 ? (+pagination?.page - 1).toString() : "1",
      })
    }
    return formSubmissionsData?.data
  }, [formSubmissionsData])

  const openFormItem = (index: number) => {
    // open form from table list
    const form = clientForm?.data?.[index]
    if (!form) return
    setSelectedForm(form)
    openViewFormModal()
  }

  const goToNext = () => {
    if (clientForm?.pagination) {
      if (+clientForm?.pagination?.page === +clientForm?.pagination?.totalPages) return
      if (+clientForm?.pagination?.page < +clientForm?.pagination?.totalPages) {
        setPage(+clientForm?.pagination?.page + 1)
        refetch()
      }
    }
  }

  const goToPrev = () => {
    if (clientForm?.pagination) {
      if (+clientForm?.pagination?.page === 1) return
      if (+clientForm?.pagination?.page > 1) {
        setPage(+clientForm?.pagination?.page - 1)
        refetch()
      }
    }
  }

  const rows = Array?.isArray(clientForm?.data) && clientForm?.data?.length ? clientForm?.data?.map((form) => {
    return {
      formName: form?.title,
      dateSent: getNumberMonthAndYearFromDate(form?.date_sent),
      dateCompleted: getNumberMonthAndYearFromDate(form?.date_completed),
      status: form?.status ? (
        <Pill variant="success">{form?.status}</Pill>
      ) : '-'
    }
  }) : [];

  return (
    <>
      {loading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row xl:justify-between xl:items-center space-x-4 p-3'>
        <div className='flex items-center gap-2 w-[300px]'>
          <SearchTerm
            placeholder='Search'
            setDebouncedSearchQuery={setDebouncedSearchQuery}
            size='md'
          />
          <Menu as='div' className='relative inline-block text-left'>
            <div>
              <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                <SvgFilter width='20px' height='20px' />
                <span className='hidden xl:inline'>Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                <div className='flex flex-col p-4 space-y-4'>
                  {keyFilter.map((heading, index) => (
                    <Label
                      className='flex space-x-2 items-center cursor-pointer'
                      key={index}
                      htmlFor={heading.value}
                      onClick={() =>
                        handleHeadingCheckboxChange(heading.value)
                      }
                    >
                      <Checkbox
                        isChecked={heading.show}
                        borderType='Black'
                        size='Sm'
                      />
                      <span>{heading.label}</span>
                    </Label>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className='flex items-center space-x-3 max-w-[600px]'>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <Paginator pageInfo={pageInfo} {...{ goToNext, goToPrev }} />
          <Button
            variant='primary'
            size='sm'
            type='button'
            rounded='md'
            fontSize='b5'
            onClick={openSendFormModal}
          >
            Add new
          </Button>
        </div>
      </div>
      <Table
        headers={CLIENT_FORMS_HISTORY_HEADERS}
        mobileHeaders={MOBILE_CLIENT_FORMS_HISTORY_HEADERS}
        rows={rows}
        onClick={openFormItem}
        tableOptions={{
          view: true,
          send_again: true,
          edit: false,
          delete: false,
          duplicate: false
        }}
      />
      <AddClientForm
        isVisible={isOpenSendFormModalVisible}
        client={client}
        closeModal={() => {
          refetch()
          closeSendFormModal()
        }}
        addToast={addToast}
      />
      <ViewClientForm
        isVisible={isOpenViewFormModalVisible}
        closeModal={() => {
          closeViewFormModal()
          setSelectedForm(null)
        }}
        selectedForm={selectedForm}
      />
    </>
  )
}

export default ClientForms