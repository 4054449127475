import { formatInToPrice, getHoursAndMinutesString, sortServices } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { Service } from 'core/generated';
import React, { useState } from 'react'
import { Button, Checkbox, Modal, Paragraph, SearchTerm, SvgArrowBack, SvgChevronLeft } from 'ui'
import { FormsSelectServicesModal } from '../types';

const SelectService = ({ isVisible, closeModal, services, selectedServices, setSelectedServices }: FormsSelectServicesModal) => {
  const [filteredServices, setFilteredServices] = useState<Service[]>(sortServices(services));
  const searchItem = (search: string) => {
    if (search === '') {
      setFilteredServices(sortServices(services));
      return;
    }
    const filteredServices = services?.filter((service) => {
      return service.name.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredServices(sortServices(filteredServices));
  }

  const addService = (id: string) => {
    // check service in services
    const service = services?.find((service) => service.id === id);
    // check if service exist in selected services
    const isServiceExist = selectedServices.find((service) => service.id === id);
    if (isServiceExist && service) {
      // removeService
      const newSelectedServices = selectedServices.filter(
        (service) => service.id !== id
      );
      setSelectedServices(newSelectedServices);
      return
    }
    if (service && !isServiceExist) {
      // addService
      const newService = { ...service };
      setSelectedServices([...selectedServices, newService]);
      return;
    }
  }
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant="right"
    >
      <div className="w-full relative my-[80px]">
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
            <Button
              variant="text"
              size="none"
              type="button"
              className="w-fit"
              fontSize="b4"
              onClick={closeModal}
            >
              <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
            </Button>
          </div>
          <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
            <Button
              variant="icon"
              size="square"
              type="button"
              rounded="md"
              className="w-fit"
              fontSize="b4"
              onClick={closeModal}
            >
              <SvgChevronLeft width="24px" height="24px" />
            </Button>
            <Paragraph size="b3" weight="bold">
              Back
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col p-4 gap-y-3'>
          <div className='w-full flex justify-between items-center'>
            <Paragraph size="b3" color={COLORS.GREY[300]}>
              Select service
            </Paragraph>

            <div className="w-2/5">
              <SearchTerm
                placeholder='Search.'
                showIcon={false}
                setDebouncedSearchQuery={searchItem}
              />
            </div>
          </div>
          <div className='w-full flex flex-col space-y-3'>
            {filteredServices && Array?.isArray(filteredServices) && filteredServices?.length ? filteredServices?.map((service) => {
              const isChecked = selectedServices?.some((selectedService) => selectedService?.id === service?.id);
              return (
                <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addService(service?.id)}>
                  <div className='flex space-x-2 items-start'>
                    <Checkbox isChecked={isChecked} />
                    <div className='flex flex-col space-y-1'>
                      <Paragraph size="b5" color={COLORS.GREY[300]}>
                        {service?.name}
                      </Paragraph>
                      <Paragraph size="b6" color={COLORS.GREY[300]}>
                        {getHoursAndMinutesString(service?.duration)}
                      </Paragraph>
                    </div>
                  </div>

                  <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                    {formatInToPrice(service?.price)}
                  </Paragraph>
                </div>
              )
            }) : null}
          </div>
        </div>
        <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
          <div className="w-full flex justify-between py-4 px-8">
            <div className="bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center">
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                TOTAL SELECTED: {selectedServices?.length}
              </Paragraph>
            </div>

            <div className="flex space-x-2">
              <Button
                variant="primary"
                className=""
                size="md"
                rounded="lg"
                type="button"
                onClick={closeModal}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SelectService